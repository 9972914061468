import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {
    Box, useTheme, Typography, IconButton,
    Container, Stack } from '@mui/material';
import { HeaderPageProps } from '@interfaces/index';

import { sx } from './sx';
import { rem } from '@utils';

const HeaderPage: React.FC<HeaderPageProps> = ({
    title,
    legend,
    icon,
    iconCustom,
    action,
    onClick,
    titleAlign,
    backgroundColor,
    size,
    noPadding,
}: HeaderPageProps) => {
    const { palette, typography } = useTheme();
    const { css } = sx(noPadding);
    const handlePadding = icon ? rem(0) : rem(15);

    const sizeHead = {
        small: {
            fontSize: 14,
            iconFontSize: 14,
        },
        medium: {
            fontSize: 16,
            iconFontSize: 18,
        },
    };

    return (
        <Box sx={{
            ...css.sxBox,
            paddingLeft: handlePadding,
            backgroundColor: backgroundColor ?
                backgroundColor : palette.common.white,
        }}>
            <Container sx={{ ...css.sxContainer }}>
                <Stack direction='row' justifyContent='space-between'>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        {icon &&
                            <IconButton
                                onClick={onClick}
                                sx={{ ...css.sxIconButton[titleAlign] }}>
                                <I
                                    color={palette.dark}
                                    fontSize={sizeHead[size ?? 'medium'].iconFontSize}
                                    icon={icon} />
                            </IconButton>
                        }
                        {iconCustom && iconCustom}
                        <Box sx={{
                            ...css.sxBoxTitle[titleAlign],
                        }}>
                            <Typography
                                color={palette.darkGray}
                                variant='h2'
                                fontSize={sizeHead[size ?? 'medium'].fontSize}
                                fontWeight={typography.fontWeightSemiBold}
                                lineHeight={1.7}
                            >
                                {title}
                            </Typography>
                            {legend && (
                                <Typography
                                    variant='h2'
                                    component='p'
                                    fontSize={14}
                                    fontWeight={typography.fontWeightMedium}
                                    pt={0.2}
                                >
                                    {legend}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    {action &&
                        <Box sx={{ ...css.sxBoxContent }}>
                            <Box sx={{ ...css.sxBoxAction }}>
                                {action}
                            </Box>
                        </Box>
                    }
                </Stack>
            </Container>
        </Box>
    );
};

export { HeaderPage };

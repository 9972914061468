import { client } from '@api';
import React from 'react';
import { DEFAULTS } from './DEFAULTS';
import {
    UserServiceAddressForm,
    UserServiceDeleteAddress,
    ZIPCodeAddress,
} from '@interfaces/Services';

export const userAddress = () => {
    const { services } = client();
    const [addressList, setAddressList] = React.useState(DEFAULTS.USER.ADDRESS_LIST);
    const [addressByZipCode,
        setAddressByZipCode] = React.useState(DEFAULTS.USER.LIST_ADDRESS_BY_ZIPCODE);

    const load = async () => {
        const response = await services.user.address.load();
        setAddressList((prev) => prev = {
            message: response?.response.message,
            isLoaded: !!(!response?.error),
            status: response?.response.status,
            data: response?.response.payload ?
                response?.response.payload :
                [],
        });
        return response;
    };

    const searchByZipCode = async ({ zipCode }: ZIPCodeAddress) => {
        const response = await services.user.address.searchByZipCode({ zipCode });
        setAddressByZipCode((prev) => prev = {
            message: '',
            isLoaded: !!(!response),
            status: response ? 200 : 400,
            data: response ?
                response : {},
        });
        return response;
    };

    const handle = async ({ address, addressId }: UserServiceAddressForm) => {
        const response = await services.user.address.handle({ address, addressId });
        return response;
    };

    const deleteAddress = async ({ addressId }: UserServiceDeleteAddress) => {
        const response = await services.user.address.deleteAddress({ addressId });
        return response;
    };

    return {
        load,
        handle,
        deleteAddress,
        searchByZipCode,
        addressByZipCode,
        list: addressList,
    };
};

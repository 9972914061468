import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        sxLink: {
            textDecoration: 'none',
            color: palette.customBlack,
        },
        sxBox: {
            'display': 'flex',
            'width': '100%',
            'height': '100%',
            'backgroundColor': palette.common.white,
            'border': `${rem(1)} solid ${palette.clouds}`,
            'borderRadius': rem(4),
            'padding': mediaQuery.md ? rem(30) : rem(20),
            'cursor': 'pointer',
        },
        sxBoxTexts: {
        },
        sxTitle: {
            fontWeight: typography.fontWeightBold,
            fontSize: mediaQuery.md ? rem(18) : rem(16),
            mb: mediaQuery.md ? rem(8) : rem(4),
        },
        sxLegend: {
            fontWeight: typography.fontWeightMedium,
            fontSize: mediaQuery.md ? rem(14) : rem(12),
            display: 'flex',
        },
        sxBoxButton: {
            mt: rem(8),
            py: rem(8),
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
        },
        sxLinkText: {
            color: palette.customBlack,
            fontWeight: typography.fontWeightSemiBold,
            fontSize: rem(14),
        },
        sxIconButton: {
            'marginRight': rem(10),
            'height': mediaQuery.md ? rem(48) : rem(38),
            'width': mediaQuery.md ? rem(48) : rem(38),
            'fontSize': mediaQuery.md ? rem(22) : rem(18),
            'backgroundColor': palette.primaryColor,
            'color': palette.common.white,
            ':hover': {
                'backgroundColor': `${palette.primaryColor} !important`,
            },
        },
        sxCopyBox: {
            'position': 'relative',
            'ml': rem(8),
            'top': mediaQuery.md ? rem(20) : rem(16),
        },
    };
    return { css };
};

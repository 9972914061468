import { useTheme } from '@mui/material';
import { rem } from '@utils';

const sx = () => {
    const { palette } = useTheme();

    const css = {
        header: {
            wrapper: {
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
                height: rem(81),
            },
            container: {
                wrapper: {
                    height: rem(81),
                },
            },
        },
    };

    return { css };
};

export { sx };

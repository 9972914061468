import { OrderStatusType } from '@types';

export const purchaseStatusOrder = (key: OrderStatusType) => {
    const object = {
        AWAITING_PAYMENT: { // N
            title: 'Aguardando pagamento',
            status: !!(key === 'AWAITING_PAYMENT'),
            color: '#e3b487',
        },
        PARTIALLY_PAID: { // N
            title: 'Parcialmente pago',
            status: !!(key === 'PARTIALLY_PAID'),
            color: '#e3b487',
        },
        PAID: {
            title: 'Pago',
            status: !!(key === 'PAID'),
            color: '#27ae60',
        },
        PAYMENT_NOT_APPROVED: {
            title: 'Pagamento não aprovado',
            status: !!(key === 'PAYMENT_NOT_APPROVED'),
            color: '#c2403f',
        },
        POSTED: {
            title: 'Postado',
            status: !!(key === 'POSTED'),
            color: '#f25e35',
        },
        POSTED_DEVOLUTION: {
            title: 'Devolução enviada',
            status: !!(key === 'POSTED'),
            color: '#f25e35',
        },
        POSTED_REFUSAL_DEVOLUTION: {
            title: 'Devolução enviada de volta',
            status: !!(key === 'POSTED'),
            color: '#f25e35',
        },
        TAG_GENERATED: {
            title: 'Aguardando envio',
            status: !!(key === 'TAG_GENERATED'),
            color: '#27ae60',
        },
        TAG_GENERATED_DEVOLUTION: {
            title: 'Aguardando seu envio',
            status: !!(key === 'TAG_GENERATED_DEVOLUTION'),
            color: '#e3b487',
        },
        TAG_GENERATED_REFUSAL_DEVOLUTION: {
            title: 'Aguardando envio do vendedor',
            status: !!(key === 'TAG_GENERATED_REFUSAL_DEVOLUTION'),
            color: '#e3b487',
        },
        FINISHED: {
            title: 'Finalizado',
            status: !!(key === 'FINISHED'),
            color: '#27ae60',
        },
        FINISHED_DEVOLUTION: { // N
            title: 'Devolução entregue',
            status: !!(key === 'FINISHED_DEVOLUTION'),
            color: '#27ae60',
        },
        FINISHED_REFUSAL_DEVOLUTION: { // N
            title: 'Recusa finalizada',
            status: !!(key === 'FINISHED_DEVOLUTION'),
            color: '#27ae60',
        },
        CANCELED: {
            title: 'Cancelado',
            status: !!(key === 'CANCELED'),
            color: '#c2403f',
        },
        PARTIALLY_CANCELED: { // N
            title: 'Parcialmente cancelado',
            status: !!(key === 'PARTIALLY_CANCELED'),
            color: '#c2403f',
        },
        REFUNDED: { // N
            title: 'Devolvido',
            status: !!(key === 'REFUNDED'),
            color: '#27ae60',
        },
        PARTIALLY_REFUNDED: {
            title: 'Parcialmente devolvido',
            status: !!(key === 'PARTIALLY_REFUNDED'),
            color: '#27ae60',
        },
    };
    return object[key];
};

import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Price } from '@components';
import { styles } from '@utils';
import { sx } from './sx';
import { Link } from 'react-router-dom';

interface BoxValueProps {
    type: 'POINTS' | 'POINTS_RELEASE' | 'TOTAL_MONEY';
    isLoaded: boolean;
    value: number;
}

const handleIcon = {
    POINTS: {
        title: 'Disponível Em Pontos',
        icon: ['fas', 'check-double'] as IconProp,
        color: '#BB2385',
    },
    POINTS_RELEASE: {
        title: 'Pontos a Liberar',
        icon: ['fas', 'cubes-stacked'] as IconProp,
        color: '#5104E1',
    },
    TOTAL_MONEY: {
        title: 'Total Em Reais',
        icon: ['fas', 'wallet'] as IconProp,
        color: '#5104E1',
    },
};

export const BoxValue: React.FC<BoxValueProps> = ({
    type, value, isLoaded,
}: BoxValueProps) => {
    const mediaQuery = styles.mediaQueryCSS();

    const { css } = sx();

    return (
        <Stack
            spacing={2}
            sx={{ ...css.stack.wrapper }}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Box sx={{ ...css.box.icon }}>
                    <I
                        icon={handleIcon[type].icon}
                        color={handleIcon[type].color} />
                </Box>
                <Stack>
                    <Typography
                        sx={{ ...css.text.title }}
                        variant='h4'>
                        {handleIcon[type].title}
                    </Typography>
                    {!mediaQuery.md &&
                    <>
                        {isLoaded ?
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                spacing={0.5}
                                sx={{ ...css.stack.value }}>
                                <Price
                                    size='small'
                                    type={type === 'TOTAL_MONEY' ?
                                        'SHOPPING' : 'REDEMPTION'}
                                    value={value} />
                            </Stack> :
                            <Skeleton variant='rectangular' width={100} height={18} />
                        }
                    </>
                    }
                </Stack>
            </Stack>
            {mediaQuery.md &&
            <>
                {isLoaded ?
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={0.5}
                        sx={{ ...css.stack.value }}>
                        <Price
                            size='big'
                            type={type === 'TOTAL_MONEY' ? 'SHOPPING' : 'REDEMPTION'}
                            value={value} />
                    </Stack> :
                    <Skeleton variant='rectangular' width={100} height={33} />
                }
            </>
            }
            {type === 'TOTAL_MONEY' &&
                <Link to={'/meus-creditos'}>
                    <Button
                        sx={{ ...css.buttonCredit }}>
                        <I icon={['fas', 'plus']} />
                    </Button>
                </Link>
            }
        </Stack>
    );
};

import { client } from '@api';
import {
    LoginProps,
    RecoveryPasswordProps,
    RecoveryPasswordTypeNewProps,
} from '@interfaces/Services';
// import Cookies from 'universal-cookie';

export const useAuth = () => {
    const { services } = client();
    // const cookies = new Cookies(null, { path: '/' });

    const login = async ({ login, password }: LoginProps) => {
        const response = await services.auth.login({ login, password });

        // if (!response?.error) {
        //     cookies.set('session', response?.response.accessToken);
        // }
        return response;
    };

    const logout = async () => {
        return await services.auth.logout();
    };

    const recoveryPassword = async ({ ...data }: RecoveryPasswordProps) => {
        return await services.auth.recoveryPassword({ ...data });
    };

    const recoveryPasswordTypeNew = async ({ ...data }: RecoveryPasswordTypeNewProps) => {
        return await services.auth.recoveryPasswordTypeNew({ ...data });
    };

    return {
        login,
        logout,
        recoveryPassword: {
            send: recoveryPassword,
            typeNew: recoveryPasswordTypeNew,
        },
    };
};

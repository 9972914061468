/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { SubmitHandler, useForm } from 'react-hook-form';

import { BoxStyled, Button, Form, Input } from './styles';
import { useNavigate } from 'react-router-dom';
import { CONSTANTS } from '@static/constants';
import { FILTERS_KEYS } from '@static/filters';
import { numbers } from '@utils';
import { useAds } from '@hooks/ads';
import { useFilters } from '@hooks/filters';

export interface SearchFormProps {
    fromHelperCenter?: boolean;
};

export interface FormSearch {
    key: string;
};


const SearchForm: React.FC<SearchFormProps> = ({
    fromHelperCenter,
}: SearchFormProps) => {
    const { ads } = useAds();
    const ad = useAds();
    const { clearFilters } = useFilters();
    const navigate = useNavigate();
    const { register, handleSubmit, reset, resetField } = useForm<FormSearch>({
        mode: 'onSubmit',
    });

    const onSubmit: SubmitHandler<FormSearch> = async (data) => {
        ads.search.clearList();
        clearFilters();
        ad.categories.reset({ type: 'history' });
        ad.categories.reset({ type: 'categories' });

        if (fromHelperCenter) {
            navigate({
                pathname: ('/central-de-ajuda/resultados'),
                search: `?key=${data.key}`,
            });
            return;
        };

        const params = {
            key: data.key,
            limit: FILTERS_KEYS.LIMIT + '=' + CONSTANTS.SEARCH.LIMIT,
            aleatory: FILTERS_KEYS.ALEATORY + '=' + numbers.randomNumber({ maxRange: 100 }),
        };

        navigate({
            pathname: (`/resultados/busca/${params.key}`),
            search: `${params.limit}&${params.aleatory}`,
        });

        reset({ key: '' });
        // resetField('key');
    };

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}>
            <BoxStyled>
                <Input
                    {...register('key',
                        { required: true },
                    )}
                    placeholder='Faça uma busca' />
            </BoxStyled>
            <Button
                type='submit'>
                <I icon={['far', 'search']} />
            </Button>
        </Form>
    );
};

export { SearchForm };

/* eslint-disable no-unused-vars */
import React from 'react';
import { AddressBox, Feedback, HeaderPage, Template } from '@components';
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Container,
    Stack,
    Typography,
} from '@mui/material';
import { formatAddress, rem } from '@utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { ToggleStaticProps } from '@interfaces';
import { useUser } from '@hooks/user';
import { DEFAULTS } from './DEFAULTS';
import { sx } from './sx';
import { useGlobal } from '@hooks/global';

export const Edit: React.FC = () => {
    const dialogChangesItem = React.useRef<ToggleStaticProps>(null);

    const navigate = useNavigate();
    const location = useLocation();

    const param = useParams();
    const user = useUser();
    const { css } = sx();

    const fromPayment = !!location.search.includes('action=pagamento');
    const [currentAddress,
        setCurrentAddress] = React.useState(DEFAULTS.CURRENT_ADDRESS);
    const [isLoading, setIsLoading] = React.useState(DEFAULTS.LOADING);
    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
    });

    const paramType = {
        type: param.type as 'adicionar' | 'editar',
        id: param.id,
    };

    const handleClose = () => setOpen(false);

    const loadAddressByID = async () => {
        setCurrentAddress((prev) => prev = {
            ...prev,
            isLoaded: true,
        });
        dialogChangesItem.current?.show();
        if (user.address.list.isLoaded) {
            const addressList = user.address.list.data.find((address) => {
                return address._id === paramType.id;
            });

            if (addressList) {
                setCurrentAddress((prev) => prev = {
                    isLoaded: false,
                    address: addressList,
                });
            }
        }
    };

    const deleteAddressByID = async () => {
        setIsLoading((prev) => prev = {
            ...prev,
            delete: true,
        });
        if (paramType.id) {
            const response = await user.address.deleteAddress({
                addressId: paramType.id,
            });
            if (!response?.error) {
                dialogChangesItem.current?.hide();
                setResponseData({
                    type: response?.response.type,
                    message: response?.response.message,
                });
                setOpen(true);
                setIsLoading(DEFAULTS.LOADING);
                await user.address.load();
                setTimeout(() => {
                    navigate('/meus-enderecos');
                }, 1000);
            }
        }
    };

    return (
        <Box sx={css.wrapper}>
            <HeaderPage
                noPadding
                onClick={() => fromPayment ? navigate(-1) : navigate('/meus-enderecos')}
                icon={['far', 'chevron-left']}
                titleAlign={'default'}
                title={'Meus Endereços'}
                action={
                    (paramType.type === 'editar' && !fromPayment) && (
                        <Button
                            variant='contained'
                            sx={css.buttons.delete}
                            size="small"
                            onClick={loadAddressByID}>
                            <Stack
                                direction='row'
                                alignItems='center'
                                spacing={0.5}>
                                <Typography
                                    variant='subtitle1'
                                    component='span'
                                    fontSize={12}>
                                    Excluir
                                </Typography>
                                <I
                                    fontSize={rem(14)}
                                    icon={['fal', 'trash-can']}
                                />
                            </Stack>
                        </Button>
                    )} />
            <Box mt={rem(10)}>
                <Container maxWidth='lg'>
                    <Template.Forms.Address
                        fromPayment={fromPayment}
                        addressID={paramType.id}
                        navigateToAfterResponse='/meus-enderecos'
                    />
                </Container>
            </Box>

            <Template.MuiDialog
                ref={dialogChangesItem}
                maxWidth='sm'
                title='Deletar endereço'
                showDialogTitle
                onCancel={{
                    action: () => dialogChangesItem.current?.hide(),
                }}
                onConfirm={{
                    text: [
                        'Deletar',
                        isLoading.delete &&
                        <CircularProgress
                            color="inherit"
                            size={12}
                            sx={{ ml: 1 }}
                        />,
                    ],
                    action: deleteAddressByID,
                }}>
                {!currentAddress.isLoaded ? (
                    <AddressBox
                        title={currentAddress.address.zipCode}
                        legend={formatAddress({
                            ...currentAddress.address,
                        })}
                        mode='list'
                        noIcon
                        addressTag={currentAddress.address.nickname}
                    />
                ) : '...'}
            </Template.MuiDialog>
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box>
    );
};

import { client } from '@api';
import {
    CreatePaymentFormProps,
    DeletePaymentFormProps,
    UpdatePaymentFormProps,
} from '@interfaces/Services';


export const create = async (data: CreatePaymentFormProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/paymentForm/create', data);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const update = async (data: UpdatePaymentFormProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/paymentForm/update', data);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const deletePaymentForm = async (data: DeletePaymentFormProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.delete('/paymentForm/delete', {
            data,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const load = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/paymentForm/find');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};


import { Box, Divider, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { useUser } from '@hooks/user';
import { files, rem } from '@utils';
import { ProductItem } from '../../../components/product-item';

const Step4 = () => {
    const { css } = sx();
    const { purchase } = useUser();

    return (
        <Box p={2}>
            <Typography
                variant='h3'
                sx={{ ...css.text.title }}>
                Antes de fazer a devolução, observe:
            </Typography>
            <Box pt={4}>
                <ul>
                    <li>
                        <Stack mb={3} spacing={1} direction={'row'}>
                            <Box sx={{ ...css.box.check }}>
                                <I fontSize={10} icon={['far', 'check']} />
                            </Box>
                            <Typography sx={{ ...css.text.p }}>
                                O Produto deve estar sem uso, com todos os
                                seus acessórios e no pacote original.
                            </Typography>
                        </Stack>
                    </li>
                    <li>
                        <Stack mb={3} spacing={1} direction={'row'}>
                            <Box sx={{ ...css.box.check }}>
                                <I fontSize={10} icon={['far', 'check']} />
                            </Box>
                            <Typography sx={{ ...css.text.p }}>
                                As devoluções serão conferidas para garantir
                                conformidade em ambos os lados.
                            </Typography>
                        </Stack>
                    </li>
                    <li>
                        <Stack mb={3} spacing={1} direction={'row'}>
                            <Box sx={{ ...css.box.check }}>
                                <I fontSize={10} icon={['far', 'check']} />
                            </Box>
                            <Typography sx={{ ...css.text.p }}>
                            O valor pago pelo produto e frete só serão estornados após
                            confirmação e aceite da devolução pela marca. Enviaremos
                            notificações para o seu email com atualizações desse processo
                            e você também pode nos escrever em contato@rewardhub.com.br a
                            qualquer momento que desejar.
                            </Typography>
                        </Stack>
                    </li>
                </ul>
            </Box>
            <Typography
                variant='h4'
                sx={{ ...css.text.resume }}>
                Resumo
            </Typography>
            <Stack
                py={2}
                divider={
                    <Divider
                        orientation="horizontal"
                        sx={{ ...css.divider }} />
                }
                spacing={1}>
                {purchase.devolution.data.items.map((item, i) => (
                    <Stack
                        px={1}
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        key={`${item.variationItemId}-${i}`}>
                        <ProductItem
                            srcImage={files
                                .load(item?.src, 'server')}
                            title={`${item.title}`} />
                        <Typography
                            fontSize={rem(12)}
                            textAlign={'center'}>
                            Quantidade: {item.quantity}
                        </Typography>
                    </Stack>
                ))}
            </Stack>
        </Box>
    );
};

export { Step4 };

import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        box: {
            wrapper: {
                backgroundColor: palette.lightBg,
                minHeight: 'calc(100vh - 178px)',
            },
            search: {
                py: rem(35),
                display: 'flex',
                justifyContent: 'center',
            },
            contact: {
                py: rem(35),
            },
        },
        texts: {
            title: {
                fontWeight: typography.fontWeightBold,
                fontSize: mediaQuery.md ? rem(26) : rem(22),
                color: palette.mediumGray,
            },
        },
    };

    return { css };
};

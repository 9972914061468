import { rem, styles } from '@utils';
import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        box: {
            wrapper: {
                backgroundColor: palette.lightBg,
                pt: rem(mediaQuery.md ? 20 : 15),
                pb: rem(16),
            },
        },
        button: {
            relevants: {
                border: '0.0625rem solid ' + palette.mediumVioletRed + ' !important',
                fontSize: rem(12),
                color: palette.mediumVioletRed,
                pt: rem(8),
                pb: rem(8),
            },
        },
        grid: {
            item: {
                backgroundColor: palette.common.white,
            },
        },
    };

    return { css };
};

import { Box, Skeleton, Stack } from '@mui/material';
import { sx } from './sx';

export const skeleton = () => {
    const { css } = sx();

    const categories = (
        <Box>
            <Box sx={css.skeleton.wrapper}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Box sx={css.skeleton.content.wrapper}>
                        <Skeleton variant="rounded" width={24} height={24} />
                        <Skeleton
                            variant="text"
                            width='100%'
                            sx={css.skeleton.content.text}
                        />
                    </Box>
                    <Skeleton variant="rounded" width={20} height={20} />
                </Stack>
            </Box>
            <Box sx={css.skeleton.wrapper}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Box sx={css.skeleton.content.wrapper}>
                        <Skeleton variant="rounded" width={24} height={24} />
                        <Skeleton
                            variant="text"
                            width='100%'
                            sx={css.skeleton.content.text}
                        />
                    </Box>
                    <Skeleton variant="rounded" width={20} height={20} />
                </Stack>
            </Box>
            <Box sx={css.skeleton.wrapper}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Box sx={css.skeleton.content.wrapper}>
                        <Skeleton variant="rounded" width={24} height={24} />
                        <Skeleton
                            variant="text"
                            width='100%'
                            sx={css.skeleton.content.text}
                        />
                    </Box>
                    <Skeleton variant="rounded" width={20} height={20} />
                </Stack>
            </Box>
            <Box sx={css.skeleton.wrapper}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Box sx={css.skeleton.content.wrapper}>
                        <Skeleton variant="rounded" width={24} height={24} />
                        <Skeleton
                            variant="text"
                            width='100%'
                            sx={css.skeleton.content.text}
                        />
                    </Box>
                    <Skeleton variant="rounded" width={20} height={20} />
                </Stack>
            </Box>
        </Box>
    );

    return { categories };
};

import React from 'react';
import { client } from '@api';
import {
    AddCoupomCallProps,
    AddItemDataProps,
    HandleItemProps,
} from '@interfaces/Services';
import { DEFAULTS } from './DEFAULTS';
import { servicesUtils } from '@utils';
import { useGlobal } from '@hooks/global';
import { StoreType } from '@types';

export const userCart = () => {
    const { services } = client();
    const global = useGlobal();
    const [listCart, setListCart] = React.useState(
        DEFAULTS.USER.CART.LIST,
    );

    const cartType = localStorage.getItem('storeType') as StoreType ?? 'SHOPPING';

    const load = async () => {
        const session = await global.app.cookies.get('session');
        if (session.error) {
            const response = await services.user.cart.load({ cartType });
            setListCart((prev) => prev = {
                ...prev,
                data: response?.response.payload ?
                    response?.response.payload : DEFAULTS.USER.CART.LIST.data,
                status: servicesUtils.noValidToken.response.status,
                message: servicesUtils.noValidToken.response.message,
                isLoaded: false,
            });
            return servicesUtils.noValidToken;
        }
        await services.purchase.shipping.calculateValue(cartType);
        const response = await services.user.cart.load({ cartType });
        setListCart((prev) => prev = {
            message: response?.response.message ?
                response?.response.message :
                '',
            isLoaded: true,
            status: response?.response.status,
            data: response?.response.payload ?
                response?.response.payload : DEFAULTS.USER.CART.LIST.data,
        });
    };

    const clear = () => {
        return setListCart(DEFAULTS.USER.CART.LIST);
    };

    const addItem = async (data: AddItemDataProps) => {
        return await services.user.cart.addItem({
            cartType, data,
        });
    };

    const deleteItem = async (data: HandleItemProps) => {
        return await services.user.cart.deleteItem({ data, cartType });
    };

    const addCoupon = async (data: AddCoupomCallProps) => {
        return await services.user.cart.addCoupon({ ...data });
    };

    const removeCoupon = async () => {
        return await services.user.cart.removeCoupon({ cartType });
    };

    const addPoints = async () => {
        return await services.user.cart.addPoints();
    };

    const removePoints = async () => {
        return await services.user.cart.removePoints();
    };

    const addWallet = async () => {
        return await services.user.cart.addWallet({ cartType });
    };

    const removeWallet = async () => {
        return await services.user.cart.removeWallet({ cartType });
    };

    return {
        cart: {
            load,
            clear,
            handleItem: {
                add: addItem,
                delete: deleteItem,
            },
            list: listCart,
            coupon: {
                add: addCoupon,
                remove: removeCoupon,
            },
            points: {
                add: addPoints,
                remove: removePoints,
            },
            wallet: {
                add: addWallet,
                remove: removeWallet,
            },
        },
    };
};

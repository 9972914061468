import { OrderProps } from '@interfaces/Services';
import { OrderStatusType } from '@types';

interface OrderStatusListProps {
    active: boolean;
    date: string;
    time: string;
}

export const findOrderStatus = (currentStatus: OrderStatusType, order: OrderProps) => {
    let result = {} as OrderStatusListProps;
    const response =
        order.statusHistory.find((status) =>
            status.status === currentStatus);

    if (!!(response)) {
        const [date, time] = response?.date.split('T') as string[];
        const dateFormated = new Date(date)
            .toLocaleDateString('pt-BR', { timeZone: 'UTC' });
        const timeFormated = time.split('.')[0];

        result = {
            active: true,
            date: dateFormated,
            time: timeFormated,
        };
    } else {
        result = {
            active: false,
            date: '',
            time: '',
        };
    };

    return result;
};

/* eslint-disable no-unused-vars */
import { TableList, AvatarGroup, LegendButton, HeaderPage } from '@components';
import { Box, Stack, Table, TableBody } from '@mui/material';
import { Link } from 'react-router-dom';
import PlaceholderIMG from '@img/no-image.png';
import { sx } from './sx';
import { files, phone, styles } from '@utils';
import { useUser } from '@hooks/user';

const View: React.FC = () => {
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();

    const user = useUser();

    return (
        <Stack
            sx={{ ...css.stack }}>
            <HeaderPage
                noPadding
                icon={['far', 'user-circle']}
                titleAlign={'default'} title={'Minha Conta'} />
            <Stack spacing={2} p={mediaQuery.lg ? 2 : 0}>
                <Box sx={{ ...css.box.wrapper }}>
                    <Box sx={{ ...css.box.row }}>
                        <Box sx={{ ...css.box.avatar }}>
                            <AvatarGroup
                                borderWidth='none'
                                align={'right'}
                                size={'medium'}
                                title={user.info.data.name}
                                titleCapitalize
                                src={user.info.data.images ?
                                    files.load(
                                        user.info.data.images.avatar,
                                        'server',
                                    ) : ''}
                            />
                        </Box>
                        <Link
                            style={{ ...css.link }}
                            to={'/minha-conta/editar'}>
                            <LegendButton
                                actionType={'edit'}
                                actionFunction={() => null} />
                        </Link>
                    </Box>
                    <Table>
                        <TableBody sx={{ ...css.table.body }}>
                            <TableList
                                align={'left'}
                                keyTab={'Email'}
                                value={user.info.data.email} />
                            <TableList
                                align={'left'}
                                keyTab={'CPF'}
                                value={user.info.data.cpf} />
                            <TableList
                                align={'left'}
                                keyTab={'Telefone'}
                                value={user.info.isLoaded ?
                                    phone.formatNumber({
                                        ddd: user.info.data.phone.ddd,
                                        number: user.info.data.phone.number,
                                    }) : ''} />
                        </TableBody>
                    </Table>
                </Box>
                <Box sx={{ ...css.box.wrapper, ...css.box.password }}>
                    <Table>
                        <TableBody sx={{ ...css.table.body }}>
                            <TableList
                                align={'between'}
                                keyTab={'Minha senha'}
                                noPaddingSide
                                value={
                                    <Link to={'/minha-conta/alterar-senha'}>
                                        <LegendButton
                                            actionType={'edit'}
                                            actionFunction={() => null} />
                                    </Link>} />
                            <TableList
                                align={'left'}
                                keyTab={'Senha'}
                                noPaddingSide
                                value={'***************'} />
                        </TableBody>
                    </Table>
                </Box>
            </Stack>
        </Stack>
    );
};

export { View };

import { ContextsProps } from '@interfaces/Contexts';
import { LayoutContext } from './context';
import { layoutProvider } from '../../hooks/layout';

export const LayoutProvider = ({ children }: ContextsProps) => {
    const providerValue = layoutProvider();
    return (
        <LayoutContext.Provider value={providerValue}>
            {children}
        </LayoutContext.Provider>
    );
};

import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();

    const css = {
        box: {
            wrapper: {
                display: 'flex',
                border: `${rem(1)} solid ${palette.cityLights}`,
            },
            icon: {
                p: 1,
                mx: rem(10),
                minHeight: rem(32),
                minWidth: rem(32),
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: palette.primaryColor,
                borderRadius: 100,
                fontSize: rem(14),
                color: palette.common.white,
            },
            hightlight: {
                backgroundColor: palette.darkGray,
                color: palette.common.white,
                borderRadius: rem(4),
                px: 1,
                py: 0.5,
                mb: rem(10),
            },
        },
        texts: {
            title: {
                fontSize: rem(12),
                fontWeight: typography.fontWeightBold,
            },
            list: {
                'fontWeight': typography.fontWeightMedium,
                'fontSize': rem(14),
                '& > span': {
                    'fontWeight': typography.fontWeightBold,
                },
            },
        },
        buttonEdit: {
            fontSize: rem(18),
            color: palette.darkGray,
        },
    };

    return { css };
};

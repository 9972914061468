/* eslint-disable max-len */
import React from 'react';
import {
    Box,
    Container,
    Stack,
    useTheme,
    IconButton,
    Paper,
    MenuList,
    MenuItem,
    ListItemIcon,
    Button,
    AlertColor,
    CircularProgress,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import logo from '../../../../../assets/img/logo.png';
import { rem, excerpt, styles, files } from '@utils';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import {
    menuHeader,
    menu,
    translateStoreTypeMenu,
    usermenu,
} from '../../../../../static/menus';
import { ButtonIcon, Feedback, Image, SearchForm, Template } from '@components';
import { SubMenuButton } from '../../components/submenu-button';
import { User } from './components/user';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import {
    usePopupState,
    bindHover,
    bindPopover,
} from 'material-ui-popup-state/hooks';
import { LoginOrCreateAccountButton } from './components/login-or-create-account-button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@hooks/auth';
import { useUser } from '@hooks/user';
import Cookies from 'universal-cookie';
import { useAds } from '@hooks/ads';
import { useFilters } from '@hooks/filters';
import { useGlobal } from '@hooks/global';
import { StoreType } from '@types';
import { useCustomTheme } from '@hooks/custom-theme';

// const Header: React.FC<HeaderProps> = ({ logged, user }: HeaderProps) => {
const Header: React.FC = () => {
    const cookies = new Cookies(null, { path: '/' });
    const { customTheme } = useCustomTheme();
    const { typography, palette } = useTheme();
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();
    const auth = useAuth();
    const user = useUser();
    const ad = useAds();
    const global = useGlobal();
    const { clearFilters } = useFilters();

    const location = useLocation();
    const navigate = useNavigate();
    const [popover, setPopover] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [logoutLoading, setLogoutLoading] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
    });

    const logged = !!(
        user.info.isLoaded &&
        !(user.info.status === 403) &&
        user.info.data.validated
    );

    const handleClose = () => setOpen(false);

    const username = excerpt({
        text: user.info?.data.name ?? '',
        excerptMaxTextLength: 2,
    });

    const popupState = usePopupState({ variant: 'popper', popupId: popover });

    const handlePopupId = (popupId: string) => {
        setPopover(popupId);
    };

    const logoutUser = async () => {
        setOpen(false);
        setLogoutLoading(true);
        const response = await auth.logout();

        if (!response?.error) {
            cookies.remove('session');
            cookies.remove('rewardhub-session');
            setOpen(true);
            setResponseData({
                type: response?.response.type,
                message: response?.response.message,
            });
            await user.load({ jwt: '' });
            user.loadHooks();
            setLogoutLoading(false);
            navigate('/');
        }
    };

    React.useEffect(() => {
        if (popupState.popupId === 'categories' && !popupState.isOpen) {
            console.log('LEAVE: ', popupState.popupId, popupState.isOpen);
            if (ad.categories.list.history.length >= 1) {
                ad.categories.reset({ type: 'history' });
                ad.categories.reset({ type: 'categories' });
            }
        }
    }, [popupState.popupId, popupState.isOpen]);

    React.useEffect(() => {
        if (!location.pathname.includes('/resultados/busca/')) {
            clearFilters();
            if (ad.categories.list.history.length >= 1) {
                ad.categories.reset({ type: 'history' });
                ad.categories.reset({ type: 'categories' });
            }
        }
    }, [location.search]);

    React.useEffect(() => {
        const prop = localStorage.getItem('storeType');

        if (prop) {
            global.app.view.set(prop as StoreType);
        } else {
            global.app.view.set('SHOPPING');
        }
    }, []);
    // Header
    return (
        <React.Fragment>
            <Box sx={css.header.wrapper} className="MuiHeader">
                <Container maxWidth='lg'>
                    <Box sx={css.header.content.wrapper}>
                        <Box sx={css.header.content.col1.wrapper}>
                            <Box sx={{ mr: rem(30) }} onClick={() => navigate('/')}>
                                <Image src={!!customTheme.data.logo ? files.load(customTheme.data.logo, 'server') : logo} alt='Logo' maxHeight={42} />
                            </Box>
                            {logged && (
                                <Box sx={css.header.content.col1.nav.wrapper}>
                                    {mediaQuery.lg &&
                                        menuHeader.map(({ id, title, href }, i) => {
                                            return (
                                                <Button
                                                    key={id}
                                                    variant="text"
                                                    onClick={() => navigate(href)}
                                                    sx={css.
                                                        header.
                                                        content.
                                                        col1.
                                                        nav.item(window
                                                            .location.
                                                            href.
                                                            includes(href)).wraper}>
                                                    {title}
                                                    <Box
                                                        sx={
                                                            css.
                                                                header.
                                                                content.
                                                                col1.
                                                                nav
                                                                .item(window.
                                                                    location
                                                                    .href.
                                                                    includes(href))
                                                                .overlay}
                                                    />
                                                </Button>
                                            );
                                        })}

                                    {!mediaQuery.lg && (
                                        <IconButton
                                            onMouseEnter={() => handlePopupId('mainMenu')}
                                            {...bindHover({
                                                ...popupState,
                                            })}
                                        >
                                            <I
                                                icon={['far', 'bars']}
                                                fontSize={rem(18)}
                                                color={palette.darkGray} />
                                        </IconButton>
                                    )}
                                </Box>
                            )}
                        </Box>

                        <Box sx={css.header.content.col2.wrapper}>
                            <Box sx={{
                                minWidth: mediaQuery.md ? rem(330) : rem(320),
                                mr: rem(30),
                            }}>
                                <SearchForm />
                            </Box>
                            {logged && (
                                <User
                                    username={username}
                                    avatar={user.info.data.images ?
                                        files.load(
                                            user.info.data.images.avatar,
                                            'server',
                                        ) : ''}
                                    balance={user.info?.data.availableBalance ?? 0}>
                                    <Paper sx={{ width: 230 }}>
                                        <MenuList>
                                            {usermenu.map(({
                                                id,
                                                title,
                                                href,
                                                hash,
                                                icon,
                                                action,
                                            }) => {
                                                const click = action === 'logout' ?
                                                    logoutUser :
                                                    () => navigate(href);
                                                return (
                                                    <MenuItem
                                                        key={id}
                                                        onClick={click}
                                                    >
                                                        <ListItemIcon>
                                                            <I
                                                                icon={icon}
                                                                fontSize={rem(15)}
                                                                color={palette.darkGray}
                                                            />
                                                        </ListItemIcon>
                                                        <Typography
                                                            variant="inherit"
                                                            fontSize={rem(13)}
                                                            fontWeight={typography.
                                                                fontWeightSemiBold}>
                                                            {title}
                                                        </Typography>
                                                        {logoutLoading &&
                                                            hash === 'logout' &&
                                                            (
                                                                <CircularProgress
                                                                    size={12}
                                                                    sx={{
                                                                        color:
                                                                            palette.
                                                                                darkGray,
                                                                    }} />
                                                            )}
                                                    </MenuItem>
                                                );
                                            })}
                                        </MenuList>
                                    </Paper>
                                </User>
                            )}
                            {!logged && (
                                <LoginOrCreateAccountButton
                                    onClick={() => navigate('/auth/login')}
                                />
                            )}
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box sx={css.sub.wrapper}>
                <Container maxWidth='lg'>
                    <Stack direction='row' justifyContent='space-between'>
                        <Box>
                            {menu('filters').map(({ id, title, icon, hash }) => {
                                const verifyTitle = hash === 'storeType' ?
                                    translateStoreTypeMenu[global.app.view.type] :
                                    title;
                                return (
                                    <SubMenuButton
                                        key={id}
                                        icon={icon}
                                        title={verifyTitle}
                                        showDropDownIcon
                                        onMouseEnter={() => handlePopupId(hash)}
                                        {...bindHover({
                                            ...popupState,
                                        })}
                                    />
                                );
                            })}
                        </Box>
                        <Stack
                            direction='row'
                            alignItems='center'
                            spacing={2}>
                            <Link to={'/favoritos'}>
                                <ButtonIcon
                                    icon={'heart'}
                                    size='medium'
                                    badge={
                                        user.favorites?.list?.data?.favorites?.length}
                                    active />
                            </Link>
                            <Link to={'/meu-carrinho'}>
                                <ButtonIcon
                                    icon={'shopping-cart'}
                                    size='medium'
                                    badge={user.cart?.list?.data?.totalProducts}
                                    active />
                            </Link>
                        </Stack>
                    </Stack>
                </Container>
            </Box>

            <HoverPopover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {popover === 'storeType' && <Template.CurrencyType />}
                {popover === 'partners' && (
                    <Box p={2} sx={{ minWidth: 320 }}>
                        <Template.Partners />
                    </Box>
                )}
                {popover === 'categories' && (
                    <Box sx={{ minWidth: 320 }}>
                        <Template.Categories origin='headerBottomMenu' />
                    </Box>
                )}
                {popover === 'mainMenu' && (
                    <Box pl={2} pr={2}>
                        <Template.Main />
                    </Box>
                )}
            </HoverPopover>

            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </React.Fragment>
    );
};

export { Header };

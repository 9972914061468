import React from 'react';
import Slider from 'react-slick';
import {
    Box,
} from '@mui/material';
import { Image } from '@components';
import { settings } from './slider-settings';
import { sx } from './sx';
import { files, styles } from '@utils';
interface FileThumbnailStaticProps {
    play: () => void;
    pause: () => void;
}
import PlaceholderIMG from '@img/no-image.png';

export interface ProductSliderProps {
    imagesSlider: string[];
    children?: React.ReactNode;
}

const ProductSlider: React.FC<ProductSliderProps> = ({
    imagesSlider, children,
}: ProductSliderProps) => {
    const player = React.useRef<FileThumbnailStaticProps>(null);
    const { sxBox, sxBoxImg, sxBoxDots } = sx();
    const mediaQuery = styles.mediaQueryCSS();

    const settingsDesk = {
        ...settings,
        dotsClass: 'slick-dots slick-thumb',
        customPaging: function(i: number) {
            return (
                <a>
                    <Box sx={{ ...sxBoxDots }}>
                        <Image
                            src={files.load(imagesSlider[i], 'server')}
                            alt=""
                            objectFit='contain'
                            maxHeight={48} />
                    </Box>
                </a>
            );
        },
    };

    const handleSettings = settingsDesk;
    return (
        <Box sx={{ ...sxBox }}>
            <Slider
                {...handleSettings}
                afterChange={() => player.current?.pause()}
                adaptiveHeight
                className={'product-pictures'}>
                {imagesSlider.length > 0 ? imagesSlider.map((src, i ) => {
                    return (
                        <Box key={`${i}-${src}`} sx={{ ...sxBoxImg }}>
                            <Image
                                src={files.load(src, 'server')}
                                alt=""
                                objectFit='contain'
                                maxHeight={mediaQuery.sm ? 378 : 220} />
                        </Box>
                    );
                }) :
                    <Box sx={{ ...sxBoxImg }}>
                        <Image
                            src={PlaceholderIMG}
                            alt=""
                            objectFit='contain'
                            maxHeight={mediaQuery.sm ? 378 : 220} />
                    </Box>
                }
            </Slider>
            {children}
        </Box>
    );
};

export { ProductSlider };

import React from 'react';
import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

interface HistoryBackMenuItemProps {
    title: string;
    onClick: () => void;
}

import { sx } from './sx';

export const HistoryBackMenuItem: React.FC<HistoryBackMenuItemProps> = ({
    title, onClick,
}: HistoryBackMenuItemProps) => {
    const { sxBox, sxIcon, sxTitle } = sx();
    return (
        <Box
            sx={{ ...sxBox }}
            onClick={onClick}>
            <I
                style={{ ...sxIcon }}
                icon={['far', 'chevron-left']} />
            <Typography
                variant='h3'
                sx={{ ...sxTitle }}>
                {title}
            </Typography>
        </Box>
    );
};

/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Container,
    IconButton,
    InputAdornment,
    Snackbar,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { rem, responseErrors, styles } from '@utils';
import { sx } from './sx';
import React from 'react';
import { ButtonTextArrow } from '../../components/text-arrow-button';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import $ from 'jquery';
import 'jquery-mask-plugin';
import { useUser } from '@hooks/user';
import { DEFAULTS } from './DEFAULTS';
import { APIObjectErrorsProps } from '@interfaces/Services';
import { Feedback } from '@components';

export interface CreateAccountForm {
    name: string;
    email: string,
    cpf: string,
    phone: string
    password: string,
    confirmPassword: string,
}

const Form: React.FC = () => {
    const { typography, palette } = useTheme();
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();
    const user = useUser();

    const navigate = useNavigate();
    const textAlign = mediaQuery.md ? 'center' : 'left';

    const [showPassword, setShowPassword] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(DEFAULTS.loading);
    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
    });

    const [cardErrors, setCardErrors] = React.
        useState<APIObjectErrorsProps>({
            listErrors: DEFAULTS.API_OBJ,
        });
    const errorKeys = Object.keys(cardErrors);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<CreateAccountForm>({
        mode: 'onSubmit',
    });

    const onSubmit: SubmitHandler<CreateAccountForm> = async (data) => {
        setOpen(false);
        setCardErrors({
            listErrors: DEFAULTS.API_OBJ,
        });

        setIsLoading((prev) => prev = {
            ...prev,
            form: true,
        });
        const response = await user.account.create({
            name: data.name,
            email: data.email,
            password: data.password,
            cpf: data.cpf,
            phone: '+55 ' + data.phone,
        });

        setIsLoading(DEFAULTS.loading);
        setResponseData({
            type: response?.response.type,
            message: response?.response.message,
        });
        setOpen(true);
        if (!response?.error) {
            setTimeout(() => {
                navigate('/auth/criar-conta/enviar-codigo');
            }, 1500);
            return;
        } else {
            if (response.response.validationErros) {
                const errors = responseErrors(
                    response.response.validationErros,
                    errorKeys,
                );

                setCardErrors(errors as any);
                return;
            }
        }
    };

    const handleClose = () => setOpen(false);

    const inputMasks = () => {
        $('.phone').mask('(00) 00000-0000');
        $('.cpf').mask('000.000.000-00');
    };

    React.useEffect(() => {
        inputMasks();
    }, []);

    return (
        <Box sx={css.wrapper}>
            <Container maxWidth='xs'>
                <Box>
                    <Typography
                        variant="body2"
                        component='h1'
                        align={textAlign}
                        fontSize={rem(22)}
                        color='darkGray'
                        fontWeight={typography.fontWeightBold}
                        gutterBottom>
                        Criar Conta
                    </Typography>
                    <Typography
                        variant="body2"
                        component='h1'
                        align={textAlign}
                        fontSize={rem(14)}
                        color='darkGray'
                        fontWeight={typography.fontWeightMedium}>
                        Para possibilitar o resgate de prêmios, por favor,
                        preencha os dados abaixo.
                    </Typography>
                    <Box component='form' onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={3} mb={rem(20)} mt={rem(30)}>
                            <TextField
                                fullWidth
                                focused={true}
                                label={'Nome completo *'}
                                id="name"
                                helperText=''
                                variant="outlined"
                                error={!!errors.name}
                                {...register('name',
                                    { required: true },
                                )} />
                            <TextField
                                fullWidth
                                focused={true}
                                label={'E-Mail *'}
                                id="email"
                                helperText=''
                                variant="outlined"
                                error={!!errors.email}
                                {...register('email',
                                    {
                                        required: true,
                                    },
                                )} />
                            <TextField
                                fullWidth
                                focused={true}
                                label={'CPF *'}
                                id="cpf"
                                helperText={(cardErrors.
                                    hasOwnProperty(
                                        'cpf') &&
                                    cardErrors.cpf.message)}
                                variant="outlined"
                                inputProps={{
                                    className: 'cpf',
                                }}
                                error={!!errors.cpf}
                                {...register('cpf',
                                    { required: true },
                                )} />
                            <TextField
                                fullWidth
                                focused={true}
                                label={'Telefone *'}
                                id="phone"
                                helperText=''
                                variant="outlined"
                                inputProps={{
                                    className: 'phone',
                                }}
                                error={!!errors.phone}
                                {...register('phone',
                                    { required: true },
                                )} />
                            <TextField
                                fullWidth
                                focused={true}
                                label={'Senha *'}
                                id="password"
                                type={!showPassword ? 'password' : 'text'}
                                helperText=''
                                variant="outlined"
                                error={!!errors.password}
                                {...register('password',
                                    { required: true },
                                )}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setShowPassword(!showPassword)}
                                                sx={{}}>
                                                <I fontSize={(rem(18))}
                                                    icon={['fas', showPassword ?
                                                        'eye-slash' :
                                                        'eye']}
                                                    color={palette.dark} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                            <TextField
                                fullWidth
                                focused={true}
                                label={'Repita Sua Senha *'}
                                id="confirm-password"
                                type={!showPassword ? 'password' : 'text'}
                                helperText={!!errors.confirmPassword &&
                                    errors.confirmPassword.message}
                                variant="outlined"
                                error={!!errors.confirmPassword}
                                {...register('confirmPassword', {
                                    required: true,
                                    validate: (val: string) => {
                                        if (watch('password') != val) {
                                            return 'As senhas não são iguais';
                                        }
                                    },
                                })}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setShowPassword(!showPassword)}
                                                sx={{}}>
                                                <I fontSize={(rem(18))}
                                                    icon={['fas', showPassword ?
                                                        'eye-slash' :
                                                        'eye']}
                                                    color={palette.dark} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Stack>
                        <Stack
                            direction='row'
                            spacing={2}
                            justifyContent='space-between'
                            mt={rem(15)}>
                            <Button
                                fullWidth
                                type='submit'
                                variant='contained'
                                size='large'
                                disabled={isLoading.form}
                            >
                                Criar conta
                                {isLoading.form &&
                                    <CircularProgress
                                        color="inherit"
                                        size={14}
                                        sx={{ ml: 1 }}
                                    />}
                            </Button>
                            <ButtonTextArrow
                                text='Fazer login'
                                disabled={isLoading.form}
                                onClick={() => navigate('/auth/login')} />
                        </Stack>
                    </Box>
                </Box>
            </Container>

            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box>
    );
};

export { Form };

import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        box: {
            check: {
                backgroundColor: palette.status.accepted,
                color: palette.common.white,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: rem(67),
                height: rem(67),
                fontSize: rem(36),
                borderRadius: rem(100),
            },
        },
        stack: {
            message: {
                backgroundColor: '#F9F9F9',
                p: 2,
                alignItems: 'center',
            },
        },
        text: {
            message: {
                fontSize: rem(16),
                fontWeight: typography.fontWeightRegular,
                textAlign: 'center' as any,
            },
            p: {
                fontSize: rem(14),
                color: palette.darkGray,
                mt: '-0.375rem !important',
            },
            link: {
                fontWeight: typography.fontWeightBold,
                color: palette.mediumVioletRed,
                marginLeft: rem(5),
            },
        },
    };

    return { css };
};

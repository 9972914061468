/* eslint-disable max-len */
import React from 'react';
import { Stack, Box } from '@mui/material';

import { sx } from './sx';

interface VariationItemProps {
    onClick?: () => void;
    srcImage: string;
    active?: boolean;
    _id?: string;
}

const VariationItem: React.FC<VariationItemProps> = ({
    srcImage, onClick, _id, active,
}: VariationItemProps) => {
    const { css } = sx();

    return (
        <Stack
            width={46}
            direction={'column'}
            component='label'
            flexWrap={'wrap'}
            htmlFor={_id}>
            <Box
                onClick={onClick}
                className={active ? 'active' : ''}
                sx={{ ...css.sxBoxWrapper }} >
                <Box sx={{ ...css.sxBoxImage }} >
                    <img src={srcImage} alt="" />
                </Box>
            </Box>
        </Stack>
    );
};

export { VariationItem };

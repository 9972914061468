import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxBox: {
            margin: rem(15),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        sxMessage: {
            color: palette.darkGray,
            fontWeight: typography.fontWeightMedium,
            fontSize: rem(16),
        },
    };

    return { css };
};

/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {

    interface Palette {
        primaryColor: string;
        secondaryColor: string;
        mediumVioletRed: string;
        mediumGray: string;
        dark: string;
        darkGray: string;
        clouds: string;
        snowWhite: string;
        cityLights: string;
        customBlack: string;
        lightBg: string;
        status: {
            pending: string;
            rejected: string;
            accepted: string;
            processing: string;
            info: string;
        }
    }

    interface PaletteOptions {
        primaryColor: string;
        secondaryColor: string;
        mediumVioletRed: string;
        mediumGray: string;
        dark: string;
        darkGray: string;
        clouds: string;
        snowWhite: string;
        cityLights: string;
        customBlack: string;
        lightBg: string;
        status: {
            pending: string;
            rejected: string;
            accepted: string;
            processing: string;
            info: string;
        }
    }

}

declare module '@mui/material/styles/createTypography' {
    interface FontStyle {
        fontWeightSemiBold: number;
    }
}

interface ThemeProps {
    primaryColor?: string;
    secondaryColor?: string;
}

const theme = ({
    primaryColor, secondaryColor,
}: ThemeProps) => {
    return createTheme({
        components: {
            MuiButton: {
                defaultProps: {
                    sx: {
                        '&.MuiButton-contained:hover': (
                            { palette },
                        ) => {
                            return {
                                color: palette.common.white,
                                backgroundColor: palette.secondaryColor + ' !important',
                            };
                        },
                        '&.MuiButton-contained:active': ({ palette }) => {
                            return {
                                color: palette.common.white,
                                backgroundColor: palette.secondaryColor + ' !important',
                            };
                        },
                    },
                },
                styleOverrides: {
                    root: ({ ownerState, theme }) => ({
                        ...(ownerState.variant === 'contained' && {
                            backgroundColor: theme.palette.primaryColor,
                            color: theme.palette.common.white,
                            boxShadow: 'none',
                            fontFamily: theme.typography.fontFamily,
                        }),
                        ...(ownerState.variant === 'outlined' && {
                            borderColor: theme.palette.darkGray + '!important',
                            color: theme.palette.darkGray,
                            fontFamily: theme.typography.fontFamily,
                        }),
                        ...(ownerState.variant === 'contained' && '&:hover' && {
                            backgroundColor: theme.palette.primaryColor,
                        }),
                        ...(ownerState.variant === 'text' && {
                            borderColor: theme.palette.darkGray + '!important',
                            color: theme.palette.darkGray,
                            fontFamily: theme.typography.fontFamily,
                        }),
                    }),
                },
            },
            MuiOutlinedInput: {
                defaultProps: {
                    sx: {
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '.0625rem',
                            borderColor: ({ palette }) => palette.clouds,
                        },
                        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                            borderColor: ({ palette }) => palette.status.rejected,
                        },
                        '&.Mui-readOnly': {
                            'backgroundColor': '#F9FAFE',
                        },
                        ...{
                            color: ({ palette }) => palette.dark,
                            backgroundColor: ({ palette }) => palette.common.white,
                        },
                    },
                },
            },
            MuiInputLabel: {
                defaultProps: {
                    sx: {
                        '&.Mui-focused': ({ typography, palette }) => {
                            return {
                                fontWeight: typography.fontWeightSemiBold,
                                color: palette.dark,
                                // fontFamily: theme.typography.body1,
                            };
                        },
                    },
                },
            },
            MuiRadio: {
                defaultProps: {
                    sx: {
                        '&.MuiRadio-root': {
                            color: '#010101',
                            padding: 0,
                        },
                    },
                },
            },
        },
        typography: {
            fontFamily: `'Open Sans', sans-serif`,
            fontWeightSemiBold: 600,
        },
        palette: {
            primaryColor: !!primaryColor ?
                primaryColor :
                '#5104E1',
            secondaryColor: !!secondaryColor ?
                secondaryColor :
                '#6713E1',
            mediumVioletRed: secondaryColor ?? '#BB2385',
            mediumGray: '#353B48',
            darkGray: '#2F3640',
            dark: '#222222',
            customBlack: '#010101',
            clouds: '#D6DADB',
            snowWhite: '#F6F6F9',
            cityLights: '#EEEBEB',
            lightBg: '#FAFAFB',
            status: {
                pending: '#E3B487',
                rejected: '#BC1823',
                accepted: '#27AE60',
                processing: '#7F98AA',
                info: '#353B48',
            },
        },
    });
};

export { theme };

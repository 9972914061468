/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { Filters, HeaderPage, MenuItem, MessageBox } from '@components';
import { useAds } from '@hooks/ads';
import { useFilters } from '@hooks/filters';
import {
    Box,
    Checkbox,
    Drawer,
    Grid,
    MenuList,
    Typography,
    useTheme,
} from '@mui/material';
import { numbers, rem } from '@utils';
import React from 'react';
import { Item, Label } from './styles';
import { LoadSizeGroupsByIDProps, SizeGroupsListProps } from '@interfaces/Services';
import { TempFilterProps } from '@interfaces/Filters';
import { useSearchParams } from 'react-router-dom';
import { FILTERS_KEYS } from '@static/filters';
import { sx } from './sx';

interface SelectedSizeGroupProps extends SizeGroupsListProps {
    isLoaded: boolean;
}

const DEFAULTS = {
    SELECTED_SIZE_GROUP: {
        _id: '',
        name: '',
        isLoaded: false,
    },
};

export const Sizes = () => {
    const { typography, palette } = useTheme();
    const { sizes } = useAds();
    const { css } = sx();
    const { externalActions, tempFilter, handleFilters } = useFilters();
    const [search, setSearch] = useSearchParams();

    const [drawerFiltersMenu, setDrawerFiltersMenu] = React.useState(false);
    const [tempSizes, setTempSizes] = React.useState<TempFilterProps[]>([]);
    const [selectSizeGroup, setSelectSizeGroup] = React.useState(
        DEFAULTS.SELECTED_SIZE_GROUP as SelectedSizeGroupProps,
    );

    const handleOnChance = (
        { _id, name, filterType }: TempFilterProps,
    ) => (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('FILTER SIZE: ', _id);
        const item = `${name}|${_id}`;
        let sizes = search.get(FILTERS_KEYS.SIZE)?.split(',') ?? [];
        if (e.target.checked) {
            sizes.push(item);
            setTempSizes(
                (prev) => (prev = [...prev, { _id, name, filterType }]),
            );
        } else {
            sizes = sizes.filter((id) => id !== item);
            const filter = tempSizes.filter((item) => item._id !== _id);
            setTempSizes((prev) => (prev = [...filter]));
        }

        if (sizes.length === 0) {
            search.delete(FILTERS_KEYS.SIZE);
        } else {
            search.set(FILTERS_KEYS.SIZE, sizes.join(','));
            search.set(FILTERS_KEYS.ALEATORY, numbers.randomNumber({ maxRange: 100 }).toString());
        }
        externalActions.action({ action: 'size', state: true });
        setSearch(search);
    };

    const handleToggleDrawerFiltersMenu = (type: 'open' | 'close') => {
        setDrawerFiltersMenu((prev) => {
            if (type === 'open') {
                return prev = true;
            }

            if (type === 'close') {
                return prev = false;
            }
            return prev;
        });
    };

    const searchSizeGroupByID = async ({ _id, name }: SizeGroupsListProps) => {
        setSelectSizeGroup(DEFAULTS.SELECTED_SIZE_GROUP);
        const response = await sizes.load.sizeGroupsByID({ sizeGroupId: _id });
        console.log('SIZE GROUPS: ', response);
        setSelectSizeGroup((prev) => prev = {
            _id,
            name,
            isLoaded: true,
        });
        // handleToggleDrawerFiltersMenu('open');
    };

    const loadSizeGroups = async () => {
        const list = ['652412d953724837efbc813a'];
        const response = await sizes.load.sizeGroups({
            categorizations: list,
        });
    };

    React.useEffect(() => {
        loadSizeGroups();
    }, []);

    React.useEffect(() => {
        handleFilters('categoryList', tempSizes);
    }, [tempSizes]);

    React.useEffect(() => {
        setTempSizes(tempFilter.size);
    }, [tempFilter.size]);

    return (
        <Box position='relative'>
            <MenuList>
                {sizes.list.sizeGroups.isLoaded && (
                    sizes.list.sizeGroups.data.map(({ _id, name }) => (
                        <MenuItem
                            size={'tiny'}
                            title={name}
                            onClick={() => searchSizeGroupByID({ _id, name })}
                        />
                    ))
                )}
            </MenuList>

            {selectSizeGroup.isLoaded && (
                <Box sx={css.sizesPanel.wrapper}>
                    <HeaderPage
                        noPadding
                        size='small'
                        icon={['far', 'chevron-left']}
                        backgroundColor={palette.snowWhite}
                        titleAlign={'default'}
                        title={selectSizeGroup.name}
                        onClick={() => setSelectSizeGroup(DEFAULTS.SELECTED_SIZE_GROUP)} />
                    <Box mt={2}>
                        <Grid container spacing={2}>
                            {sizes.list.sizeGroupsByID.isLoaded && (
                                sizes.list.sizeGroupsByID.data.length > 0 ?
                                    sizes.list.sizeGroupsByID.data.map((size) => (
                                        <Grid item key={size._id} xs={3}>
                                            <Item className={!!tempSizes
                                                .find((item) =>
                                                    item._id === size._id) ?
                                                'active' :
                                                ''}>
                                                <Label htmlFor={size._id}>
                                                    {size.name} ++
                                                    <Checkbox
                                                        id={size._id}
                                                        size='small'
                                                        value={size._id}
                                                        checked={!!tempSizes
                                                            .find((item) => item._id === size._id)}
                                                        onChange={handleOnChance({
                                                            _id: size._id,
                                                            name: size.name,
                                                            filterType: 'size',
                                                        })}
                                                    />
                                                </Label>
                                            </Item>
                                        </Grid>
                                    )) : <Grid item xs={12}>
                                        <MessageBox message={'Nada a exibir.'} type={'info'} />
                                    </Grid>
                            )}
                        </Grid>
                    </Box>
                </Box>
            )}

            <Drawer
                anchor='right'
                open={drawerFiltersMenu}
                onClose={() => handleToggleDrawerFiltersMenu('close')}
                sx={{
                    overflowX: 'hidden',
                    p: 0,
                    width: '280px',
                    minWidth: '280px',
                    height: '100%',
                }}
            >
                <Box sx={{
                    overflowX: 'hidden',
                    p: 0,
                    width: '280px',
                    height: '100%',
                }}>
                    <Filters.FilterHeader
                        title='Tamanhos'
                        bgColor={palette.lightBg}
                        onClose={() => handleToggleDrawerFiltersMenu('close')}
                    />

                    <button onClick={() => {
                        handleToggleDrawerFiltersMenu('close');
                        setTimeout(() => {
                            externalActions.action(
                                { action: 'categoryList', state: true },
                            );
                        }, 500);
                    }}>
                        FECHAR
                    </button>
                    <Grid container spacing={2}>
                        {sizes.list.sizeGroupsByID.isLoaded && (
                            sizes.list.sizeGroupsByID.data.map((size) => (
                                <Grid item key={size._id} xs={3}>
                                    <Item className={!!tempSizes
                                        .find((item) =>
                                            item._id === size._id) ?
                                        'active' :
                                        ''}>
                                        <Label htmlFor={size._id}>
                                            {size.name} ++
                                            <Checkbox
                                                id={size._id}
                                                size='small'
                                                value={size._id}
                                                checked={!!tempSizes
                                                    .find((item) => item._id === size._id)}
                                                onChange={handleOnChance({
                                                    _id: size._id,
                                                    name: size.name,
                                                    filterType: 'size',
                                                })}
                                            />
                                        </Label>
                                    </Item>
                                </Grid>
                            ))
                        )}
                    </Grid>
                </Box>
            </Drawer>
        </Box>
    );
};

import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        grid: {
            container: {
                pt: rem(10),
            },
        },
        box: {
            breadcrumbs: {
                mt: rem(15),
            },
            favoriteBtn: {
                position: 'absolute',
                top: mediaQuery.md ? 30 : 10,
                right: 5,
            },
            deliferyInfo: {
                display: 'flex',
                height: rem(48),
                alignItems: 'center',
                mt: rem(5),
                mb: rem(5),
                whiteSpace: 'nowrap',
            },
            pointsField: {
                mt: rem(5),
                mb: rem(20),
            },
            plusIcon: {
                width: rem(40),
                display: 'flex',
                justifyContent: 'center',
                fontSize: rem(14),
                color: palette.mediumGray,
            },
            moneyField: {
                display: 'flex',
                alignItems: 'center',
            },
            divider: {
                mt: rem(20),
                mb: rem(20),
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
            },
        },
        button: {
            favorite: {
                'borderRadius': '0',
                'color': palette.mediumGray,
                ':disabled': {
                    color: palette.clouds,
                },
                'fontSize': rem(16),
            },
            addToCart: {
                'backgroundColor': palette.common.white,
                'color': palette.mediumGray,
                'border': `${rem(1)} solid ${palette.clouds}`,
                '&:hover': {
                    'boxShadow': 'none',
                },
            },
        },
        texts: {
            title: {
                fontSize: mediaQuery.md ? rem(24) : rem(18),
                mb: mediaQuery.md ? rem(0) : rem(10),
                color: palette.darkGray,
                fontWeight: typography.fontWeightBold,
            },
            deliferyInfo: {
                fontSize: rem(15),
                color: palette.mediumGray,
                fontWeight: typography.fontWeightMedium,
                mr: rem(6),
            },
            points: {
                mt: rem(15),
                mb: rem(15),
                fontSize: rem(14),
                color: palette.darkGray,
                fontWeight: typography.fontWeightBold,
            },
            money: {
                fontSize: rem(14),
                color: palette.darkGray,
                fontWeight: typography.fontWeightSemiBold,
            },
            titleSection: {
                fontSize: rem(14),
                fontWeight: typography.fontWeightSemiBold,
            },
            noStockText: {
                color: palette.status.rejected,
                fontSize: rem(12),
                fontWeight: typography.fontWeightMedium,
            },
        },
        textField: {
            points: {
                '& > .MuiOutlinedInput-root': {
                    '& > .MuiOutlinedInput-input': {
                        'fontSize': rem(14),
                    },
                },
                '& > .MuiFormLabel-root': {
                    fontWeight: typography.fontWeightMedium,
                },
            },
        },
        stack: {
            buttons: {
                mt: rem(30),
                maxWidth: mediaQuery.sm ? rem(377) : 'auto',
            },
        },
    };

    return { css };
};

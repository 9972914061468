/* eslint-disable no-unused-vars */
import React from 'react';
import { GlobalContext } from '../../contexts/global/context';
import Cookies from 'universal-cookie';
import { CookieResponseProps } from '@interfaces/Contexts';
import { StoreType } from '@types';
import { useUser } from '@hooks/user';

export const globalProvider = () => {
    const cookies = new Cookies(null, { path: '/' });
    const { info } = useUser();
    const [storeType,
        // eslint-disable-next-line max-len
        setStoreType] = React.useState<StoreType>(localStorage.getItem('storeType') as StoreType || 'SHOPPING');

    const handleViewType = (type: StoreType) => {
        localStorage.setItem('storeType', type);
        setStoreType((prev) => prev = type);
    };

    const getCookie = async (key: string) => {
        let response = {} as CookieResponseProps;
        try {
            // if (!key) throw new Error('Insira a key do cookie');
            const getJwtCookie = cookies.get(key);
            if (!getJwtCookie) throw new Error('Insira a key do cookie');

            return response = {
                error: false,
                data: { jwt: getJwtCookie },
            };
        } catch (error) {
            return response = {
                error: true,
                data: {
                    jwt: '',
                },
            };
        } finally {
            return response;
        }
    };

    const setCookie = (key: string, token: string) => {
        cookies.set(key, token);
    };

    return {
        app: {
            view: {
                type: storeType,
                set: handleViewType,
            },
            cookies: {
                get: getCookie,
                set: setCookie,
            },
        },
    };
};

export const useGlobal = () => {
    const context = React.useContext(GlobalContext);
    return context;
};

import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();


    const css = {
        container: {
            px: mediaQuery.md ? rem(24) : '0 !important',
            pb: 2,
            minHeight: 'calc(100vh - 296px)',
        },
        stack: {
            listValues: {
                px: rem(5),
            },
            paymentOptions: {
                px: rem(15),
                pt: mediaQuery.md ? rem(5) : rem(15),
                pb: rem(15),
            },
            installments: {
                pb: rem(30),
            },
            listValues2: {
                borderTop: `${rem(1)} dashed ${palette.cityLights}`,
                px: rem(5),
                pb: mediaQuery.md ? rem(0) : rem(15),
            },
        },
        grid: {
            minHeight: 'calc(100vh - 312px)',
        },
        box: {
            wrapper: {
                position: 'relative',
                minHeight: 'calc(100vh - 245px)',
            },
            wrapperStore: {
                border: `${rem(1)} solid ${palette.cityLights}`,
                mb: 2,
            },
            storeHeader: {
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
                px: rem(15),
                py: rem(8),
            },
            dividerSection: {
                mx: mediaQuery.md ? rem(15) : 0,
                borderLeft: mediaQuery.md ?
                    `${rem(1)} solid ${palette.cityLights}` : 'none',
                borderRight: mediaQuery.md ?
                    `${rem(1)} solid ${palette.cityLights}` : 'none',
                height: '100%',

            },
            wrapperSection: {
                pt: mediaQuery.md ? rem(15) : rem(5),
                height: '100%',
            },
            divider: {
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
            },
            dividerResume: {
                borderBottom: `${rem(1)} dashed ${palette.cityLights}`,
            },
            dividerMobile: {
                width: '100%',
                height: rem(5),
                backgroundColor: `${palette.cityLights}`,
            },
            productInline: {
                p: rem(15),
            },
            address: {
                px: rem(15),
            },
            total: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'end',
                justifyContent: 'center',
            },
        },
        icon: {
            squarePlus: {
                fontSize: rem(24),
                marginRight: rem(10),
                color: palette.customBlack,
            },
            paymentMenu: {
                fontSize: rem(18),
                color: palette.customBlack,
                marginRight: rem(10),
            },
        },
        select: {
            '& > .MuiSelect-select': {
                'padding': `${rem(10)} ${rem(20)}`,
                'fontSize': `${rem(14)}`,
            },
        },
    };

    return { css };
};

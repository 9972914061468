export function formatNumberToPriceBRL(price: number) {
    if (!price) {
        return '0,00';
    }

    return price.toLocaleString('pt-BR',
        {
            minimumFractionDigits: 2,
        });
};

export const removeCurrencySymbol = (price: string = '0.00') => {
    const splitBySymbol = price.split('R$');
    const [total] = splitBySymbol.join('').split('BRL');
    return total.toString().trim();
};

const formatPrice = (money: string) => {
    const removeDot = money.replace(/\./g, '');
    return removeDot.replace(/,/i, '.');
};

export const format = (moneyValue: string) => {
    if (moneyValue.includes('R$')) {
        const removeCurrency = removeCurrencySymbol(moneyValue);
        return Number(formatPrice(removeCurrency));
    }
    return Number(formatPrice(moneyValue));
};

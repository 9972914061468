import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { rem } from '@utils';

interface LoginOrCreateAccountButtonProps {
    onClick: () => void;
}

const LoginOrCreateAccountButton: React.FC<LoginOrCreateAccountButtonProps> = (
    { onClick }: LoginOrCreateAccountButtonProps,
) => {
    const { typography, palette } = useTheme();

    return (
        <Button
            type='button'
            variant='text'
            onClick={onClick}>
            <Stack direction='row' alignItems='center' spacing={1}>
                <I
                    icon={['fal', 'user-circle']}
                    fontSize={rem(24)}
                    color={palette.darkGray} />
                <Typography
                    variant="body2"
                    component='p'
                    align='left'
                    fontSize={rem(12)}
                    color='darkGray'
                    whiteSpace='nowrap'
                    fontWeight={typography.
                        fontWeightMedium}>
                    faça o login< br />
                    ou crie uma conta
                </Typography>
            </Stack>
        </Button>
    );
};

export { LoginOrCreateAccountButton };

import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

const sx = () => {
    const { palette } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        box: {
            slider: {
                '& > .slick-slider .slick-dots > li.slick-active > button': {
                    backgroundColor: `${palette.primaryColor} !important`,
                },
            },
        },
        slider: {
            button: {
                border: '1px solid '+palette.cityLights,
                backgroundColor: palette.common.white,
                minWidth: rem(!mediaQuery.sm ? 44 : 34),
                minHeight: rem(!mediaQuery.sm ? 44 : 34),
            },
        },
        quicklinks: {
            box: {
                'mt': mediaQuery.lg ? 4 : 0,
                // eslint-disable-next-line max-len
                ' & > .ScrollbarsCustom > .ScrollbarsCustom-Wrapper > .ScrollbarsCustom-Scroller > .ScrollbarsCustom-Content': {
                    'display': 'flex',
                    'alignItems': 'center',
                    'justifyContent': mediaQuery.sm ? 'center' : 'start',
                    'backgroundColor': palette.lightBg,
                    '& > .MuiBox-root': {
                        cursor: 'pointer',
                    },
                },
            },
            wrapper: {
                width: '100%',
                height: '140px',
            },
        },
    };

    return { css };
};

export { sx };

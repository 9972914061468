import { filtersProviderHook } from '@hooks/filters';
import { FiltersContext } from './context';
import { ContextsProps } from '@interfaces/Contexts';

export const FiltersProvider = ({ children }: ContextsProps) => {
    const providerValue = filtersProviderHook();
    return (
        <FiltersContext.Provider value={providerValue}>
            {children}
        </FiltersContext.Provider>
    );
};

import { client } from '@api';
import { LoadExpireMovementsProps } from '@interfaces/Services';

export const load = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/extract/receivables');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const loadExpireMovements = async ({
    month,
}: LoadExpireMovementsProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        // eslint-disable-next-line max-len
        const response = await instance.get(`/extract/points/toExpire${month ? '?month='+month : ''}`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

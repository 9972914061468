import { Box, Stack, Typography } from '@mui/material';
import { ImageAd, sx } from './sx';

interface ProductItemProps {
    srcImage: string;
    title: string;
}

const ProductItem = ({ srcImage, title }: ProductItemProps) => {
    const { css } = sx();
    return (
        <Stack alignItems={'center'} spacing={1} direction={'row'}>
            <Box
                sx={{ ...css.box.image }}>
                <ImageAd
                    alt={''}
                    src={srcImage} />
            </Box>

            <Typography sx={{ ...css.text.title }}>
                {title}
            </Typography>
        </Stack>
    );
};

export { ProductItem };

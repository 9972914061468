/* eslint-disable camelcase */
import { Box, Button, Link, Typography, useTheme } from '@mui/material';
import { sx } from './sx';

interface ContentPixBoletoProps {
    billing_link: string;
}

const ContentPixBoleto = ({ billing_link }: ContentPixBoletoProps) => {
    const { palette, typography } = useTheme();
    const { pxToRem } = typography;
    const { css } = sx();

    return (
        <Box>
            <Typography
                variant='h2'
                component='p'
                align='center'
                fontSize={pxToRem(16)}
                fontWeight={typography.fontWeightMedium}
                color={palette.dark}
                mb={pxToRem(20)}>
                Pedido realizado com sucesso!
            </Typography>

            <Typography
                align={'center'}
                mb={pxToRem(20)}>
                Você será notificada quando o pagamento for aprovado.
                Caso o pagamento não seja aprovado, este pedido será
                cancelado automaticamente.
            </Typography>

            <Box
                display='flex'
                justifyContent={'center'}
                mt={4}
                mb={4}>
                <Box
                    maxWidth={320}
                    width='100%'
                    justifyContent='center'>
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        type='button'
                    >
                        <Link href={billing_link}
                            target='_blank'
                            rel="noreferrer"
                            sx={{ ...css.link }}>
                            Ver fatura
                        </Link>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export { ContentPixBoleto };

/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { AlertColor, Box, Button, Container, Stack } from '@mui/material';
import { BoxContent, Feedback, HeaderPage, MessageBox, ProductInline } from '@components';
import { styles, files } from '@utils';
import { sx } from './sx';
import React from 'react';
import { useUser } from '@hooks/user';
import { skeleton } from './skeleton';
import { Link, useNavigate } from 'react-router-dom';

interface HandleItemProps {
    idAd: string;
    idVariation: string;
    variationItemId: string;
}

export const Favorites: React.FC = () => {
    const { css } = sx();
    const { favorites, cart } = useUser();
    const skel = skeleton();
    const mediaQuery = styles.mediaQueryCSS();
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        id: '',
        isLoadedCart: true,
        isLoadedDelete: true,
        type: 'success' as AlertColor,
        message: '',
    });

    const handleClose = () => setOpen(false);
    const handleFavorite = async ({
        idAd, idVariation, variationItemId,
    }: HandleItemProps) => {
        setResponseData((prev) => prev = {
            ...prev, isLoadedDelete: false,
            id: idVariation,
        });
        const response = await favorites.handle({
            idAd: idAd,
            idVariation: idVariation,
            variationItemId: variationItemId,
            typeAd: 'SHOPPING',
        });

        if (!response?.error) {
            setResponseData({
                id: '',
                isLoadedDelete: true,
                isLoadedCart: true,
                type: 'success',
                message: response?.response.message,
            });
            setOpen(true);
            setTimeout(() => {
                handleClose();
            }, 1500);
            await favorites.load();
        } else {
            setResponseData({
                id: '',
                isLoadedDelete: true,
                isLoadedCart: true,
                type: response?.response?.type,
                message: response?.response.message,
            });
            setOpen(true);
            setTimeout(() => {
                handleClose();
            }, 1500);
        }
    };

    const handleCart = async ({
        idAd, idVariation, variationItemId,
    }: HandleItemProps) => {
        setResponseData((prev) => prev = {
            ...prev, isLoadedCart: false,
            id: idVariation,
        });
        const response = await cart.handleItem.add({
            idAd: idAd,
            idVariation: idVariation,
            variationItemId: variationItemId,
            quantity: 1,
        });

        if (!response?.error) {
            setResponseData({
                id: '',
                isLoadedDelete: true,
                isLoadedCart: true,
                type: 'success',
                message: response?.response.message,
            });
            setOpen(true);
            setTimeout(() => {
                handleClose();
            }, 1500);
            await cart.load();
        } else {
            setResponseData({
                id: '',
                isLoadedDelete: true,
                isLoadedCart: true,
                type: response?.response?.type,
                message: response?.response.message,
            });
            setOpen(true);
            setTimeout(() => {
                handleClose();
            }, 1500);
        }
    };

    const loadFavorites = async () => {
        await favorites.load();
    };

    React.useEffect(() => {
        loadFavorites();
    }, []);


    return (
        <Box sx={{ ...css.box.wrapper }}>
            <HeaderPage
                titleAlign={'default'}
                icon={['far', 'heart']}
                title={'Favoritos'} />
            <Container sx={{ ...css.container }}>
                {!favorites.list.isLoaded && favorites.list.status === 403 && (
                    <>
                        <MessageBox
                            type={'info'}
                            message='Faça o login para ver seus favoritos'
                        />
                        <Stack
                            direction='row'
                            spacing={2}
                            justifyContent='center'>
                            <Button
                                type='submit'
                                variant='contained'
                                size='large'
                                onClick={() => navigate('/auth/login')}>
                                Fazer login
                            </Button>
                        </Stack>
                    </>
                )}
                <Stack divider={<Box sx={{ ...css.box.divider }}></Box>}>
                    {/* {favorites.list.message && favorites.list.status !== 403 &&
                        <MessageBox message={favorites.list.data.totalFavorites > 0 ? favorites.list.message :
                            'Você não possui favoritos no momento'} type={'info'} />
                    } */}
                    {favorites.list.isLoaded ?
                        (favorites.list.data?.favorites?.length > 0 ?
                            favorites.list.data.favorites.map((item) => (
                                <BoxContent
                                    actions={[
                                        {
                                            actionType: 'addToCart',
                                            actionFunction: () => handleCart({
                                                idAd: item._id,
                                                idVariation: item.variation._id,
                                                variationItemId: item.variation.items._id,
                                            }),
                                            loading: !responseData.isLoadedCart && responseData.id === item.variation._id,
                                            noLegend: !mediaQuery.lg,
                                        },
                                        {
                                            actionType: 'delete',
                                            actionFunction: () => handleFavorite({
                                                idAd: item._id,
                                                idVariation: item.variation._id,
                                                variationItemId: item.variation.items._id,
                                            }),
                                            loading: !responseData.isLoadedDelete && responseData.id === item.variation._id,
                                            noLegend: !mediaQuery.lg,
                                        },
                                    ]}>
                                    <Link
                                        style={{ textDecoration: 'none' }}
                                        to={`/produto/${item._id}?variation=${item.variation._id}&option=${item.variation.items._id}`}>
                                        <ProductInline
                                            srcImage={files.load(item.variation.images[0], 'server')}
                                            title={`${item.title}`}
                                            valueType={'SHOPPING'}
                                            discount={item.variation.items.oldValue}
                                            value={item.variation.items.actualValue} />
                                    </Link>
                                </BoxContent>
                            )) :
                            <Box sx={{ ...css.box.messageInfo }}>
                                <MessageBox message={favorites.list.data.totalFavorites > 0 ? favorites.list.message :
                                    'Você não possui favoritos no momento'} type={'info'} />
                            </Box>
                        ) :
                        [0, 1].map(() => (skel.boxContentProduct))
                    }
                </Stack>
                <Box sx={{ ...css.box.divider }}></Box>
            </Container>
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box>
    );
};

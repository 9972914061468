import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { typography, palette } = useTheme();
    const css = {
        text: {
            title: {
                fontWeight: typography.fontWeightRegular,
                fontSize: rem(16),
            },
            p: {
                fontSize: rem(14),
                color: palette.darkGray,
                mt: '-0.375rem !important',
            },
            resume: {
                fontWeight: typography.fontWeightSemiBold,
                fontSize: rem(12),
            },
        },
        box: {
            check: {
                'backgroundColor': palette.status.accepted,
                'color': palette.common.white,
                'height': rem(20),
                'minWidth': rem(20),
                'display': 'flex',
                'justifyContent': 'center',
                'alignItems': 'center',
                'borderRadius': 50,
            },
        },
        divider: {
            borderColor: palette.clouds,
            height: 'auto',
        },
    };

    return { css };
};

import { useState } from 'react';
import { client } from '@api';
import { DEFAULTS } from './DEFAULTS';
import {
    LoadMovementHistoryProps,
    RequestWithdrawalProps,
    LoadExpireMovementsProps,
    PixProps,
    BankAccountProps,
} from '@interfaces/Services';


export const userWallet = () => {
    const { services } = client();

    const [walletValue, setWalletValue] =
        useState(DEFAULTS.USER.WALLET.WALLET_VALUE);
    const [movementHistory, setMovementHistory] =
        useState(DEFAULTS.USER.WALLET.MOVEMENT_HISTORY);
    const [movementExpire, setMovementExpire] =
        useState({ ...DEFAULTS.USER.WALLET.MOVEMENT_EXPIRE, isLoaded: true });

    const [pixWithdrawal, setPixWithdrawal] =
        useState({} as PixProps);
    const [bankWithdrawal, setBankWithdrawal] =
        useState({} as BankAccountProps);

    const loadCreditValue = async () => {
        setWalletValue((prev) => prev = {
            ...prev,
            isLoaded: false,
        });
        const response = await services.user.wallet.loadValues();
        setWalletValue((prev) => prev = {
            message: response?.response?.message,
            isLoaded: true,
            error: !!response?.error,
            status: response?.response?.status,
            data: response?.response?.payload ?
                response?.response.payload : {
                    money: 0,
                    availableBalance: 0,
                    pointsToRelease: 0,
                },
        });
    };

    const loadMovementHistory = async ({ period, currency, type }:
        LoadMovementHistoryProps) => {
        setMovementHistory((prev) => prev = {
            ...prev,
            isLoaded: false,
        });
        const response = await services.user.wallet.loadMovementHistory({
            period,
            currency,
            type,
        });
        setMovementHistory((prev) => prev = {
            message: response?.response?.message,
            isLoaded: true,
            error: !!response?.error,
            status: response?.response?.status,
            data: response?.response?.payload ?
                response?.response.payload : [],
        });
    };

    const loadMovementExpire = async ({ month }: LoadExpireMovementsProps) => {
        setMovementExpire((prev) => prev = {
            ...prev,
            isLoaded: false,
        });
        const response = await services.user.extract.loadExpireMovements({
            month,
        });
        setMovementExpire((prev) => prev = {
            message: response?.response?.message,
            isLoaded: true,
            error: !!response?.error,
            status: response?.response?.status,
            data: response?.response?.payload ?
                response?.response.payload : [],
        });
    };

    const requestWithdrawal = async (data: RequestWithdrawalProps) => {
        const dataFormated = data.whereToReturn === 'BANK_ACCOUNT' ? {
            ...data,
            ...bankWithdrawal,
        } : {
            ...data,
            ...pixWithdrawal,
        };
        return await services.user.wallet.requestWithdrawal(dataFormated);
    };

    const handlePixWithdrawal = (data: PixProps) => {
        setPixWithdrawal(data);
    };

    const handleBankWithdrawal = (data: BankAccountProps) => {
        setBankWithdrawal(data);
    };

    return {
        walletValue: {
            list: walletValue,
            load: loadCreditValue,
        },
        movementHistory: {
            list: movementHistory,
            load: loadMovementHistory,
        },
        movementExpire: {
            list: movementExpire,
            load: loadMovementExpire,
        },
        withdrawal: {
            pix: {
                handle: handlePixWithdrawal,
                list: pixWithdrawal,
            },
            bank: {
                handle: handleBankWithdrawal,
                list: bankWithdrawal,
            },
            submit: requestWithdrawal,
        },
    };
};

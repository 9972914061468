import * as Page from '@pages';
import { defaultRouteLayout, simpleHeaderMobile } from '@static/noFooterPaths';
import { routes } from '@static/routes.list';

export const stackRoutes = [
    {
        path: '/',
        index: true,
        element: <Page.Home />,
    },
    {
        path: '/ui',
        element: <Page.DesignSystem />,
    },
    {
        path: '/resultados/parceiro/:partner',
        element: <Page.Results.Partner />,
    },
    {
        path: routes.common.results.search + '/:key?',
        element: <Page.Results.Search />,
    },
    {
        path: '/produto/:id',
        element: <Page.ProductView />,
    },
];

export const stackAuthRoutesMobile = [
    {
        path: '/auth/login',
        element: <Page.Auth.Login />,
    },
    {
        path: '/auth/criar-conta',
        element: <Page.Auth.CreateAccount.Form />,
    },
    {
        path: routes.auth.createAccount.success,
        element: <Page.Auth.CreateAccount.SuccessFeedback />,
    },
    {
        path: '/auth/criar-conta/enviar-codigo',
        element: <Page.Auth.SendConfirmationCode />,
    },
    {
        path: '/auth/criar-conta/validar-conta/:method',
        element: <Page.Auth.TypeConfirmationCode />,
    },
    {
        path: '/auth/recuperar-senha',
        element: <Page.Auth.RecoveryPassword.Form />,
    },
    {
        path: '/auth/recuperar-senha/sucesso',
        element: <Page.Auth.RecoveryPassword.SuccessFeedback />,
    },
    {
        path: '/auth/recuperar-senha/nova-senha',
        element: <Page.Auth.RecoveryPassword.RecoveryForm />,
    },
    {
        path: '/auth/recuperar-senha/nova-senha/sucesso',
        element: <Page.Auth.RecoveryPassword.RecoverySuccessFeedback />,
    },
];

export const stackRoutesSimpleHeaderMobile = [
    {
        path: simpleHeaderMobile.userMenu,
        element: <Page.Mobile.Menu.UserMenu />,
    },
    {
        path: '/autenticando',
        element: <Page.Common.AuthenticatedLoad />,
    },
    {
        path: simpleHeaderMobile.unloggedMenu,
        element: <Page.Mobile.Menu.LoggedOut />,
    },
    {
        path: '/central-de-ajuda',
        element: <Page.HelperCenter.Center />,
    },
    {
        path: '/central-de-ajuda/:topic',
        element: <Page.HelperCenter.Questions />,
    },
    {
        path: '/central-de-ajuda/resultados',
        element: <Page.HelperCenter.Questions />,
    },
    {
        path: '/central-de-ajuda/:topic/:questionId',
        element: <Page.HelperCenter.Answer />,
    },
];

export const stackRoutesCentralizedHeaderMobile = [
    {
        path: '/escolher',
        element: <Page.Mobile.ViewOptions />,
    },
    {
        path: '/menu/parceiros',
        element: <Page.Mobile.Menu.Partners />,
    },
    {
        path: '/menu/categorias',
        element: <Page.Mobile.Menu.Categories />,
    },
    {
        path: '/menu/categorias-parceiro',
        element: <Page.Mobile.Menu.PartnerCategories />,
    },
];

export const stackRoutesAuthenticatedLayoutSideMenu = [
    {
        path: '/minha-conta',
        element: <Page.Authenticated.Profile.View />,
    },
    {
        path: '/minha-conta/editar',
        element: <Page.Authenticated.Profile.Edit.EditProfile />,
    },
    {
        path: '/minha-conta/alterar-senha',
        element: <Page.Authenticated.Profile.Edit.EditPassword />,
    },
    {
        path: '/meu-extrato',
        element: <Page.Authenticated.Extract />,
    },
    {
        path: '/meus-creditos',
        element: <Page.Authenticated.Credits />,
    },
    {
        path: '/meus-pedidos',
        element: <Page.Authenticated.Orders.List />,
    },
    {
        path: '/meus-pedidos/detalhes/:id',
        element: <Page.Authenticated.Orders.Details />,
    },
    {
        path: '/meus-pedidos/detalhes-devolucao/:id',
        element: <Page.Authenticated.Orders.DetailsDevolution />,
    },
    {
        path: '/meus-enderecos',
        element: <Page.Authenticated.Address.List />,
    },
    {
        path: '/meus-enderecos/:type/:id?',
        element: <Page.Authenticated.Address.Edit />,
    },
    {
        path: '/formas-de-pagamento',
        element: <Page.Authenticated.PaymentMethod.List />,
    },
    {
        path: '/formas-de-pagamento/:typeAction',
        element: <Page.Authenticated.PaymentMethod.Edit />,
    },
];

export const stackAuthenticatedRoutes = [
    {
        path: defaultRouteLayout.cart,
        element: <Page.Authenticated.Shopping.Cart />,
    },
    {
        path: '/checkout/endereco',
        element: <Page.Authenticated.Shopping.Address.List />,
    },
    {
        path: '/checkout/endereco/editar/:id',
        element: <Page.Authenticated.Shopping.Address.Edit />,
    },
    {
        path: '/checkout/endereco/adicionar',
        element: <Page.Authenticated.Shopping.Address.Add />,
    },
    {
        path: '/checkout/pagamento',
        element: <Page.Authenticated.Shopping.Checkout />,
    },
    {
        path: '/favoritos',
        element: <Page.Authenticated.Favorites />,
    },
    {
        path: defaultRouteLayout.checkoutSuccess,
        element: <Page.Feedback.CheckoutSuccess />,
    },
];

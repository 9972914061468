/* eslint-disable max-len */
import React from 'react';
import { Feedback, HeaderPage, Price, WithdrawalButton } from '@components';
import { AlertColor, Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { BankAccountForm } from './forms/bank-account-form';
import { PixForm } from './forms/pix-form';
import { WithdrawalRequestProps } from '@interfaces/index';
import { WithdrawalType } from '@types';
import { useUser } from '@hooks/user';
import { WithdrawalTypeList } from './components/withdrawal-type-list';

const handleWithdrawalType = {
    PIX: {
        title: 'Dados Pix',
        form: <PixForm />,
    },
    BANK_ACCOUNT: {
        title: 'Dados da conta bancária',
        form: <BankAccountForm />,
    },
};

export const WithdrawalRequest: React.FC<WithdrawalRequestProps> = ({
    handleClose,
}: WithdrawalRequestProps) => {
    const [withdrawalType, setWithdrawalType] = useState<WithdrawalType>();
    const { wallet } = useUser();

    const [openSnack, setOpenSnack] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        isLoaded: false,
        type: 'success' as AlertColor,
        message: '',
    });

    const submitRequest = async () => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoaded: true,
        });

        const response = await wallet.withdrawal.submit({
            whereToReturn: withdrawalType ?? 'PIX',
            value: wallet.walletValue.list.data.money,
        });

        setResponseData({
            isLoaded: false,
            type: response?.response?.type,
            message: response?.response.message,
        });
        setOpenSnack(true);
        if (!response?.error) {
            setTimeout(async () => {
                handleClose();
            }, 500);
        };
    };

    return (
        <Box>
            <HeaderPage
                onClick={!withdrawalType ? handleClose :
                    () => setWithdrawalType(undefined)}
                size='medium'
                noPadding
                icon={['far', 'chevron-left']}
                titleAlign={'center'}
                title={withdrawalType ?
                    handleWithdrawalType[withdrawalType].title :
                    'Meus créditos'} />
            {!withdrawalType ?
                <Box py={4} px={2}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: 700,
                        }}
                        variant='h3'>
                            Por onde realizar o saque?
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '14px',
                        }}
                        variant='body1'>
                            Escolha por onde deseja receber o dinheiro de sua carteira
                    </Typography>
                    <Stack
                        pt={3}
                        spacing={1}
                        direction={'row'}>
                        <WithdrawalButton
                            icon={['fab', 'pix']}
                            title={'Pix'}
                            subtitle={'Saque via pix'}
                            onClick={() => setWithdrawalType('PIX')} />
                        <WithdrawalButton
                            icon={['far', 'building-columns']}
                            title={'Conta bancária'}
                            subtitle={'Saque em conta'}
                            onClick={() => setWithdrawalType('BANK_ACCOUNT')} />
                    </Stack>
                </Box> :
                <>
                    <Stack
                        sx={{
                            backgroundColor: '#FAFAFB',
                        }}
                        py={'20px'}
                        px={2}>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                            }}
                            variant={'h4'}>
                            Valor disponível
                        </Typography>
                        <Price
                            size='big'
                            type={'SHOPPING'}
                            value={wallet.walletValue.list.data.money} />
                    </Stack>
                    {
                        (Object.keys(wallet.withdrawal.bank.list).length > 0 && withdrawalType === 'BANK_ACCOUNT') ||
                        (Object.keys(wallet.withdrawal.pix.list).length > 0 && withdrawalType === 'PIX') ?
                            (
                                <Stack m={2} spacing={2}>
                                    <WithdrawalTypeList type={withdrawalType} />
                                    <Button
                                        onClick={() => submitRequest()}
                                        disabled={responseData.isLoaded}
                                        size='large'
                                        variant='contained'>
                                    Solicitar transferência {responseData.isLoaded &&
                                    <CircularProgress
                                        color="inherit"
                                        size={14}
                                        sx={{ ml: 1 }}
                                    />}
                                    </Button>
                                </Stack>
                            ) : handleWithdrawalType[withdrawalType].form
                    }
                    <Feedback.MuiSnackbar
                        open={openSnack}
                        severity={responseData.type}
                        onClose={() => setOpenSnack(false)}
                        message={responseData.message}
                    />
                </>
            }
        </Box>
    );
};

import styled from 'styled-components';
import Box from '@mui/material/Box';
import { rem } from '@utils';

export const Form = styled.form`
    display: flex;
    width: 100%;
    @media (max-width: 900px) {
        height: 2.75rem;
    }
`;

export const Input = styled('input')(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: rem(14),
    fontWeight: theme.typography.fontWeightSemiBold,
    width: '100%',
    border: 'none',
    outline: 'none',
}));

export const Button = styled('button')(({ theme }) => ({
    backgroundColor: theme.palette.primaryColor,
    color: theme.palette.common.white,
    border: 'none',
    borderRadius: `0 ${rem(4)} ${rem(4)} 0`,
    padding: rem(13),
    fontSize: rem(18),
    cursor: 'pointer',
}));

export const BoxStyled = styled(Box)(({ theme }) => ({
    width: '100%',
    backgroundColor: `${theme.palette.common.white}`,
    border: `${rem(1)} solid ${theme.palette.clouds}`,
    borderRight: 'none',
    borderRadius: `${rem(4)} 0 0 ${rem(4)}`,
    padding: `${rem(10)} ${rem(20)}`,
}));

export const DEFAULTS = {
    loading: {
        form: false,
        address: false,
    },
    API_OBJ: {
        error: false,
        message: '',
        field: '',
    },
    TEXTS: {
        adicionar: {
            title: 'Adicione seu endereço',
            sub: 'Preencha os campos abaixo com os dados do endereço.',
        },
        editar: {
            title: 'Editar endereço',
            sub: 'Preencha os campos abaixo com os dados do endereço.',
        },
    },
};

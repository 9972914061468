/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Header } from '../../header/dekstop/default';
import { MobileHeader } from '../../header/mobile/default';
import { Footer } from '../..';
import { Box } from '@mui/material';
import { useUser } from '@hooks/user';
import { skeleton } from './skeleton';
import { defaultRouteLayout } from '@static/noFooterPaths';
import { useGlobal } from '@hooks/global';
import { useLayout } from '@hooks/layout';
import { useAds } from '@hooks/ads';
import { styles } from '@utils';
import { sx } from './sx';

const noFooterPaths = [
    defaultRouteLayout.checkoutSuccess,
    defaultRouteLayout.cart,
];

export function RouterLayout() {
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();

    const skel = skeleton();
    const user = useUser();
    const global = useGlobal();
    const { layout } = useLayout();

    const userLoad = async () => {
        const cookieSession = await global.app.cookies.get('session');
        await user.load({ jwt: cookieSession.data.jwt });
    };

    const layoutLoad = async () => {
        if (!layout.banners.carrousel.list.isLoaded) {
            await layout.banners.carrousel.load();
        }
    };

    // console.log('RETURN COOKIE: ', cookieSession);

    React.useEffect(() => {
        layoutLoad();
        if (!user.info.isLoaded) {
            userLoad();
        }
    }, []);

    return (
        <div className="h-full">
            {mediaQuery.md && !user.info.isLoaded && skel.headerDefault}
            {!mediaQuery.md && !user.info.isLoaded && skel.headerMobile}

            {mediaQuery.md && (
                user.info.isLoaded &&
                <Header />
            )}
            {!mediaQuery.md && (
                user.info.isLoaded &&
                <MobileHeader user={user.info} />
            )}
            <Box sx={
                !mediaQuery.md ?
                    css.heightSize.mobile :
                    css.heightSize.desktop
            }>
                <Outlet />
            </Box>
            <ScrollRestoration />
            {mediaQuery.md ?
                <Footer.Default /> :
                !(noFooterPaths.find((item) => item === window.location.pathname)) &&
                <Footer.Mobile />
            }
        </div>
    );
}

export default RouterLayout;

import { Template } from '@components';
import { Box, Container } from '@mui/material';
import { sx } from './sx';
import { useNavigate } from 'react-router-dom';

const SuccessFeedback: React.FC = () => {
    const { css } = sx();
    const navigate = useNavigate();

    return (
        <Box sx={css.wrapper}>
            <Container maxWidth='xs'>
                <Template.AuthFeedback
                    title='Inscrição encontrada'
                    subtitle={`E-mail de recuperação de senha enviado. \n
                    Acesse sua caixa de entrada para concluir a
                    redefinição de senha.`}
                    button={{
                        text: 'Voltar para o login',
                        onClick: () =>navigate('/auth/login'),
                    }}
                />
            </Container>
        </Box>
    );
};

export { SuccessFeedback };

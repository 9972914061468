import {
    Grid,
    FormControl,
    Box,
    Button,
    TextField,
    Autocomplete,
} from '@mui/material';
import React from 'react';

import $ from 'jquery';
import 'jquery-mask-plugin';

import { SubmitHandler, useForm } from 'react-hook-form';
import { PixProps } from '@interfaces/Services';
import { useUser } from '@hooks/user';
import { FormControlLabel } from '../styles';
import { KeyPixType } from '@types';

const typesPixKey = [
    {
        _id: 'CELULAR',
        label: 'Celular',
    },
    {
        _id: 'CPF',
        label: 'CPF',
    },
    {
        _id: 'CHAVE_ALEATORIA',
        label: 'Chave aleatoria',
    },
];

const validationKey = {
    CELULAR: {
        min: 19,
        max: 19,
        message: 'Insira um celular válido',
    },
    CPF: {
        min: 14,
        max: 14,
        message: 'Insira um CPF válido',
    },
    CHAVE_ALEATORIA: {
        min: 1,
        max: 1000,
        message: '',
    },
};

const placeholders = {
    CELULAR: '+00 (00) 00000-0000',
    CPF: '000.000.000-00',
    CHAVE_ALEATORIA: '',
};

const PixForm: React.FC = () => {
    const { wallet } = useUser();

    const [typesPixList] = React.useState(typesPixKey);
    const [typeKeySelected, setTypeKeySelected] = React.useState<KeyPixType>(
        'CHAVE_ALEATORIA');

    const { register, handleSubmit, formState: { errors }, resetField, reset } =
        useForm<PixProps>({
            mode: 'onSubmit',
        });

    const onSubmit: SubmitHandler<PixProps> = async (data) => {
        wallet.withdrawal.pix.handle(data);
        setTypeKeySelected('CHAVE_ALEATORIA');
        reset();
    };

    const inputMasks = () => {
        $('.CELULAR').mask('+00 (00) 00000-0000');
        $('.CPF').mask('000.000.000-00');
        $('.CHAVE_ALEATORIA').unmask();
    };

    React.useEffect(() => {
        inputMasks();
        resetField('key');
    }, [typeKeySelected]);

    return (
        <Box sx={{ m: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12} mb={3}>
                    <Box position='relative'>
                        <FormControlLabel htmlFor={'key-type'} >
                                Tipo de chave
                        </FormControlLabel>
                        <FormControl
                            fullWidth
                            error={!!errors.keyType}>
                            <Autocomplete
                                disablePortal
                                fullWidth
                                id="key-type"
                                options={typesPixList}
                                onInputChange={(_, newPixType) =>
                                    setTypeKeySelected(newPixType
                                        .toUpperCase()
                                        .replace(' ', '_') as KeyPixType)}
                                renderInput={(params) => <TextField
                                    {...params}
                                    helperText={errors.keyType ?
                                        errors.keyType.message :
                                        ''}
                                    error={!!errors.keyType}
                                    {...register('keyType',
                                        {
                                            required: true,
                                        },
                                    )}
                                />}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} mb={1}>
                    <TextField
                        focused
                        fullWidth
                        label={'Chave'}
                        placeholder={placeholders[typeKeySelected]}
                        inputProps={{
                            className: typeKeySelected,
                        }}
                        variant="outlined"
                        helperText={errors.key ?
                            errors.key.message :
                            ''}
                        error={!!errors.key}
                        {...register('key',
                            {
                                required: true,
                                minLength: {
                                    value: validationKey[typeKeySelected].min,
                                    message:
                                            `Informe uma chave válida de acordo com o tipo
                                            ${typeKeySelected}`,
                                },
                                maxLength: validationKey[typeKeySelected].max,
                            },
                        )} />
                </Grid>
                <Box
                    display='flex'
                    justifyContent='center'
                    mt={3}>
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        type='submit'
                        className='MuiButton primary'
                    >
                                Salvar
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export { PixForm };

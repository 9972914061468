import { client } from '@api';
import {
    CardInfosProps,
    CreatePaymentFormProps,
    DeletePaymentFormProps,
    UpdatePaymentFormProps,
} from '@interfaces/Services';
import { useState } from 'react';
import { DEFAULTS } from './DEFAULTS';

export const userPaymentForm = () => {
    const { services } = client();
    const [paymentsList, setPaymentsList] =
        useState(DEFAULTS.USER.PAYMENT_FORM.LIST);
    const [currentData, setCurrentData] =
        useState(DEFAULTS.USER.PAYMENT_FORM.CURRENT_DATA);

    const load = async () => {
        const response = await services.user.paymentForm.load();
        setPaymentsList((prev) => prev = {
            message: response?.response?.message,
            isLoaded: !!(!response?.error),
            status: response?.response?.status,
            data: response?.response?.payload ?
                response?.response.payload :
                [],
        });
        return response;
    };

    const create = async (data: CreatePaymentFormProps) => {
        const response = await services.user.paymentForm.create(data);
        return response;
    };

    const update = async (data: UpdatePaymentFormProps) => {
        const response = await services.user.paymentForm.update(data);
        return response;
    };

    const deletePaymentForm = async (data: DeletePaymentFormProps) => {
        const response = await services.user.paymentForm.deletePaymentForm(data);
        return response;
    };


    const handleCurrentData = (data: CardInfosProps) => {
        setCurrentData((prev) => prev = {
            ...prev,
            ...data,
        });
    };

    const clearCurrentData = () => {
        setCurrentData(DEFAULTS.USER.PAYMENT_FORM.CURRENT_DATA);
    };

    return {
        load,
        create,
        delete: deletePaymentForm,
        update,
        list: paymentsList,
        current: {
            handle: handleCurrentData,
            data: currentData,
            clear: clearCurrentData,
        },
    };
};

import { rem } from '@utils';

export const sx = () => {
    const css = {
        sxWrapper: {
            position: 'relative',
            display: 'flex',
            p: rem(15),
            pr: 0,
        },
        sxLoadindBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: rem(68),
        },
        sxLink: {
            position: 'absolute' as any,
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
        },
        sxBoxChildren: {
            width: '100%',
        },
        sxBoxActions: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: rem(72),
        },
    };

    return { css };
};

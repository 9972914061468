import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxBox: (paddingBottom: boolean) => {
            return {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: paddingBottom ?
                    '10px 0' :
                    '10px 0 0 0',
            };
        },
        sxTitle: {
            fontWeight: typography.fontWeightBold,
            color: palette.darkGray,
        },
        sxLegend: {
            paddingTop: '0.3125rem',
            fontWeight: typography.fontWeightMedium,
            color: palette.dark,
        },
    };
    return {
        ...css,
    };
};

export const fontSizes = {
    tiny: {
        title: '0.75rem',
        subtitle: '0.625rem',
    },
    small: {
        title: '1rem',
        subtitle: '0.8125rem',
    },
    medium: {
        title: '1.125rem',
        subtitle: '.875rem',
    },
    big: {
        title: '1.75rem',
        subtitle: '1rem',
    },
};

import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        stack: {
            listValues: {
                px: rem(5),
                pb: mediaQuery.md ? rem(0) : rem(15),
            },
            paymentOptions: {
                px: rem(15),
                pt: mediaQuery.md ? rem(5) : rem(15),
                pb: rem(15),
            },
            installments: {
                pb: rem(30),
            },
        },
        icon: {
            squarePlus: {
                fontSize: rem(24),
                marginRight: rem(10),
                color: palette.customBlack,
            },
            paymentMenu: {
                fontSize: rem(18),
                color: palette.customBlack,
                marginRight: rem(10),
            },
        },
        select: {
            '& > .MuiSelect-select': {
                'padding': `${rem(10)} ${rem(20)}`,
                'fontSize': `${rem(14)}`,
            },
        },
        texts: {
            showMore: {
                'fontSize': '14px',
                'cursor': 'pointer',
                '&:hover': {
                    textDecoration: 'underline',
                },
            },
        },
    };

    return { css };
};

import axios from 'axios';
import React from 'react';
import config from '../../configs/config.json';
import { CustomThemeContext } from '../../contexts/custom-theme/context';

const DEFAULT = {
    isLoaded: false,
    colors: {
        primaryColor: '',
        secondaryColor: '',
    },
    title: '',
    logo: '',
};

export const customThemeProvider = () => {
    const [customTheme, setCustomTheme] = React.useState(DEFAULT);

    const loadTheme = async () => {
        try {
            setCustomTheme(((prev) => prev = {
                ...prev,
                isLoaded: false,
            }));
            const response = await axios.get(
                // eslint-disable-next-line max-len
                `${config.apis.url}/files/styles/${!!config.domainSplit ? window.location.host.split(config.domainSplit)[0] : 'commarket'}.json`);
            setCustomTheme({
                isLoaded: true,
                colors: {
                    primaryColor: response.data.colors.primaryColor,
                    secondaryColor: response.data.colors.secundaryColor,
                },
                title: response.data.title,
                logo: response.data.logo,
            });
        } catch (error) {
            setCustomTheme({
                ...DEFAULT,
                isLoaded: true,
            });
        }
    };

    return {
        customTheme: {
            load: loadTheme,
            data: customTheme,
        },
    };
};

export const useCustomTheme = () => {
    const context = React.useContext(CustomThemeContext);
    return context;
};

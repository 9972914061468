import { client } from '@api';
import React from 'react';
import { DEFAULTS } from './DEFAULTS';
import { HandleFavoriteActionProps } from '@interfaces/Services';
import { servicesUtils } from '@utils';
import { useGlobal } from '@hooks/global';

export const userFavorites = () => {
    const { services } = client();
    const global = useGlobal();
    const [listFavorites, setListFavorites] = React.useState(
        DEFAULTS.USER.FAVORITES.LIST,
    );

    const handle = async ({ ...data }: HandleFavoriteActionProps) => {
        return await services.user.favorites.handle({ ...data });
    };

    const load = async () => {
        setListFavorites(DEFAULTS.USER.FAVORITES.LIST);
        const session = await global.app.cookies.get('session');
        if (session.error) {
            setListFavorites((prev) => prev = {
                ...prev,
                status: servicesUtils.noValidToken.response.status,
                message: servicesUtils.noValidToken.response.message,
                isLoaded: false,
            });
            return servicesUtils.noValidToken;
        }
        const response = await services.user.favorites.load();
        setListFavorites((prev) => prev = {
            message: response?.response.message ?
                response?.response.message :
                '',
            isLoaded: !!(!response?.error),
            status: response?.response.status,
            data: response?.response.payload ?
                response?.response.payload : DEFAULTS.USER.FAVORITES.LIST.data.favorites,
        });
        return response;
    };

    return {
        favorites: {
            load,
            handle,
            list: listFavorites,
        },
    };
};

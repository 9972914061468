/* eslint-disable max-len */
/* eslint-disable indent */

import queryString from 'query-string';
import React from 'react';
import { DEFAULTS } from './DEFAULTS';
import {
    FiltersType,
    FiltersTypeProps,
    TempFiltersTypeProps,
} from '@types';
import {
    ExternalFiltersActionProps,
    HandleSearchQueryResponse,
    HandleURLQueryFilterResponse,
    RemoveFilterFromListResponse,
    ServiceSearchQueryProps,
    TempFilterProps,
} from '@interfaces/Filters';
import { FiltersContext } from '../../contexts/filters/context';
import { orderByFilterTranslate } from '@static/filters';
import { QuicklinksProps } from '@interfaces/Services';
import { client } from '@api';

export const filtersProviderHook = () => {
    const { services } = client();

    const [filters, setFilters] = React.useState<TempFiltersTypeProps>(
        DEFAULTS.FILTERS_LIST,
    );
    const [tempFilter, setTempFilter] = React.useState<TempFiltersTypeProps>(
        DEFAULTS.TEMP_FILTERS_LIST,
    );
    const [listActiveFilters, setListActiveFilters] = React.useState(
        DEFAULTS.LIST_ACTIVE_FILTERS,
    );

    const [quicklinks, setQuicklinks] = React.useState(
        DEFAULTS.QUICKLINKS.LIST,
    );

    const [showcases, setShowcases] = React.useState(
        DEFAULTS.SHOWCASES.LIST,
    );

    const [actionOnExternalComponent,
        setActionOnExternalComponent] = React.useState({
            valueRange: {
                state: false,
            },
            categoryList: {
                state: false,
            },
        });

    const externalComponentAction = (
        { action, state }: ExternalFiltersActionProps) => {
        setActionOnExternalComponent((prev) => prev = {
            ...prev,
            [action]: {
                state,
            },
        });
    };


    const onlyTrueListElems = (list: any[]) => {
        return list.filter((el) => !!el);
    };

    const handleFilters = async (
        filterType: FiltersType,
        data: TempFilterProps[],
    ) => {
        setFilters(
            (prev) =>
            (prev = {
                ...prev,
                [filterType]: data,
            }),
        );
    };

    const removeFilter = async (filterType: FiltersType) => {
        let response = {} as RemoveFilterFromListResponse;

        try {
            const object = { [filterType]: [] as any[] } as FiltersTypeProps;
            return response = {
                data: object,
                error: false,
                message: 'filters list',
            };
        } catch (error) {
            return response = {
                data: {} as FiltersTypeProps,
                error: false,
                message: 'filters list',
            };
        } finally {
            return response;
        }
    };

    const clearFilters = () => {
        setFilters(DEFAULTS.FILTERS_LIST);
        setTempFilter(DEFAULTS.TEMP_FILTERS_LIST);
    };

    const parseURLParamsToObjectList = async (
        filtersList: FiltersTypeProps,
    ): Promise<HandleURLQueryFilterResponse> => {
        let response = {} as HandleURLQueryFilterResponse;

        try {
            const list = Object.entries(filtersList).reduce((prev, [key, val]) => {
                const value = val.toString();
                const split = value.split(',');
                const map = split.map((item) => {
                    const [name, _id] = item.split('|');
                    if ((name && _id) && item.includes('|')) {
                        return { _id, name, filterType: key };
                    }
                    return item;
                });
                return { ...prev, [key]: map };
            }, {}) as TempFiltersTypeProps;

            return response = {
                message: 'Successfully converted URL parameters to list format',
                data: list,
                error: false,
            };
        } catch (error: any) {
            return response = {
                message: error.message ?? 'An error occurred while generating list',
                data: {} as TempFiltersTypeProps,
                error: false,
            };
        } finally {
            return response;
        }
    };

    const handleURLQuery = async (parseObject: FiltersTypeProps) => {
        if ((parseObject.pageNumber)) delete (parseObject as any).pageNumber;
        if ((parseObject.aleatory)) delete (parseObject as any).aleatory;
        if ((parseObject.limit)) delete (parseObject as any).limit;
        if ((parseObject.storeUrl)) delete (parseObject as any).storeUrl;

        const response = await parseURLParamsToObjectList(parseObject);
        if (!response.error) {
            setFilters(
                (prev) =>
                (prev = {
                    ...prev,
                    ...response.data,
                }),
            );
            setTempFilter(
                (prev) =>
                (prev = {
                    ...prev,
                    ...response.data,
                }),
            );
        }
        return response;
    };

    const serviceSearchQuery = async (
        { paramsList, queryStringParams }: ServiceSearchQueryProps,
    ): Promise<HandleSearchQueryResponse> => {
        let response = {} as HandleSearchQueryResponse;
        try {
            const parse = queryString.parse(queryStringParams, {
                arrayFormat: 'index',
            }) as FiltersTypeProps;
            const parseParams = { ...parse };
            if (parseParams.orderBy) delete (parseParams as any).aleatory;

            const objectList = await parseURLParamsToObjectList(parseParams);
            const parsedObjectList = { ...objectList.data };
            const tempParamsList = paramsList;
            if (tempParamsList && !tempParamsList.key) delete (tempParamsList as any).key;

            // Format valueRange
            if (parsedObjectList.valueRange &&
                parsedObjectList.valueRange.length > 0) {
                delete (parsedObjectList as any).valueRange;
                const valueRangeFormated = objectList.data.valueRange
                    .map((value) => value.name)
                    .join('-');
                Object.assign(parsedObjectList, {
                    valueRange: [valueRangeFormated],
                });
            }
            const formatParamsObject = Object.entries(parsedObjectList).
                reduce((prev, [key, val]) => {
                    const value = val as TempFilterProps[];
                    const map = value.map((item) => {
                        // if (item.filterType === 'valueRange') {
                        //     return item.name;
                        // }
                        if (item.filterType === 'orderBy') {
                            return item.name;
                        }
                        return item._id ? item._id : value;
                    }).join(',');
                    return prev + key + '=' + map + '&';
                }, '').split('&');

            // key, limit, aleatory
            const formatConstantParamsObject = Object.entries(tempParamsList ?? {})
                .reduce((prev, [key, val]) => {
                    return prev + key + '=' + val + '&';
                }, '').split('&');

            const { searchParams, apiQuery } = {
                searchParams: onlyTrueListElems(formatConstantParamsObject).join('&'),
                apiQuery: onlyTrueListElems(formatParamsObject).join('&'),
            };

            const queryParams = (apiQuery.length > 0) ?
                searchParams + '&' + apiQuery :
                searchParams;

            return response = {
                message: 'Generated query string parameter',
                data: queryParams,
                error: false,
            };
        } catch (error: any) {
            return response = {
                message: error.message ?? error.response.message,
                data: '',
                error: true,
            };
        } finally {
            return response;
        }
    };

    const generatingActiveFiltersList = (list: TempFilterProps[]) => {
        const orderByObjKeys = Object.keys(orderByFilterTranslate);
        const test = list.find((item) => orderByObjKeys.includes(item._id));

        if ((list.length > 0)) {
            return list.map((item) => {
                if (item.filterType === 'orderBy') {
                    return {
                        _id: item._id,
                        name: orderByFilterTranslate[test?._id as any],
                        filterType: item.filterType,
                    };
                }
                return {
                    _id: item._id,
                    name: item.name,
                    filterType: item.filterType,
                };
            });
        }
    };
    const onChangeFilters = async (filtersList: FiltersTypeProps) => {
        setListActiveFilters(DEFAULTS.LIST_ACTIVE_FILTERS);

        if ((Object.keys(filtersList).length > 0)) {
            const response = await parseURLParamsToObjectList(filtersList);
            const activeFilters = Object.entries(response.data).
                reduce((prev: any[], [key, value]) => {
                    const map = generatingActiveFiltersList(value);
                    if (['quickLinkId', 'showcaseId'].includes(key)) return [...prev];
                    return [...prev, { filterType: key, list: map }];
                }, []);

            if (activeFilters.length > 0) {
                setListActiveFilters((prev) => prev = {
                    isLoaded: true,
                    data: activeFilters,
                });
            }
        }
    };

    const loadQuicklinks = async ({ storeType }: QuicklinksProps) => {
        const response = await services.ads.quicklinks({ storeType });
        if (response) {
            setQuicklinks({
                isLoaded: true,
                message: response.response.message,
                status: response?.response.status,
                data: response.response?.payload ?
                    response.response?.payload :
                    DEFAULTS.QUICKLINKS.LIST.data,
            });
        }
        return response;
    };

    const loadShowcases = async ({ storeType }: QuicklinksProps) => {
        const response = await services.ads.showcases({ storeType });
        if (response) {
            setShowcases({
                isLoaded: true,
                message: response.response.message,
                status: response?.response.status,
                data: response.response?.payload ?
                    response.response?.payload :
                    DEFAULTS.SHOWCASES.LIST.data,
            });
        }
        return response;
    };

    return {
        handleFilters,
        handleURLQuery,
        parseURLParamsToObjectList,
        removeFilter,
        clearFilters,
        serviceSearchQuery,
        onChangeFilters,
        filters,
        tempFilter,
        listActiveFilters,
        load: {
            quicklinks: loadQuicklinks,
            showcases: loadShowcases,
        },
        list: {
            quicklinks,
            showcases,
        },
        externalActions: {
            status: actionOnExternalComponent,
            action: externalComponentAction,
        },
    };
};

export const useFilters = () => {
    const context = React.useContext(FiltersContext);
    return context;
};


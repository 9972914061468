import { UserAddress } from '@interfaces/Services';

export const DEFAULTS = {
    LOADING: {
        delete: false,
        update: false,
    },
    CURRENT_ADDRESS: {
        isLoaded: false,
        address: {} as UserAddress,
    },
};

import { rem, styles } from '@utils';
import { skeleton } from './skeleton';
import { Box, Container, Grid, Skeleton, Stack } from '@mui/material';
import { ProductSkeleton } from '@components';

export const LoadingTheme: React.FC = () => {
    const mediaQuery = styles.mediaQueryCSS();
    const skel = skeleton();
    return (
        <div className="h-full">
            {mediaQuery.md && skel.headerDefault}
            {!mediaQuery.md && skel.headerMobile}

            <Container>
                {mediaQuery.md &&
                    <Box pt={rem(15)}>
                        <Skeleton
                            variant='rectangular' height={ mediaQuery.md ? 490 : 190} />
                    </Box>
                }
                <Stack
                    my={4}
                    spacing={6}
                    justifyContent={'center'}
                    direction={'row'}>
                    {Array.from(Array(3).keys()).map((_, i) => (
                        <Skeleton
                            key={'loading-sk-link-' + i}
                            variant='circular'
                            height={ mediaQuery.md ? 84 : 64}
                            width={ mediaQuery.md ? 84 : 64} />

                    ))}
                </Stack>
                <Grid container spacing={2}>
                    {Array.from(Array(mediaQuery.md ? 4 : 1).keys()).map((_, i) => (
                        <Grid
                            key={'loading-sk-showcase-' + i}
                            item md={6} sm={12} xs={12}>
                            <Skeleton
                                variant='rectangular'
                                height={ mediaQuery.md ? 280 : 187} />
                        </Grid>
                    ))}
                </Grid>
                <Grid container spacing={2}>
                    {Array.from(Array(8).keys()).map((_, i) => (
                        <Grid key={'loading-sk-ad-' + i} item md={3} sm={4} xs={6}>
                            <ProductSkeleton />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};

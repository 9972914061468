import {
    LoadMovementHistoryProps,
    RequestWithdrawalCallProps,
} from '@interfaces/Services';
import { client } from '@api';

export const loadValues = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/wallet');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const requestWithdrawal = async (data: RequestWithdrawalCallProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/wallet/requestWithdrawal', data);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const loadMovementHistory = async ({ period, currency, type }:
LoadMovementHistoryProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        // eslint-disable-next-line max-len
        const response = await instance.get(`/wallet/movementHistory/period/${period}?${currency ? '&currency='+currency : ''}${type ? '&type='+type : ''}`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

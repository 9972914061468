import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Box, Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import { rem, styles } from '@utils';

interface SubMenuButtonProps {
    icon: IconProp;
    title: string;
    showDropDownIcon?: boolean;
    showVerticalDivider?: boolean;
    onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
    onClick?: () => void;
}

const SubMenuButton: React.FC<SubMenuButtonProps> = (
    props: SubMenuButtonProps,
) => {
    const mediaQuery = styles.mediaQueryCSS();
    const { typography, palette } = useTheme();
    const { showDropDownIcon, showVerticalDivider, ...rest } = props;

    return (
        <>
            <Button
                variant="text"
                {...rest}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
                sx={{ height: '100%' }}>
                <Box pt={rem(8.5)} pb={rem(8.5)}>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <I
                            icon={props.icon}
                            color={palette.primaryColor}
                            fontSize={rem(14)}
                        />
                        <Typography
                            component='h5'
                            fontSize={!mediaQuery.sm ? rem(12) : rem(14)}
                            textTransform='capitalize'
                            color='darkGray'
                            fontWeight={
                                typography.fontWeightSemiBold
                            }>
                            {props.title}
                        </Typography>
                        {showDropDownIcon && (
                            <I
                                icon={['fas', 'chevron-down']}
                                color={palette.primaryColor}
                                fontSize={rem(11)}
                            />
                        )}
                    </Stack>
                </Box>
            </Button>
            {showVerticalDivider && (
                <Divider orientation="vertical" flexItem />
            )}
        </>

    );
};

export { SubMenuButton };

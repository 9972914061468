import React from 'react';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { sx } from './sx';
import { Badge, useTheme } from '@mui/material';
import { ButtonIconProps } from '@interfaces';

const ButtonIcon: React.FC<ButtonIconProps> = ({
    size,
    icon,
    onClick,
    active,
    disabled,
    boxShadow,
    badge,
    customColor,
    showBackgroundColor,
}: ButtonIconProps) => {
    const { shadows, palette } = useTheme();
    const { sxIcon, sxIconButton, sxBadge } = sx();
    const handleIcon = [active ? 'fas' : 'far', icon];

    return (
        <IconButton
            onClick={disabled ? () => { } : onClick}
            sx={{
                'boxShadow': boxShadow ? shadows[2] : 'none',
                ...sxIconButton.default,
                ...sxIconButton[size],
                'opacity': disabled ? 0.5 : 1,
                'cursor': disabled ? 'default' : 'pointer',
                '& > .MuiBadge-badge': {

                },
                ...{
                    backgroundColor: showBackgroundColor ?
                        palette.snowWhite :
                        'transparent',
                },
            }}>
            <Badge
                badgeContent={badge}
                sx={{ ...sxBadge[size] }}
                color="primary">
                <I
                    style={{
                        ...sxIcon.default,
                        ...sxIcon[size],
                        color: customColor ?? palette.darkGray,
                    }}
                    icon={handleIcon as any} />
            </Badge>
        </IconButton>
    );
};

export { ButtonIcon };

import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = (active?: boolean, actual?: boolean) => {
    const { palette, typography } = useTheme();
    const css = {
        box: {
            items: {
                display: 'flex',
                alignItems: 'center',
            },
            texts: {
                ml: rem(10),
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            wrapper: {
                content: {
                    px: rem(15),
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                },
                timeline: {
                    'backgroundColor': palette.lightBg,
                    'padding': rem(15),
                    '& .wallet-extract-item:last-child > .divider': {
                        display: 'none',
                    },
                },
            },
            icon: {
                backgroundColor: active ? palette.primaryColor : '#E0E0E0',
                minWidth: rem(40),
                height: rem(40),
                borderRadius: rem(100),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            dividerV: {
                height: rem(30),
                width: rem(2),
                marginLeft: rem(18),
                backgroundColor: active ? palette.primaryColor : '#E0E0E0',
            },
        },
        texts: {
            title: {
                fontSize: rem(14),
                fontWeight: actual ?
                    typography.fontWeightBold : typography.fontWeightRegular,
                color: (active || actual) ? palette.customBlack : '#9E9E9E',
            },
            date: {
                fontSize: rem(13),
                fontWeight: typography.fontWeightBold,
                color: palette.customBlack,
            },
            time: {
                fontSize: rem(12),
                fontWeight: typography.fontWeightMedium,
                color: '#6A6F7A',
            },
        },
        icon: {
            fontSize: rem(18),
            color: active ? palette.common.white : '#9E9E9E',
        },
    };

    return { css };
};

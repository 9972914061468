/* eslint-disable camelcase */
import { client } from '@api';
import {
    CategorizationProps,
    ListCategorizationProps,
    ResetCategorizationDataProps,
} from '@interfaces/Services';
import React from 'react';
import { DEFAULTS } from './DEFAULTS';

export const useCategories = () => {
    const [listHistoryCategory,
        setListHistoryCategory] = React.useState<ListCategorizationProps[]>([]);
    const [listCategories,
        setListCategories] = React.useState(DEFAULTS.CATEGORIES.LIST);

    const listCategorization = async ({ _id, direction }: CategorizationProps) => {
        const { services } = client();
        const response = await services.ads.categorization({ _id, direction });
        return response;
    };

    const handleCategorization = async ({
        _id,
        direction,
        lastLevel,
        name,
        parentCategory,
    }: ListCategorizationProps) => {
        if (direction === 'FORWARD' && !(parentCategory)) {
            setListHistoryCategory((prev) => prev = [{
                name: name,
                direction: direction,
                _id: _id,
                parentCategory: parentCategory,
            }]);
        } else if (direction === 'FORWARD' && parentCategory && !lastLevel) {
            const filter = listHistoryCategory.filter((cat) => cat._id === _id).length;
            if (!(!!filter)) {
                await historyCategorization({
                    name: name,
                    _id: _id,
                    parentCategory: parentCategory,
                    direction: 'FORWARD',
                });
            }
        }

        if (!lastLevel) {
            const response = await listCategorization({ _id, direction });
            setListCategories((prev) => prev = {
                message: response?.response.message,
                isLoaded: !!(!response?.error),
                status: response?.response.status,
                data: response?.response.payload ?
                    response?.response.payload :
                    [],
            });
            // if (!response?.error) {
            //     setListCategories((prev) => prev = response?.response.payload);
            // }
        }
    };

    const historyCategorization = async ({
        _id,
        parentCategory,
        direction,
        name,
    }: ListCategorizationProps) => {
        if (direction === 'FORWARD') {
            setListHistoryCategory((prev) => prev = [...prev, {
                name: name,
                direction: direction,
                _id: _id,
                parentCategory: parentCategory,
            }]);
        }

        if (direction === 'BACK') {
            const idx = listHistoryCategory.
                findIndex((el) => el.parentCategory === parentCategory);

            if (idx !== -1) {
                const splice = listHistoryCategory.splice(0, idx + 1);
                setListHistoryCategory((prev) => prev = [...splice]);

                if (direction === 'BACK' &&
                    (splice.length === 1)) {
                    await handleCategorization({});
                    setListHistoryCategory([]);
                } else {
                    await handleCategorization({
                        _id: _id,
                        direction: 'BACK',
                    });
                    setListHistoryCategory((prev) => prev = [...splice]);
                }
            }
        }
    };

    const resetCategorizationData = ({ type }: ResetCategorizationDataProps) => {
        if (type === 'history') {
            setListHistoryCategory((prev) => prev = []);
        }

        if (type === 'categories') {
            setListCategories(DEFAULTS.CATEGORIES.LIST);
        }
    };

    return {
        listCategorization,
        handleCategorization,
        historyCategorization,
        reset: resetCategorizationData,
        list: {
            categories: listCategories,
            history: listHistoryCategory,
        },
    };
};

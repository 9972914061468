import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { typography, palette } = useTheme();
    const css = {
        box: {
            title: {
                mb: rem(30),
            },
            info: {
                p: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
        text: {
            title: {
                fontWeight: typography.fontWeightRegular,
                fontSize: rem(14),
            },
        },
        reason: {
            stack: {
                'p': 2,
                'borderRadius': rem(5),
                'cursor': 'pointer',
                'backgroundColor': '#F9FAFE',
                '& > .box-check': {
                    'backgroundColor': '#F1F1F1',
                    'color': palette.grey[500],
                    'height': rem(20),
                    'width': rem(20),
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'borderRadius': 50,
                },
                '& > .text': {
                    'fontSize': rem(14),
                    'color': palette.darkGray,
                    'fontWeight': typography.fontWeightRegular,
                },
                '&.active': {
                    'backgroundColor': '#ECF0F1',

                    '& > .box-check': {
                        'backgroundColor': palette.status.accepted,
                        'color': palette.common.white,
                    },

                    '& > .text': {
                        'fontWeight': typography.fontWeightSemiBold,
                    },
                },
            },

        },
    };

    return { css };
};

import { useEffect, useState } from 'react';
import { Box, Checkbox, Divider, Stack, Typography, useTheme } from '@mui/material';
import { ProductItem } from '../../../components/product-item';
import { useUser } from '@hooks/user';
import { OrderProps } from '@interfaces/Services';
import { ItemDevolutionProps } from '@interfaces/index';
import { files } from '@utils';
import { sx } from './sx';
import { ControllQuantity } from '@components';

interface Step1Props {
    order: OrderProps;
}

const Step1 = ({ order }: Step1Props) => {
    const { css } = sx();
    const { palette } = useTheme();
    const { purchase } = useUser();

    const [items, setItems] = useState([] as ItemDevolutionProps[]);

    const handleItems = (newItem: ItemDevolutionProps) => {
        const findItem =
            items.find((item) => item.variationItemId === newItem.variationItemId);

        if (!!findItem) {
            const newResult =
                items.filter((item) => item.variationItemId !== newItem.variationItemId);

            setItems(newResult);
        } else {
            setItems((prev) => prev = [
                ...prev,
                newItem,
            ]);
        }
    };

    const handleQuantity = (variationItemId: string, action: 'add' | 'remove') => {
        const findItem =
            items.find((item) => item.variationItemId === variationItemId) ??
            {} as ItemDevolutionProps;

        if (action === 'add') {
            findItem.quantity++;
        } else {
            findItem.quantity--;
        }

        setItems([
            ...items.filter((item) => item.variationItemId !== variationItemId),
            ...[findItem],
        ]);
    };

    useEffect(() => {
        purchase.devolution.handle.items(items);
    }, [items]);

    useEffect(() => {
        purchase.devolution.handle.order({
            orderId: order._id,
        });
    }, []);

    return (
        <Box p={2}>
            <Box sx={{ ...css.box.title }}>
                <Typography
                    sx={{ ...css.text.title }}
                    variant='h3'>
                    Selecione quais produtos deseja devolver
                </Typography>
            </Box>
            <Stack spacing={1}>
                <Typography
                    sx={{ ...css.text.products }}
                    variant='h4'>
                    Produtos
                </Typography>
                <Stack
                    divider={
                        <Divider
                            orientation="horizontal"
                            sx={{ ...css.divider }} />
                    }
                    spacing={2}>
                    {order.items.map((itemAd, i) => (
                        <Box>
                            <Box
                                sx={{ ...css.box.productItem }}
                                key={`${itemAd._id}-${i}`}>
                                <ProductItem
                                    srcImage={files
                                        .load(itemAd?.variation?.images[0], 'server')}
                                    title={`${itemAd.ad.title}`} />
                                <Checkbox
                                    onClick={() => {
                                        handleItems({
                                            variationItemId: itemAd.variationItem._id,
                                            quantity: itemAd.quantity,
                                            src: itemAd?.variation?.images[0],
                                            title: itemAd.ad.title,
                                        });
                                    }}
                                    checked={!!(items
                                        .find((item) =>
                                            item.variationItemId ===
                                    itemAd.variationItem._id))}
                                    sx={{
                                        '&.Mui-checked': {
                                            color: `${palette.primaryColor} !important`,
                                        },
                                    }}
                                />
                            </Box>
                            {itemAd.quantity > 1 && items.find((item) =>
                                item.variationItemId === itemAd.variationItem._id) &&
                                <Box mt={0.5}>
                                    <ControllQuantity
                                        label
                                        value={items.find((item) =>
                                            item.variationItemId ===
                                                itemAd.variationItem._id)?.quantity ?? 0}
                                        limit={itemAd.quantity}
                                        remove={ () =>
                                            handleQuantity(itemAd.variationItem._id,
                                                'remove') }
                                        add={ () =>
                                            handleQuantity(itemAd.variationItem._id,
                                                'add') } />
                                </Box>
                            }
                        </Box>
                    ))}
                </Stack>
            </Stack>
        </Box>
    );
};

export { Step1 };

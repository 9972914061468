/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { AlertColor, Box, CircularProgress, Stack, Switch, Typography } from '@mui/material';
import { money } from '@utils';
import { useUser } from '@hooks/user';

import { sx } from './sx';
import { ResponseAsyncProps } from '@interfaces/ClientServicesApi';
import { Feedback } from '@components';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const Wallet = () => {
    const { css } = sx();
    const { wallet, cart } = useUser();

    const [checked, setChecked] = useState(cart.list.data.discount?.walletActive);
    const [open, setOpen] = useState(false);
    const [responseData, setResponseData] = useState({
        isLoaded: false,
        type: 'success' as AlertColor,
        message: '',
    });

    const handleChange = async (value: boolean) => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoaded: true,
        });
        let response = {} as ResponseAsyncProps | undefined;
        if (!!value) {
            response = await cart.wallet.add();
        } else {
            response = await cart.wallet.remove();
        }

        setResponseData({
            type: response?.response.type,
            isLoaded: false,
            message: response?.response.message,
        });
        setOpen(true);

        if (!response?.error) {
            setChecked(value);
            await cart.load();
        }
    };


    useEffect(() => {
        if (!wallet.walletValue.list.isLoaded) {
            wallet.walletValue.load();
        }
    }, []);

    return (
        <>
            {wallet.walletValue.list.isLoaded && wallet.walletValue.list.data.money > 0 &&
                <Stack direction={'row'} justifyContent={'space-between'} p={'0.625rem'}>
                    <Stack>
                        <Typography variant='h6' sx={{ ...css.texts.title }}>
                            Utilizar crédito
                        </Typography>
                        <Typography variant='body1' sx={{ ...css.texts.value }}>
                            <span>R${money.formatNumberToPriceBRL(wallet.walletValue.list.data.money)}</span> disponível para uso
                        </Typography>
                    </Stack>
                    <Box>
                        {responseData.isLoaded ?
                            <Box p={0.4}>
                                <CircularProgress color='inherit' size={16} />
                            </Box> :
                            <Switch
                                onChange={(e) => handleChange(e.target.checked)}
                                checked={checked}
                                sx={{ ...css.switch }} {...label} />
                        }
                    </Box>
                </Stack>
            }
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={() => setOpen(false)}
                message={responseData.message}
            />
        </>
    );
};

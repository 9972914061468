/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable camelcase */
import React from 'react';
import { useAds } from '@hooks/ads';
import { ListCategorizationProps } from '@interfaces/Services';
import {
    Box,
    Checkbox,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Stack,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { HistoryBackMenuItem, MessageBox } from '@components';
import { skeleton } from './skeleton';
import { CategoriesComponentProps, TempFilterProps } from '@interfaces/Filters';
import { useFilters } from '@hooks/filters';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FILTERS_KEYS } from '@static/filters';
import { numbers } from '@utils';
import { FiltersTypeProps } from '@types';
import queryString from 'query-string';
import { CONSTANTS } from '@static/constants';


const Categories: React.FC<CategoriesComponentProps> = ({ origin }: CategoriesComponentProps) => {
    const ad = useAds();
    const skel = skeleton();
    const location = useLocation();
    const navigate = useNavigate();
    const {
        handleFilters,
        tempFilter,
        externalActions,
        serviceSearchQuery,
        handleURLQuery,
        onChangeFilters,
    } = useFilters();

    interface CategoriesProps extends ListCategorizationProps {
        lastLevel: boolean;
    }
    const { css } = sx();

    const params = {
        limit: FILTERS_KEYS.LIMIT + '=' + CONSTANTS.SEARCH.LIMIT,
        aleatory: FILTERS_KEYS.ALEATORY + '=' + numbers.randomNumber({ maxRange: 100 }),
    };
    const [search, setSearch] = useSearchParams();
    const [tempCategories, setTempCategories] = React.useState<TempFilterProps[]>([]);

    const setURLParams = async (queryStringParams: string) => {
        const params = {
            limit: FILTERS_KEYS.LIMIT + '=' + CONSTANTS.SEARCH.LIMIT,
            aleatory: FILTERS_KEYS.ALEATORY + '=' + numbers.randomNumber({ maxRange: 100 }),
        };

        if (!location.pathname.includes('/resultados/busca') && origin === 'headerBottomMenu') {
            const path = queryStringParams + `&${params.limit}`;
            console.log('-->', path);
            if (tempCategories.length > 0) {
                navigate({
                    pathname: '/resultados/busca/',
                    search: `${path}&${params.aleatory}`,
                });
            }
        }
    };

    const handleOnChance = (
        { _id, name, filterType }: TempFilterProps,
    ) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const item = `${name}|${_id}`;
        let categories = search.get(FILTERS_KEYS.CATEGORY_LIST)?.split(',') ?? [];

        if (e.target.checked) {
            categories.push(item);
            setTempCategories(
                (prev) => (prev = [...prev, { _id, name, filterType }]),
            );
        } else {
            categories = categories.filter((id) => id !== item);
            const filter = tempCategories.filter((item) => item._id !== _id);
            setTempCategories((prev) => (prev = [...filter]));
        }

        if (categories.length === 0) {
            search.delete(FILTERS_KEYS.CATEGORY_LIST);
        } else {
            search.set(FILTERS_KEYS.CATEGORY_LIST, categories.join(','));
            search.set(FILTERS_KEYS.ALEATORY, numbers.randomNumber({ maxRange: 100 }).toString());
        }
        externalActions.action({ action: 'categoryList', state: true });
        setSearch(search);
    };

    const handleCategories = async ({
        _id,
        direction,
        name,
        parentCategory,
        lastLevel,
    }: CategoriesProps) => {
        await ad.categories.
            handleCategorization({
                _id: _id,
                direction: direction,
                lastLevel: lastLevel,
                name: name,
                parentCategory: parentCategory,
            });
    };

    const handleHistoryCategorization = async ({
        _id,
        parentCategory,
        direction,
    }: ListCategorizationProps) => {
        await ad.categories.historyCategorization({
            _id: _id as string,
            parentCategory: parentCategory as string,
            direction: direction,
        });

        console.log('CLEAR CATEGORIES === 1');
    };

    React.useEffect(() => {
        if (!(ad.categories.list.categories.data.length > 0)) {
            ad.categories.handleCategorization({});
        }
        externalActions.action({ action: 'categoryList', state: false });
    }, []);

    React.useEffect(() => {
        handleFilters('categoryList', tempCategories);
    }, [tempCategories]);

    React.useEffect(() => {
        setTempCategories(tempFilter.categoryList);
    }, [tempFilter.categoryList]);

    React.useEffect(() => {
        if (origin === 'headerBottomMenu') {
            setURLParams(location.search);
        }
    }, [location.search]);

    return (
        <Box width='100%'>
            <Stack width='100%'>
                {ad.categories.
                    list.
                    history.length > 0 && (
                        <Box sx={css.categoriesHistory.wrapper}>
                            {
                                ad.categories.
                                    list.
                                    history.map((category, i) => (
                                        <Box
                                            key={category._id}>
                                            <HistoryBackMenuItem
                                                title={category.name as string}
                                                onClick={() =>
                                                    handleHistoryCategorization({
                                                        _id: category._id,
                                                        parentCategory:
                                                            category.parentCategory,
                                                        direction: 'BACK',
                                                    })} />
                                        </Box>
                                    ))
                            }
                        </Box>
                    )}
                <MenuList dense sx={{ padding: 0 }}>
                    {(ad.categories.list.categories.isLoaded) ?
                        (ad.categories.list.categories.data.length > 0) ?
                            ad.categories.list.categories.data
                                .map((category, i, list) => (
                                    <Box>
                                        <MenuItem key={category._id} sx={{ pt: 1 }}>
                                            <ListItemIcon
                                                onClick={!category.lastLevel ? () =>
                                                    handleCategories({
                                                        lastLevel: category.lastLevel,
                                                        _id: category._id,
                                                        direction: 'FORWARD',
                                                        name: category.name,
                                                        parentCategory: category.
                                                            parentCategory,
                                                    }) : () => { }}
                                                sx={css.icons.left}>
                                                <I
                                                    fontSize={10}
                                                    icon={['fas', 'plus']}
                                                />
                                            </ListItemIcon>
                                            <Box
                                                component='label'
                                                htmlFor={category._id}
                                                sx={css.categoryMenu.title}>
                                                <ListItemText>
                                                    {category.name}
                                                </ListItemText>
                                            </Box>
                                            <Box>
                                                <Checkbox
                                                    size='small'
                                                    id={category._id}
                                                    checked={!!tempCategories.find((item) => item._id === category._id)}
                                                    onChange={handleOnChance({
                                                        _id: category._id,
                                                        name: category.name,
                                                        filterType: 'categoryList',
                                                    })}
                                                />
                                            </Box>
                                        </MenuItem>
                                        {!(i + 1 === list.length) && (
                                            <Divider light />
                                        )}
                                    </Box>
                                )) :
                            <MessageBox
                                type={'info'}
                                message='Nenhuma categoria disponível'
                            /> :
                        skel.categories}
                </MenuList>
                {/* </Paper> */}
            </Stack>
        </Box>
    );
};

export { Categories };

import { Box, Container, Stack, Typography, useTheme } from '@mui/material';
import { Image } from '@components';
import LogoCmk from '../../../../assets/img/logo-cmk.png';
import { rem } from '@utils';
import { Link } from 'react-router-dom';
import { sx } from '../sx';

export const Default: React.FC = () => {
    const { palette } = useTheme();
    const { css } = sx();
    return (
        <Stack
            divider={<Box borderBottom={`${rem(1)} solid ${palette.cityLights}`}></Box>}
            sx={{ ...css.stack.wrapperDesk }}>
            <Container>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    py={rem(14)}>
                    <Image
                        src={LogoCmk}
                        objectFit='contain'
                        maxHeight={30}
                        alt={'Commarket'} />
                    <Box display={'flex'}>
                        <Link
                            style={{ ...css.link }}
                            to={''}>
                            <Typography variant='caption'
                                sx={{ ...css.texts.link }}>
                                Privacidade
                            </Typography>
                        </Link>
                        <Typography sx={{ ...css.texts.divisor }}>
                        ·
                        </Typography>
                        <Link
                            style={{ ...css.link }}
                            to={''}>
                            <Typography variant='caption'
                                sx={{ ...css.texts.link }}>
                                Termos
                            </Typography>
                        </Link>
                    </Box>
                </Box>
            </Container>
            <Container>
                <Box p={rem(15)}>
                    <Typography variant='caption' sx={{ ...css.texts.copyright }}>
                        Copyright © 2023 Commarket LTDA
                    </Typography>
                </Box>
            </Container>
        </Stack>
    );
};

/* eslint-disable max-len */
import sliderImage from '../assets/img/hero-slider.png';

const dataProducts = (count: number) => Array.from(Array(count).keys()).map((_, i) => {
    return {
        _id: i+1,
        title: 'Ar Condicionado branco',
        type: 'money',
        to: '/produto/123',
        value: 100,
        discount: 120,
        partnerLogo: 'https://i.shoppingsmiles.com.br/imagens/v2/fornecedor0casasbahia.png',
        image: 'https://fujiokadistribuidor.vteximg.com.br/arquivos/ids/235014',
        cashback: 500,
    };
});

export const slickData = {
    home: {
        hero: [
            {
                _id: 'b0475cabd175',
                image: sliderImage,
            },
            {
                _id: '0e9ca55d0b7b',
                image: sliderImage,
            },
        ],
        products: dataProducts,
    },
};

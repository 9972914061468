import { rem } from '@utils';

const sx = () => {
    const css = {
        wrapper: {
            borderRadius: rem(4),
            overflow: 'hidden',
        },
    };

    return { css };
};

export { sx };

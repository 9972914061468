import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette } = useTheme();
    // const mediaQuery = styles.mediaQueryCSS();

    const css = {
        box: {
            wrapper: {
                height: '100%',
                border: `${rem(1)} solid ${palette.snowWhite}`,
                borderRadius: rem(4),
                overflow: 'hidden',
                position: 'relative',
                backgroundColor: palette.common.white,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            },
            image: {
                wrapper: {
                    position: 'relative',
                    px: rem(15),
                    pt: rem(15),
                },
                partner: {
                    backgroundColor: palette.common.white,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    borderBottomLeftRadius: rem(4),
                    p: rem(5),
                },
            },
            content: {
                wrapper: {
                    px: rem(15),
                    py: rem(10),
                    // mb: rem(30),
                },
                title: {
                    // minHeight: !mediaQuery.sm ? rem(78) : rem(67),
                },
                footer: {
                    wrapper: {
                        // position: 'absolute',
                        // bottom: 0,
                        width: '100%',
                        pl: rem(15),
                        zIndex: 4,
                    },
                },
            },
        },
    };

    return { css };
};

/* eslint-disable max-len */
import React from 'react';
import { AlertColor, Box, Stack, Typography, useTheme } from '@mui/material';
import { sx } from './sx';
import { ButtonIcon, Price } from '../../molecule';
import { excerpt, rem, styles } from '@utils';
import { ProductProps } from '@interfaces';
import { Image } from '../../atoms/image';
import { useUser } from '@hooks/user';
import { Feedback } from '@components';
import { LinkStyled } from './styles';
import { useGlobal } from '@hooks/global';

const Product: React.FC<ProductProps> = ({
    _id, idVariation, variationItemId, favorite,
    title, to, image, partnerLogo, value, discount, cashback, pointValue,
}: ProductProps) => {
    const { css } = sx();
    const { typography, palette } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();
    const global = useGlobal();

    const { favorites, cart } = useUser();

    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        isLoadedFavorite: false,
        isLoadedCart: false,
        type: 'success' as AlertColor,
        message: '',
    });

    const handleClose = () => setOpen(false);

    const handleAction = async (action: 'cart' | 'favorite') => {
        const response = action === 'favorite' ? await favorites.handle({
            idAd: _id,
            idVariation: idVariation,
            variationItemId: variationItemId,
            typeAd: 'SHOPPING',
        }) :
            await cart.handleItem.add({
                idAd: _id,
                idVariation: idVariation,
                variationItemId: variationItemId,
                quantity: 1,
            });
        ;

        if (!response?.error) {
            setResponseData({
                isLoadedFavorite: false,
                isLoadedCart: false,
                type: 'success',
                message: response?.response.message,
            });
            setOpen(true);
            setTimeout(() => {
                handleClose();
            }, 1500);
            action === 'favorite' ? await favorites.load() : await cart.load();
        } else {
            setResponseData({
                isLoadedFavorite: false,
                isLoadedCart: false,
                type: response?.response?.type,
                message: response?.response.message,
            });
            setOpen(true);
            setTimeout(() => {
                handleClose();
            }, 1500);
        }
    };

    const findItemCart = (variationItemId: string) => {
        let i = false;
        cart?.list?.data?.stores?.map((store) => {
            store.items.map((item) => {
                if (item.variationItem._id === variationItemId) {
                    i = true;
                }
            });
            return;
        });

        return i;
    };

    const excerptMaxWords = !mediaQuery.sm ? 8 : 12;

    return (
        <Box height={'100%'} position={'relative'}>
            <LinkStyled to={to}>
            </LinkStyled>
            <Box sx={css.box.wrapper}>
                <Box sx={css.box.image.wrapper}>
                    {partnerLogo && (
                        <Box sx={css.box.image.partner}>
                            <Image
                                src={partnerLogo}
                                alt={title}
                                objectFit='contain'
                                maxHeight={26}
                            />
                        </Box>
                    )}
                    <Image
                        src={image}
                        alt={title}
                        objectFit='contain'
                        maxHeight={!mediaQuery.sm ? 112 : 192}
                    />
                </Box>

                <Box sx={css.box.content.wrapper}>
                    <Typography
                        component='h2'
                        fontSize={rem(!mediaQuery.sm ? 13 : 14)}
                        color='dark'
                        fontWeight={typography.fontWeightSemiBold}
                        mb={rem(10)}
                        sx={css.box.content.title}
                    >
                        {excerpt({
                            text: title,
                            excerptMaxTextLength: excerptMaxWords,
                            ellipsis: true,
                        })}
                    </Typography>
                    {/* {!discount && (<Box height={rem(22)} />)} */}

                </Box>
                <Box sx={css.box.content.footer.wrapper}>
                    <Price
                        type={global.app.view.type}
                        value={global.app.view.type === 'REDEMPTION' ? Number(pointValue) : value}
                        discount={discount}
                        size={mediaQuery.sm ? 'medium' : 'small'}
                    />
                    {(cashback && cashback > 0) ? (
                        <Box mt={rem(4)}>
                            <Typography
                                component='h2'
                                fontSize={rem(10)}
                                color='dark'
                                fontWeight={typography.fontWeightMedium}
                                sx={{
                                    '& > span': {
                                        color: palette.mediumVioletRed,
                                        fontWeight: typography.fontWeightSemiBold,
                                    },
                                }}
                            >
                                Ganhe <span>{cashback} pontos</span> com esta compra
                            </Typography>
                        </Box>
                    ) : null}
                    <Stack py={1} pr={1} justifyContent='end' direction='row' spacing={1}>
                        <ButtonIcon
                            onClick={() => handleAction('favorite')}
                            size='small'
                            showBackgroundColor
                            active={
                                !!(favorites?.list?.data?.favorites?.find((item) =>
                                    item.variation.items._id === variationItemId)) || (!favorites.list.isLoaded && favorite)
                            }
                            customColor={!!(favorites?.list?.data?.favorites?.find((item) =>
                                item.variation.items._id === variationItemId)) || (!favorites.list.isLoaded && favorite) ?
                                palette.primaryColor : undefined}
                            icon='heart' />
                        <ButtonIcon
                            onClick={() => handleAction('cart')}
                            showBackgroundColor
                            size='small'
                            active={findItemCart(variationItemId)}
                            customColor={findItemCart(variationItemId) ?
                                palette.primaryColor : undefined}
                            icon='cart-shopping' />
                    </Stack>
                </Box>
                <Feedback.MuiSnackbar
                    open={open}
                    severity={responseData.type}
                    onClose={handleClose}
                    message={responseData.message}
                />
            </Box>
        </Box>
    );
};

export { Product };

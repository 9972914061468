import { Box, styled } from '@mui/material';

export const Icon = styled(Box)(({ theme }) => ({
    height: 52,
    width: 52,
    borderRadius: 100,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
        height: 70,
        width: 70,
    },
}));

export const Content = styled(Box)(({ theme }) => ({
    padding: '1.875rem .9375rem',
}));

import { Template } from '@components';
import { useLayout } from '@hooks/layout';
import Box from '@mui/material/Box';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ViewOptions: React.FC = () => {
    const navigate = useNavigate();

    const { layout } = useLayout();

    React.useEffect(() => {
        layout.mobile.header.headerSettings({
            title: 'Opção de pagamento',
            onBackAction: () => navigate(-1),
        });
    }, []);

    return (
        <Box>
            <Template.CurrencyType />
        </Box>
    );
};

export { ViewOptions };

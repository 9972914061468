import { rem } from '@utils';

const sx = () => {
    const css = {
        discount: {
            wrapper: {
                opacity: 0.8,
                position: 'relative',
                display: 'inline-block',
            },
        },
        overline: {
            position: 'absolute',
            height: rem(1),
            width: '100%',
            top: 0,
            bottom: 0,
            left: 0,
            margin: 'auto !important',
            backgroundColor: 'mediumGray',
        },
    };

    return { css };
};

export { sx };

import { useTheme } from '@mui/material';
import { rem } from '@utils';

const sx = () => {
    const { typography, palette } = useTheme();

    const flexCenter = {
        display: 'flex',
        alignItems: 'center',
    };

    const css = {
        header: {
            wrapper: {
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
            },
            content: {
                wrapper: {
                    ...flexCenter,
                    justifyContent: 'space-between',
                    width: '100%',
                    height: rem(80),
                    // display: 'none',
                },
                col1: {
                    wrapper: {
                        ...flexCenter,
                        height: '100%',
                        width: '100%',
                    },
                    nav: {
                        wrapper: {
                            ...flexCenter,
                            height: '100%',
                        },
                        item: (active: boolean) => {
                            return {
                                wraper: {
                                    backgroundColor: palette.common.white,
                                    display: 'flex',
                                    alignItems: 'center',
                                    position: 'relative',
                                    pl: rem(15),
                                    pr: rem(15),
                                    textDecoration: 'none',
                                    fontSize: rem(14),
                                    color: palette.darkGray,
                                    height: '100%',
                                    fontWeight: active ?
                                        typography.fontWeightBold :
                                        typography.fontWeightMedium as any,
                                },
                                overlay: {
                                    width: '100%',
                                    height: rem(3),
                                    background: palette.primaryColor,
                                    position: 'absolute',
                                    left: 0,
                                    bottom: 0,
                                    display: active ? 'block' : 'none',
                                },
                            };
                        },
                    },
                },
                col2: {
                    wrapper: {
                        ...flexCenter,
                        justifyContent: 'flex-end',
                        height: '100%',
                    },
                },
            },
        },
        sub: {
            wrapper: {
                position: 'relative',
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
                boxShadow: `${rem(0)} ${rem(3)} ${rem(6)} rgba(0, 0, 0, 0.2)`,
                zIndex: 10,
            },
        },
    };

    return { css };
};

export { sx };

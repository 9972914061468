/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { Feedback, HeaderPage, PaymentMethodCard, Template } from '@components';
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Stack,
    Typography,
} from '@mui/material';
import { rem } from '@utils';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { ToggleStaticProps } from '@interfaces';
import { useUser } from '@hooks/user';
import { sx } from './sx';
import { CreditCardFlagType } from '@types';
import { cardFlagsIcon } from '@static/card-flags';

export const Edit: React.FC = () => {
    const dialogChangesItem = React.useRef<ToggleStaticProps>(null);

    const navigate = useNavigate();
    const param = useParams();
    const user = useUser();
    const { css } = sx();

    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        isLoading: false,
        type: 'success' as AlertColor,
        message: '',
    });

    const paramType = {
        type: param.typeAction as 'adicionar' | 'editar',
    };

    const handleClose = () => setOpen(false);

    const deletePaymentMethod = async () => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoading: true,
        });
        if (user.paymentForm.current.data?._id) {
            const response = await user.paymentForm.delete({
                cardId: user.paymentForm.current.data._id,
            });
            setResponseData({
                isLoading: false,
                type: response?.response.type,
                message: response?.response.message,
            });
            setOpen(true);
            if (!response?.error) {
                dialogChangesItem.current?.hide();
                user.paymentForm.current.clear();
                setTimeout(() => {
                    navigate('/formas-de-pagamento');
                }, 1000);
            }
        }
    };


    return (
        <Box sx={css.wrapper}>
            <HeaderPage
                noPadding
                onClick={() => navigate('/formas-de-pagamento')}
                icon={['far', 'chevron-left']}
                titleAlign={'default'}
                title={'Formas de pagamento'}
                action={
                    paramType.type === 'editar' && (
                        <Button
                            variant='contained'
                            sx={css.buttons.delete}
                            size="small"
                            onClick={() => {
                                console.log(user.paymentForm.current.data._id);
                                dialogChangesItem.current?.show();
                            }}>
                            <Stack
                                direction='row'
                                alignItems='center'
                                spacing={0.5}>
                                <Typography
                                    variant='subtitle1'
                                    component='span'
                                    fontSize={12}>
                                    Excluir
                                </Typography>
                                <I
                                    fontSize={rem(14)}
                                    icon={['fal', 'trash-can']}
                                />
                            </Stack>
                        </Button>
                    )} />
            <Box mt={rem(10)}>
                <Template.Forms.PaymentForm />
            </Box>
            <Template.MuiDialog
                ref={dialogChangesItem}
                maxWidth='sm'
                title='Deletar forma de pagamento'
                showDialogTitle
                onCancel={{
                    action: () => dialogChangesItem.current?.hide(),
                }}
                onConfirm={{
                    text: [
                        'Deletar',
                        responseData.isLoading &&
                        <CircularProgress
                            color="inherit"
                            size={12}
                            sx={{ ml: 1 }}
                        />,
                    ],
                    action: deletePaymentMethod,
                }}>
                <Box p={2}>
                    <PaymentMethodCard
                        // eslint-disable-next-line max-len
                        srcFlag={cardFlagsIcon[user.paymentForm.current.data.flag as CreditCardFlagType]}
                        nameFlag={user.paymentForm.current.data.flag}
                        numberCard={user.paymentForm.current.data.number} />
                </Box>
            </Template.MuiDialog>
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box>
    );
};

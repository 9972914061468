/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { ButtonCurrencyType } from './components/button';
import React, { useEffect } from 'react';
import { Divider, useMediaQuery } from '@mui/material';
import { useGlobal } from '@hooks/global';
import { useNavigate } from 'react-router-dom';
import { StoreType } from '@types';
import { useAds } from '@hooks/ads';
import { useUser } from '@hooks/user';
import { useLayout } from '@hooks/layout';

const CurrencyType: React.FC = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const global = useGlobal();
    const layout = useLayout();
    const navigate = useNavigate();
    const ad = useAds();
    const user = useUser();
    const [actualType, setActualType] = React.useState(global.app.view.type);

    const setURLParams = async (type: StoreType) => {
        global.app.view.set(type);
        if (!matches) {
            navigate(-1);
        }
    };

    const loadAds = async () => {
        layout.layout.loading.handle(true);
        try {
            await ad.ads.popular.load();
            await ad.ads.news.load();
            await user.cart.load();
        } catch (error) {
            layout.layout.loading.handle(false);
        } finally {
            layout.layout.loading.handle(false);
        }
    };

    useEffect(() => {
        if (global.app.view.type !== actualType) {
            loadAds();
            setActualType(global.app.view.type);
        }
    }, [global.app.view.type]);

    return (
        <React.Fragment>
            <ButtonCurrencyType
                title='Ganhar Pontos'
                text='Pague com o cartão de crédito ou outro método de
            pagamento em dinheiro e acumule pontos.'
                onClick={() => setURLParams('SHOPPING')}
                disabled={!!(global.app.view.type === 'SHOPPING')}
            />
            <Divider light />
            <ButtonCurrencyType
                title='Usar Pontos'
                text='Use seus pontos acumulados'
                onClick={() => setURLParams('REDEMPTION')}
                disabled={!!(global.app.view.type === 'REDEMPTION')}
            />
        </React.Fragment>
    );
};

export { CurrencyType };

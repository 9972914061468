import { Box, Skeleton, Stack } from '@mui/material';

export const skeleton = () => {
    const valueText = (
        <Skeleton variant='text' width={165} />
    );
    const updateText = (
        <Stack>
            <Skeleton variant='text' width={315} />
            <Skeleton variant='text' width={220} />
        </Stack>
    );
    const listMovement = (
        <Box>
            <Stack spacing={2}>
                <Skeleton variant='rectangular' height={40} />
                <Skeleton variant='rectangular' height={40} />
            </Stack>
        </Box>
    );

    return { valueText, updateText, listMovement };
};

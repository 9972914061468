import React from 'react';
import {
    Box,
    CircularProgress,
    Divider,
    IconButton,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { sx } from './sx';
import { ControllQuantityProps } from '@interfaces';

const ControllQuantity: React.FC<ControllQuantityProps> = ({
    add, remove, value, limit, disabled, loading, label,
}: ControllQuantityProps) => {
    const { palette } = useTheme();
    const { css } = sx();
    return (
        <Box>
            {label &&
                <Typography
                    sx={{ ...css.sxLabel }}
                    variant='h4'>
                    Quantidade
                </Typography>
            }
            <Stack
                sx={{ ...css.sxStack }}
                divider={
                    <Divider
                        orientation="vertical"
                        sx={{ ...css.sxDivider }} />
                }
                direction={'row'}>
                <IconButton
                    size='small'
                    disabled={value <= 1}
                    onClick={disabled ? () => { } : remove} sx={{ ...css.sxButton }}>
                    <I icon={['fas', 'minus']} />
                </IconButton>
                <Box sx={{ ...css.sxValueBox }}>
                    {loading ? (
                        <CircularProgress
                            size={14}
                            sx={{
                                color: palette.darkGray,
                            }} />
                    ) : (

                        <Typography sx={{ ...css.sxNumber }}>
                            {value}
                        </Typography>
                    )}
                </Box>
                <IconButton
                    size='small'
                    disabled={value === limit}
                    onClick={disabled ? () => { } : add} sx={{ ...css.sxButton }}>
                    <I icon={['fas', 'plus']} />
                </IconButton>
            </Stack>
        </Box>
    );
};

export { ControllQuantity };

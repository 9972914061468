import { styled, useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        box: {
            image: {
                width: rem(50),
                height: rem(50),
                border: `${rem(1)} solid ${palette.cityLights}`,
                p: rem(2),
                borderRadius: rem(4),
            },
        },
        text: {
            title: {
                fontSize: rem(13),
                fontWeight: typography.fontWeightSemiBold,
            },
        },
    };

    return { css };
};

export const ImageAd = styled('img')`
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 4px;
`;

/* eslint-disable max-len */
import { Box, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { useUser } from '@hooks/user';
import { Link } from 'react-router-dom';

interface Step6Props {
    tagUrl?: string;
}

const Step6 = ({
    tagUrl,
}:Step6Props) => {
    const { css } = sx();
    const { purchase } = useUser();
    return (
        <Box p={2}>
            <Stack spacing={2} sx={{ ...css.stack.message }}>
                <Stack alignItems={'center'} spacing={1}>
                    <Box sx={{ ...css.box.check }}>
                        <I icon={['far', 'check']} />
                    </Box>
                    <Typography
                        sx={{ ...css.text.message }}
                        variant='h3'>
                        Você pode visualizar e imprimir sua etiqueta clicando no botão imprimir logo abaixo.
                    </Typography>
                </Stack>
            </Stack>
            <Box pt={4}>
                <ul>
                    <li>
                        <Stack mb={3} spacing={1} direction={'row'}>
                            <I fontSize={8} icon={['fas', 'circle']} />
                            <Typography sx={{ ...css.text.p }}>
                                Caso você não consiga visualizar a etiqueta abaixo
                                <Link
                                    style={{ ...css.text.link }}
                                    target='_blank'
                                    to={tagUrl ? tagUrl : purchase.devolution.data.tagUrl}>
                                    clique aqui
                                </Link>.
                            </Typography>
                        </Stack>
                    </li>
                </ul>
            </Box>
            <Box sx={{
                height: '210px',
            }}>
                <iframe width="100%" height="100%" src={tagUrl ? tagUrl : purchase.devolution.data.tagUrl} title="Etiqueta Melhor Envio" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </Box>
        </Box>
    );
};

export { Step6 };

import React from 'react';
import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { Price } from '@components';
import { ExtractTimelineExpireItemProps } from '@interfaces';
import { formatShortDate } from '@utils';

const ExtractTimelineExpireItem = ({
    title, date, value, lastItem,
}: ExtractTimelineExpireItemProps) => {
    const { css } = sx();
    return (
        <Box sx={{ ...css.box.wrapper.content }}
            className='wallet-extract-item'>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ ...css.box.items }}>
                    <Box sx={{ ...css.box.icon }}>
                        <I
                            icon={['far', 'arrow-up-arrow-down']}
                            style={{ ...css.icon }} />
                    </Box>
                    <Box sx={{ ...css.box.texts }}>
                        <Box>
                            <Typography
                                component="p"
                                sx={{ ...css.texts.legend }}>
                                Expira em {formatShortDate(date)}
                            </Typography>
                            <Typography
                                component='p'
                                sx={{ ...css.texts.title }}>
                                {title}
                            </Typography>
                        </Box>
                        <Box sx={{ ...css.box.price }}>
                            <Price
                                type={'REDEMPTION'}
                                value={value} />
                            {false &&
                                <Box sx={{ ...css.box.overline }}></Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                className={`divider ${lastItem ? 'last-divider' : ''}`}
                sx={{ ...css.box.dividerV }} />
        </Box>
    );
};

export { ExtractTimelineExpireItem };

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const LinkStyled = styled(Link)({
    textDecoration: 'none',
    position: 'absolute',
    height: 'calc(100% - 46px)',
    width: '100%',
    zIndex: 10,
});


export const simpleHeaderMobile = {
    userMenu: '/menu/usuario',
    unloggedMenu: '/menu/deslogado',
};

export const defaultRouteLayout = {
    checkoutSuccess: '/checkout/pedido-realizado',
    cart: '/meu-carrinho',
};

import {
    Box,
    Button,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { rem } from '@utils';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Edit: React.FC = () => {
    const { typography } = useTheme();
    const navigate = useNavigate();

    const [gender, setGender] = React.useState('');

    const handleChangeGender = (event: SelectChangeEvent) => {
        setGender(event.target.value as string);
    };
    return (
        <Box pt={rem(30)}>
            <Container maxWidth='sm'>
                <Box mb={rem(20)}>
                    <Typography
                        variant="body2"
                        component='h1'
                        fontSize={rem(18)}
                        color='darkGray'
                        fontWeight={typography.fontWeightBold}
                        gutterBottom>
                        Endereço de entrega
                    </Typography>
                    <Typography
                        variant="body2"
                        component='h1'
                        fontSize={rem(14)}
                        color='darkGray'
                        fontWeight={typography.fontWeightMedium}>
                        Edite seu endereço de entrega.
                    </Typography>
                </Box>

                <Stack spacing={2}>
                    <TextField
                        fullWidth
                        focused
                        label="CEP *"
                        id="name"
                        helperText=''
                        variant="outlined"
                    />
                    <Stack spacing={2} direction='row'>
                        <FormControl fullWidth focused>
                            <InputLabel focused
                                id="select-state">Estado *</InputLabel>
                            <Select
                                labelId="select-state"
                                id="state"
                                value={gender}
                                label="Estado *"
                                onChange={handleChangeGender}
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            fullWidth
                            focused
                            id="city"
                            label="Cidade *"
                            helperText=''
                            variant="outlined" />
                    </Stack>

                    <Stack spacing={2} direction='row'>
                        <TextField
                            fullWidth
                            focused
                            id="address"
                            label="Endereço *"
                            helperText=''
                            variant="outlined"
                            sx={{ maxWidth: '70%' }} />
                        <TextField
                            fullWidth
                            focused
                            id="number"
                            label="Número *"
                            helperText=''
                            variant="outlined"
                            sx={{ maxWidth: '30%' }} />
                    </Stack>

                    <TextField
                        fullWidth
                        focused
                        id="complement"
                        label="Complemento *"
                        helperText=''
                        variant="outlined" />
                </Stack>
                <Stack
                    spacing={1}
                    justifyContent='space-between'
                    mt={rem(15)}>
                    <Button
                        fullWidth
                        type='button'
                        variant='contained'
                        size='large'
                        onClick={() => navigate(-1)}>
                        Salvar
                    </Button>
                    <Button
                        fullWidth
                        type='button'
                        variant='text'
                        size='large'
                        onClick={() => navigate('/checkout/endereco')}>
                        Cancelar
                    </Button>
                </Stack>
            </Container>
        </Box>
    );
};

import { client } from '@api';
import { HandleFavoriteActionProps } from '@interfaces/Services';

export const handle = async ({ ...data }: HandleFavoriteActionProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/ad/favorite/addOrRemove', {
            ...data,
        });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const load = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/ad/favorites');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

/* eslint-disable no-unused-vars */
import { client } from '@api';
import React from 'react';
import { DEFAULTS } from './DEFAULTS';
import { servicesUtils } from '@utils';
import { useGlobal } from '@hooks/global';

export const userExtract = () => {
    const { services } = client();
    const global = useGlobal();
    const [listExtract, setListExtract] = React.useState(
        DEFAULTS.USER.EXTRACT.LIST,
    );

    const load = async () => {
        setListExtract(DEFAULTS.USER.EXTRACT.LIST);
        const session = await global.app.cookies.get('session');
        if (session.error) {
            setListExtract((prev) => prev = {
                ...prev,
                status: servicesUtils.noValidToken.response.status,
                message: servicesUtils.noValidToken.response.message,
                isLoaded: false,
            });
            return servicesUtils.noValidToken;
        }
        const response = await services.user.extract.load();
        setListExtract((prev) => prev = {
            message: response?.response.message ?
                response?.response.message :
                '',
            isLoaded: !!(!response?.error),
            status: response?.response.status,
            data: response?.response.payload ?
                response?.response.payload : DEFAULTS.USER.EXTRACT.LIST.data,
        });
        return response;
    };

    return {
        load,
        list: listExtract,
    };
};

import { BannerTypeProps } from '@types';
import { client } from '../..';

export const load = async (bannerType: BannerTypeProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get(`/banners/${bannerType}`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        link: {
            color: palette.common.white,
            textDecoration: 'none',
        },
    };

    return { css };
};

import React from 'react';
import { HeaderPage, Template } from '@components';
import {
    Box,
    Container,
    Typography,
    useTheme,
} from '@mui/material';
import { rem } from '@utils';
import { useNavigate, useParams } from 'react-router-dom';
import { sx } from './sx';

export const Add: React.FC = () => {
    const { typography } = useTheme();

    const navigate = useNavigate();
    const param = useParams();
    const { css } = sx();

    const paramType = {
        type: param.type as 'adicionar' | 'editar',
        id: param.id,
    };

    return (
        <Box sx={css.wrapper}>
            <HeaderPage
                noPadding
                onClick={() => navigate('/checkout/endereco')}
                icon={['far', 'chevron-left']}
                titleAlign={'default'}
                title={'Endereço de entrega'} />
            <Box mt={rem(30)} mb={rem(30)}>
                <Container maxWidth='xs'>
                    <Box mb={rem(30)}>
                        <Typography
                            variant="body2"
                            component='h1'
                            fontSize={rem(18)}
                            color='darkGray'
                            fontWeight={typography.fontWeightBold}
                            gutterBottom>
                            Endereço de entrega
                        </Typography>
                        <Typography
                            variant="body2"
                            component='h1'
                            fontSize={rem(14)}
                            color='darkGray'
                            fontWeight={typography.fontWeightMedium}>
                            Adicione um novo endereço de entrega.
                        </Typography>
                    </Box>
                    <Template.Forms.Address
                        addressID={paramType.id}
                        toCheckout
                        navigateToAfterResponse='/checkout/endereco'
                    />
                </Container>
            </Box>
        </Box>
    );
};

import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { typography, palette } = useTheme();
    const css = {
        sxBoxWrapper: {
            'cursor': 'pointer',
            'position': 'relative',
            'border': `1px solid ${palette.clouds}`,
            'display': 'flex',
            'justifyContent': 'center',
            'alignItems': 'center',
            'borderRadius': rem(4),
            'width': rem(44),
            'height': rem(44),

            '&.active': {
                border: `2px solid ${palette.primaryColor}`,
            },
        },
        sxBoxImage: {
            'width': '100%',
            'height': '100%',
            '& > img': {
                'width': '100%',
                'height': '100%',
                'borderRadius': rem(4),
            },
        },
        sxText: {
            paddingTop: rem(4),
            fontSize: rem(12),
            fontWeight: typography.fontWeightBold,
            textAlign: 'center',
        },
    };

    return { css };
};

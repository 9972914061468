import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();

    const css = {
        wrapper: {
            border: '1px solid ' + palette.cityLights,
        },
        buttons: {
            delete: {
                ...{
                    backgroundColor: palette.status.rejected,
                },
                '&:hover': {
                    backgroundColor: palette.status.rejected + ' !important',
                },
            },
        },
    };
    return { css };
};

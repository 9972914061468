import { useTheme } from '@mui/material';
import { rem } from '@utils';

const sx = () => {
    const { palette } = useTheme();

    const css = {
        wrapper: {
            border: rem(1) + ' solid ' +palette.grey[200],
            padding: '5px',
            borderRadius: '4px',
            minHeight: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
        },
    };

    return { css };
};

export { sx };

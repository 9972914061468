/* eslint-disable max-len */
import {
    CreateUserProps,
    CreaterUserSendCodeValidationProps,
    CreaterUserCodeValidationProps,
    JwtTokenProps,
    UserUploadImageProps,
    UpdateUserServiceProps,
} from '@interfaces/Services';
import { client } from '../..';

export * as cart from './cart';
export * as favorites from './favorites';
export * as extract from './extract';
export * as address from './address';
export * as paymentForm from './payment-form';
export * as purchase from '../purchase';
export * as wallet from './wallet';

export const create = async ({ ...data }: CreateUserProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/user', {
            ...data,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const update = async ({ data, jwt }: UpdateUserServiceProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/user', {
            ...data,
        });
        console.log('UPDATE JWT: ', response);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const load = async ({ jwt }: JwtTokenProps) => {
    const { instance, handleErrors, handleResponse } = client();
    try {
        const response = await instance.get('/user', {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const loadBalance = async ({ jwt }: JwtTokenProps) => {
    const { instance, handleErrors, handleResponse } = client();
    try {
        const response = await instance.get('/user/balance', {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const sendValidationCode = async (
    { ...data }: CreaterUserSendCodeValidationProps,
) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/user/request-validation', {
            ...data,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const codeValidation = async ({ ...data }: CreaterUserCodeValidationProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/user/validate', {
            ...data,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const images = async ({ type, data }: UserUploadImageProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put(
            `/user/image/${type}`,
            data,
            { headers: { 'Content-Type': 'multipart/form-data' } });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export const upload = {
    images,
};

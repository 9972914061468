import { Paper, styled } from '@mui/material';
import { rem } from '@utils';

export const Item = styled(Paper)(({ theme }) => ({
    ...{
        boxShadow: 'none',
        border: '1px solid ' + theme.palette.cityLights,
        backgroundColor: theme.palette.common.white,
        paddingTop: rem(8),
        paddingBottom: rem(8),
        paddingLeft: rem(10),
        paddingRight: rem(10),
        textAlign: 'left',
        fontSize: rem(12),
        color: theme.palette.dark,
        textTransform: 'capitalize',
        cursor: 'pointer',
        fontFamily: theme.typography.fontFamily,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    '&:hover': {
        borderColor: theme.palette.clouds,
    },
}));

import { styled, InputLabel } from '@mui/material';

export const FormControlLabel = styled(InputLabel)(({ theme }) => ({
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    fontSize: '12px !important',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightSemiBold,
    color: theme.palette.dark,
    left: 10,
    top: -8,
    zIndex: 1,
    padding: '0 4px',
}));

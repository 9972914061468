import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { typography } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        stack: {
            mb: mediaQuery.md ? rem(30) : rem(10),
        },
        texts: {
            sectionName: {
                py: rem(10),
                fontWeight: typography.fontWeightBold,
                fontSize: mediaQuery.md ? rem(18) : rem(14),
            },
            value: {
                'fontSize': rem(14),
                'h1': {
                    fontSize: rem(32),
                },
                'h2': {
                    fontSize: rem(24),
                },
                'h3': {
                    fontSize: rem(18),
                },
                'h4': {
                    fontSize: rem(16),
                },
                'h5': {
                    fontSize: rem(15),
                },
                'h6': {
                    fontSize: rem(14),
                },
            },
        },
    };

    return { css };
};

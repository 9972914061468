/* eslint-disable max-len */
import React from 'react';
import { bankAccounts } from '@static/bank-accounts';
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    Grid,
    TextField,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormControlLabel } from '../styles';
import { useUser } from '@hooks/user';
import { BankAccountListType, FormAccountBank } from '@interfaces/index';

const BankAccountForm = () => {
    const { wallet } = useUser();

    const [selectedBank, setSelectedBank] = React.useState<BankAccountListType>({
        label: '',
        value: '',
        dig_ag: false,
        dig_cc: false,
    });
    const [bankAccountsList] = React.useState<BankAccountListType[]>(bankAccounts);


    const { register, handleSubmit, formState: { errors }, setValue, reset } =
        useForm<FormAccountBank>({
            mode: 'all',
        });

    const findSelectedBank = (bankName: string) => {
        const findResult = bankAccountsList.find((bank) => bank.label === bankName);
        if (findResult) {
            setValue('bank', findResult.label);
            setSelectedBank(findResult);
            if (!findResult.dig_ag) {
                setValue('agencyDig', 'x');
            } else {
                setValue('agencyDig', '');
            }
            if (!findResult.dig_cc) {
                setValue('accountDig', 'x');
            } else {
                setValue('accountDig', '');
            }
        } else {
            setValue('bank', '');
        }
    };

    const onSubmit: SubmitHandler<FormAccountBank> = async (data) => {
        const digAgFormated = data.agencyDig === '0' ? 'x' : data.agencyDig;

        const dataFormated = {
            bank: data.bank,
            agency: `${data.agency}${selectedBank.dig_ag ? '-' : ''}${selectedBank.value === '001' ? digAgFormated : selectedBank.dig_ag ? data.agencyDig : ''}`,
            account: `${data.accountNumber}${selectedBank.dig_cc ? '-' + data.accountDig : ''}`,
        };

        wallet.withdrawal.bank.handle(dataFormated);
        reset();
    };

    return (
        <Box sx={{ m: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} mb={1}>
                        <Box position='relative'>
                            <FormControlLabel htmlFor={'bank-list'} >
                                    Banco
                            </FormControlLabel>
                            <FormControl
                                fullWidth
                                error={!!errors.bank}>
                                <Autocomplete
                                    disablePortal
                                    fullWidth
                                    id="bank-list"
                                    options={bankAccountsList}
                                    onInputChange={(_, newBankValue) =>
                                        findSelectedBank(newBankValue)}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        helperText={errors.bank ?
                                            errors.bank.message :
                                            ''}
                                        error={!!errors.bank}
                                        {...register('bank',
                                            { required: true },
                                        )}
                                    />}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={selectedBank.dig_ag ? 8 : 12} mb={1}>
                        <TextField
                            focused
                            type='number'
                            fullWidth
                            label={'Agência'}
                            variant="outlined"
                            helperText={errors.agency ?
                                errors.agency.message :
                                ''}
                            error={!!errors.agency}
                            {...register('agency',
                                { required: true },
                            )} />
                    </Grid>
                    {selectedBank.dig_ag &&
                            <Grid item xs={4} mb={1}>
                                <TextField
                                    focused
                                    fullWidth
                                    label={'Dígito'}
                                    type='number'
                                    variant="outlined"
                                    helperText={errors.agencyDig ?
                                        errors.agencyDig.message :
                                        ''}
                                    error={!!errors.agencyDig}
                                    {...register('agencyDig',
                                        { required: selectedBank.dig_ag },
                                    )} />
                            </Grid>
                    }
                    <Grid item xs={selectedBank.dig_cc ? 8 : 12} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            type='number'
                            label={'Número da conta'}
                            variant="outlined"
                            helperText={errors.accountNumber ?
                                errors.accountNumber.message :
                                ''}
                            error={!!errors.accountNumber}
                            {...register('accountNumber',
                                { required: true },
                            )} />
                    </Grid>
                    {selectedBank.dig_cc &&
                            <Grid item xs={4} mb={1}>
                                <TextField
                                    focused
                                    fullWidth
                                    type='number'
                                    label={'Dígito'}
                                    variant="outlined"
                                    helperText={errors.accountDig ?
                                        errors.accountDig.message :
                                        ''}
                                    error={!!errors.accountDig}
                                    {...register('accountDig',
                                        { required: selectedBank.dig_cc },
                                    )} />
                            </Grid>
                    }
                </Grid>
                <Box
                    display='flex'
                    justifyContent='center'
                    mt={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        type='submit'
                        className='MuiButton primary'
                    >
                            Salvar
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export { BankAccountForm };

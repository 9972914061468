/* eslint-disable max-len */
import React from 'react';
import { Box } from '@mui/material';
import { rem, styles } from '@utils';
import { FilterHead } from '../../../design-system/molecule';
import { SearchFilterProps } from '@interfaces/Filters';
import { useFilters } from '@hooks/filters';

const Search: React.FC<SearchFilterProps> = (
    { filtersList }: SearchFilterProps,
) => {
    const mediaQuery = styles.mediaQueryCSS();
    const { listActiveFilters } = useFilters();

    const [toggleFilter, setToggleFilter] = React.useState<string[]>([]);

    const handleToggleFilter = (_id: string) => {
        const find = toggleFilter.find((item) => item === _id);
        if (!find) {
            setToggleFilter((prev) => prev = [...prev, _id]);
        } else {
            const filter = toggleFilter.filter((item) => item !== _id);
            setToggleFilter((prev) => prev = [...filter]);
        }
    };

    return (
        <Box>
            {filtersList.map(({ _id, name, filterContent, filter }) => (
                <Box mb={rem(10)} key={_id}>
                    <FilterHead
                        title={name}
                        onClick={() => handleToggleFilter(_id)}
                        active={toggleFilter.includes(_id)}
                        activeFilters={listActiveFilters.
                            data.
                            find((item) => item.filterType === filter)?.list.length
                        }
                    />
                    <Box sx={{
                        display: mediaQuery.md ? 'block' :
                            toggleFilter.includes(_id) ?
                                'block' :
                                'none',
                    }}>
                        {filterContent}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export { Search };

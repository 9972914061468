import { Box, Skeleton, Stack } from '@mui/material';

export const skeleton = () => {
    const boxContentProduct = (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: '0.9375rem',
            pr: '0.625rem',
        }}>
            <Box>
                <Stack direction={'row'} spacing={1}>
                    <Skeleton variant='rectangular' height={80} width={80} />
                    <Stack spacing={0.6}>
                        <Skeleton variant='text' width={175} />
                        <Skeleton variant='rectangular' height={24} width={100} />
                        <Skeleton variant='text' width={175} />
                        <Skeleton variant='text' width={130} />
                        <Skeleton variant='text' width={130} />
                    </Stack>
                </Stack>
            </Box>
            <Box>
                <Stack spacing={2}>
                    <Skeleton variant='circular' height={52} width={56} />
                    <Skeleton variant='circular' height={52} width={56} />
                </Stack>
            </Box>
        </Box>
    );

    return { boxContentProduct };
};

import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { rem } from '@utils';

interface AuthFeedbackProps {
    title: string;
    subtitle?: string;
    button?: {
        text: string;
        onClick: () => void;
    }
}

const AuthFeedback: React.FC<AuthFeedbackProps> = ({
    title, subtitle, button }: AuthFeedbackProps,
) => {
    const { typography } = useTheme();

    return (
        <Box>
            <Box mb={rem(20)}>
                <Typography
                    variant="body2"
                    component='h1'
                    align='center'
                    fontSize={rem(24)}
                    color='darkGray'
                    gutterBottom
                    fontWeight={typography.fontWeightBold}>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography
                        variant="body2"
                        component='h1'
                        align='center'
                        fontSize={rem(14)}
                        color='darkGray'
                        whiteSpace='pre-line'
                        fontWeight={typography.fontWeightMedium}>
                        {subtitle}
                    </Typography>
                )}
            </Box>
            {button && (
                <Stack direction={'row'} justifyContent={'center'}>
                    <Button
                        type='button'
                        variant='contained'
                        size='large'
                        onClick={button.onClick}>
                        {button.text}
                    </Button>
                </Stack>
            )}

        </Box>
    );
};

export { AuthFeedback };

import React from 'react';
import {
    ExtractTimelineExpireItem,
    MessageBox,
    WalletExtractTimeline,
} from '@components';
import { useUser } from '@hooks/user';
import { skeleton } from '../../skeleton';
import { Button, Stack } from '@mui/material';

export const ExpireSection = () => {
    const user = useUser();
    const skel = skeleton();

    const [limitList, setLimitList] = React.useState<number>(3);

    return (
        <WalletExtractTimeline>
            {user.wallet.movementExpire.list.isLoaded ?
                (user.wallet.movementExpire.list.data.length > 0 ?
                    user.wallet.movementExpire.list.data.slice(0, limitList)
                        .map((item, i) => (
                            <ExtractTimelineExpireItem
                                key={`expire-extract-${i}`}
                                title={item.description}
                                value={item.value}
                                date={item.expirationDate}
                                lastItem={i === limitList ||
                                    i === user.wallet.movementExpire.list.data.length - 1
                                }/>
                        )) :
                    <WalletExtractTimeline>
                        <MessageBox
                            message={user.wallet.movementExpire.list.error ?
                                user.wallet.movementExpire.list.message :
                                'Nenhum registro encontrado'}
                            type={'info'} />
                    </WalletExtractTimeline>
                ) :
                <WalletExtractTimeline>
                    {skel.listMovement}
                </WalletExtractTimeline>}
            {user.wallet.movementExpire.list.isLoaded &&
            user.wallet.movementExpire.list.data.length > 0 &&
                <Stack justifyContent={'center'}>
                    {limitList < user.wallet.movementExpire.list.data.length ?
                        <Button
                            onClick={() => setLimitList(limitList + 3)}
                            variant='text'>
                            Mostrar mais
                        </Button> :
                        !(user.wallet.movementExpire.list.data.length <= 3) &&
                        <Button
                            onClick={() => setLimitList(3)}
                            variant='text'>
                            Mostrar menos
                        </Button>
                    }
                </Stack>
            }
        </WalletExtractTimeline>
    );
};

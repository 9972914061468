import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Price } from '@components';
import { WalletExtractTimelineItemProps, WalletExtractTimelineProps } from '@interfaces';
import { AdType } from '@types';
import { formatUpdateDate } from '@utils';

const WalletExtractTimeline = ({ children }: WalletExtractTimelineProps) => {
    const { css } = sx();
    return (
        <Box sx={{ ...css.box.wrapper.timeline }}>
            {children}
        </Box>
    );
};

const typeIcon = {
    CREDIT: ['fas', 'check'] as IconProp,
    DEBIT: ['far', 'circle-minus'] as IconProp,
};

const handleCurrency = {
    MONEY: 'SHOPPING',
    POINTS: 'REDEMPTION',
};


const WalletExtractTimelineItem = ({
    title, date, type, value, currency, status, lastItem,
}: WalletExtractTimelineItemProps) => {
    const { css } = sx();
    const { palette } = useTheme();

    const handleStatus = {
        PROCESSING: {
            label: 'Processando',
            color: palette.status.pending,
        },
        CANCELED: {
            label: 'Cancelada',
            color: palette.status.rejected,
        },
        CONCLUDED: {
            label: 'Concluída',
            color: palette.status.accepted,
        },
    };

    return (
        <Box sx={{ ...css.box.wrapper.content }}
            className='wallet-extract-item'>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ ...css.box.items }}>
                    <Box sx={{ ...css.box.icon }}>
                        <I icon={typeIcon[type]} style={{ ...css.icon }} />
                    </Box>
                    <Box sx={{ ...css.box.texts }}>
                        <Box>
                            <Typography
                                component="p"
                                sx={{ ...css.texts.legend }}>
                                Solicitação em {formatUpdateDate(date)}
                            </Typography>
                            <Typography
                                component='p'
                                sx={{ ...css.texts.title }}>
                                {title}
                            </Typography>
                            <Stack
                                pt={0.3}
                                direction={'row'}
                                spacing={0.6}
                                color={handleStatus[status].color}
                                alignItems={'center'}>
                                <I
                                    icon={['fas', 'bookmark']}
                                    style={{ ...css.iconStatus }} />
                                <Typography sx={{ ...css.texts.status }}>
                                    {handleStatus[status].label}
                                </Typography>
                            </Stack>
                        </Box>
                        <Box sx={{ ...css.box.price }}>
                            <Price
                                type={handleCurrency[currency] as AdType}
                                value={value} />
                            {type === 'DEBIT' &&
                                <Box sx={{ ...css.box.overline }}></Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                className={`divider ${lastItem ? 'last-divider' : ''}`}
                sx={{ ...css.box.dividerV }} />
        </Box>
    );
};

export { WalletExtractTimeline, WalletExtractTimelineItem };

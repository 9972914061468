import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        wrapper: {
            border: '1px solid ' + palette.cityLights,
        },
        icon: {
            squarePlus: {
                fontSize: rem(24),
                marginRight: rem(10),
                color: palette.customBlack,
            },
        },
    };
    return { css };
};

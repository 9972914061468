import React from 'react';
import { LayoutContext } from '../../contexts/layout/context';
import { DEFAULTS } from './DEFAULTS';
import { MobileHeaderSettingsProps } from '@interfaces/Contexts';
import { client } from '@api';

export const layoutProvider = () => {
    const { services } = client();
    const [mobileConfig, setMobileConfig] = React.useState(DEFAULTS.mobile);
    const [carrousel, setCarrousel] = React.useState(
        DEFAULTS.banner.carrousel.list);
    const [loadingLayout, setLoadingLayout] = React.useState(false);

    const mobileHeaderSettings = ({ title, onBackAction }: MobileHeaderSettingsProps) => {
        setMobileConfig((prev) => prev = {
            ...prev,
            pageHeader: {
                title,
                onBackAction,
            },
        });
    };

    const loadCarrousel = async () => {
        setCarrousel(DEFAULTS.banner.carrousel.list);
        const response = await services.banners.load('CARROUSEL');

        setCarrousel((prev) => prev = {
            message: response?.response.message ?
                response?.response.message :
                '',
            isLoaded: true,
            data: response?.response.payload ?
                response?.response.payload : DEFAULTS.banner.carrousel.list.data,
        });
        return response;
    };

    const handleLoadingLayout = (loading: boolean) => {
        setLoadingLayout(loading);
    };

    const layout = {
        mobile: {
            header: {
                title: mobileConfig.pageHeader.title,
                onBackAction: mobileConfig.pageHeader.onBackAction,
                headerSettings: mobileHeaderSettings,
            },
        },
        banners: {
            carrousel: {
                load: loadCarrousel,
                list: carrousel,
            },
        },
        loading: {
            value: loadingLayout,
            handle: handleLoadingLayout,
        },
    };

    return { layout };
};

export const useLayout = () => {
    const context = React.useContext(LayoutContext);
    return context;
};


/* eslint-disable max-len */
import React from 'react';
import { AddressBox, HeaderPage, ListValues, MenuItem, Price, ProductInline, Template, AvatarGroup, MessageBox, Feedback } from '@components';
import { Box, Container, Grid, Stack, Button, Typography, Skeleton, AlertColor, CircularProgress } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { TitleSection } from './components/title-section';
import { sx } from './sx';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { files, formatAddress, styles } from '@utils';
import { ToggleStaticProps } from '@interfaces';
import { useUser } from '@hooks/user';
import { paymentMethodLabel, paymentMethods } from '@static/payment-methods';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { PaymentMethodType } from '@types';
import { Card, Coupon, Wallet } from './payment-methods';
import { useGlobal } from '@hooks/global';
import { useLayout } from '@hooks/layout';
import { ShippingInfo } from './components/shippingInfo';

const Checkout: React.FC = () => {
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();
    const user = useUser();
    const global = useGlobal();
    const navigate = useNavigate();
    const { layout } = useLayout();
    const [searchParams] = useSearchParams();

    const dialogEditAddress = React.useRef<ToggleStaticProps>(null);

    const [openSnack, setOpenSnack] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        isLoaded: false,
        type: 'success' as AlertColor,
        message: '',
    });

    const findAddress = () => {
        const addressId = searchParams.get('addressID') ?? '';
        const address = user.address.list?.data?.find((address) => address._id === addressId);
        if (address) return address;
        return user.address.list.data[0];
    };

    const onSubmit = async () => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoaded: true,
        });
        if (!user.purchase.paymentMethod.list ||
            (user.purchase.paymentMethod.list === 'CARD' && !user.purchase.creditCardForm.list.creditCardId)) {
            setResponseData({
                isLoaded: false,
                type: 'info',
                message: 'Selecione uma forma de pagamento para realizar a compra',
            });
            return setOpenSnack(true);
        }

        const response = await user.purchase.create();

        if (!response?.error) {
            await user.purchase.orders.load();
            user.cart.clear();
            user.purchase.paymentMethod.handleType(undefined);
            return navigate('/checkout/pedido-realizado', {
                state: {
                    paymentType: response?.response.payload.payment?.type,
                    billingLink: response?.response.payload.payment?.informations?.invoiceUrl,
                },
            });
        }
        setResponseData({
            isLoaded: false,
            type: response?.response?.type,
            message: response?.response.message,
        });
        setOpenSnack(true);
    };

    React.useEffect(() => {
        if (!user.address.list.isLoaded) {
            user.address.load();
        }
        if (!user.cart.list.isLoaded) {
            user.cart.load();
        }
    }, []);

    return (
        <Box sx={{ ...css.box.wrapper }}>
            <HeaderPage
                titleAlign={'default'}
                icon={['far', 'bag-shopping']}
                title={'Checkout'}
                action={!user.cart.list?.isLoaded && (
                    <Box px={2}>
                        <CircularProgress color='inherit' size={18} />
                    </ Box>
                )} />
            <Container sx={{ ...css.container }}>
                {!layout.loading.value && !user.cart.list?.data?.totalProducts && !!user.cart.list?.message && (
                    <Box py={'0.9375rem'}>
                        <MessageBox
                            type={'info'}
                            message={user.cart.list.status === 403 ? 'Faça login para visualizar o checkout' : 'Não foi possível encontrar o carrinho para prosseguir com o checkout'}
                        />
                        {user.cart.list.status === 403 &&
                            <Stack
                                direction='row'
                                spacing={2}
                                justifyContent='center'>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    size='large'
                                    onClick={() => navigate('/auth/login')}>
                                        Fazer login
                                </Button>
                            </Stack>
                        }
                    </Box>
                )}
                <Grid sx={{ ...css.grid }} container>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Box sx={{ ...css.box.wrapperSection }}>
                            <TitleSection name={'Produtos'} />
                            <Box px={2} pb={1}>
                                {!layout.loading.value && user.cart.list.data?.totalProducts > 0 ?
                                    user.cart.list.data.stores.map((store) => (
                                        <Box sx={{ ...css.box.wrapperStore }}>
                                            <Box sx={{ ...css.box.storeHeader }}>
                                                <AvatarGroup
                                                    align={'right'}
                                                    size={'tiny'}
                                                    borderWidth='none'
                                                    title={store.store.title}
                                                    src={files.load(store.store.images.avatar, 'server')} />
                                            </Box>
                                            <Stack divider={ <Box sx={{ ...css.box.divider }}></Box> }>
                                                {store.items.map((item, i) => (
                                                    <Box key={`${item.ad._id}-${i}`} p={1}>
                                                        <Link
                                                            style={{ textDecoration: 'none' }}
                                                            to={`/produto/${item.ad._id}?variation=${item.variation._id}&option=${item.variationItem._id}`}>

                                                            <ProductInline
                                                                srcImage={files.load(item?.variation?.images[0], 'server')}
                                                                title={item.ad.title}
                                                                valueType={global.app.view.type}
                                                                discount={item.variationItem.oldValue}
                                                                value={global.app.view.type === 'REDEMPTION' ? item.variationItem.pointValue : item.variationItem.actualValue}
                                                                cashback={global.app.view.type === 'SHOPPING' ? item.cashback : undefined} />
                                                        </Link>
                                                    </Box>
                                                ))}
                                            </Stack>
                                            <ShippingInfo name={store.carrierFreight.name} days={store.carrierFreight.delivery_time} />
                                        </Box>)) :
                                    <Skeleton variant='rectangular' height={!mediaQuery.sm ? 170 : 'calc(100vh - 312px)'} />
                                }
                            </Box>
                            {/* {mediaQuery.md &&
                                <Box sx={{ ...css.box.divider }}></Box>
                            } */}
                        </Box>
                        {!mediaQuery.md && <Box sx={{ ...css.box.dividerMobile }}></Box>}
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Box sx={{ ...css.box.dividerSection }}>
                            <Box sx={{ ...css.box.wrapperSection }}>
                                <TitleSection name={'Endereço de entrega'} />
                                <Box sx={{ ...css.box.address }}>
                                    {!layout.loading.value && user.cart.list.data?.totalProducts > 0 && user.address.list.isLoaded ?
                                        (user.cart.list.data?.totalProducts > 0 && Object.keys(findAddress()).length > 0 ?
                                            <AddressBox
                                                title={findAddress().zipCode}
                                                legend={formatAddress({
                                                    ...findAddress(),
                                                })}
                                                mode='edit'
                                                onClick={() => dialogEditAddress.current?.show()}
                                                addressTag={findAddress().nickname}
                                            /> :
                                            ''
                                        ) :
                                        <Skeleton variant='rectangular' height={!mediaQuery.sm ? 170 : 'calc(100vh - 312px)'} />
                                    }
                                </Box>
                            </Box>
                        </Box>
                        {!mediaQuery.md && <Box sx={{ ...css.box.dividerMobile }}></Box>}
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Box sx={{ ...css.box.wrapperSection }}>
                            <TitleSection name={'Resumo do pedido'} />
                            {!layout.loading.value && user.cart.list.isLoaded &&
                                user.cart.list.data?.stores?.length > 0 ? <>
                                    {global.app.view.type === 'REDEMPTION' ?
                                        <Stack
                                            sx={{ ...css.stack.listValues }}
                                            divider={ <Box sx={{ ...css.box.dividerResume }}></Box> }>
                                            <ListValues
                                                keyTitle={`Total dos produtos (${user.cart.list.data.totalProducts})`}
                                                value={<Price type={'REDEMPTION'} value={user.cart.list.data.redemption.totalPoints} />} />
                                            <ListValues
                                                keyTitle={'Pontos disponíveis'}
                                                value={<Price type={'REDEMPTION'} value={user.info.data.availableBalance} />} />
                                            {user.cart.list.data.redemption.totalPointsPurchased > 0 &&
                                                <ListValues
                                                    keyTitle={'Pontos restantes'}
                                                    value={<Price type={'REDEMPTION'} value={user.cart.list.data.redemption.totalPointsPurchased} />} />
                                            }
                                            {user.cart.list.data.redemption.valuePaidPointsPurchased > 0 &&
                                                <ListValues
                                                    keyTitle={'Valor dos pontos restantes'}
                                                    value={<Price type={'SHOPPING'} value={user.cart.list.data.redemption.valuePaidPointsPurchased} />} />
                                            }
                                        </Stack> :
                                        <Stack
                                            sx={{ ...css.stack.listValues }}
                                            divider={ <Box sx={{ ...css.box.dividerResume }}></Box> }>
                                            <ListValues
                                                keyTitle={`Total dos produtos (${user.cart.list.data.totalProducts || 0})`}
                                                value={<Price type={'SHOPPING'} value={user.cart.list.data.totalPartial} />} />
                                            <ListValues
                                                keyTitle={'Total de chashback'}
                                                value={<Price type={'REDEMPTION'} value={user.cart.list.data.totalCashback} />} />
                                        </Stack>}
                                    <Stack
                                        sx={{ ...css.stack.listValues2 }}
                                        divider={ <Box sx={{ ...css.box.dividerResume }}></Box> }>
                                        <ListValues
                                            keyTitle={'Frete'}
                                            value={<Price type={'SHOPPING'} value={user.cart.list.data.totalShipping} />} />
                                        <Wallet />
                                        <Coupon />
                                        <ListValues
                                            total
                                            keyTitle={'Total'}
                                            value={
                                                <Stack spacing={1} sx={{ ...css.box.total }}>
                                                    {global.app.view.type === 'REDEMPTION' && user.cart.list.data?.redemption?.totalPointsUsed > 0 &&
                                                    <Price type={'REDEMPTION'} value={user.cart.list.data?.redemption?.totalPointsUsed} />}
                                                    <Price type={'SHOPPING'} value={user.cart.list.data?.total} />
                                                </Stack>
                                            } />
                                    </Stack>
                                    <TitleSection name={user.purchase.paymentMethod.list ? paymentMethodLabel[user.purchase.paymentMethod.list].label : 'Pagamento'} />
                                    {(user.purchase.paymentMethod.list && user.purchase.paymentMethod.list !== 'CARD') &&
                                            <Stack sx={{ ...css.stack.paymentOptions }} spacing={1}>
                                                <Typography variant='caption'>
                                                    {paymentMethodLabel[user.purchase.paymentMethod.list].text}
                                                </Typography>
                                            </Stack>
                                    }
                                    {!user.purchase.paymentMethod.list &&
                                            <Stack sx={{ ...css.stack.paymentOptions }} spacing={1}>
                                                {paymentMethods.map(({ _id, icon, title, type }, i) => (
                                                    <MenuItem
                                                        key={_id}
                                                        leftChildren={
                                                            <I
                                                                style={{ ...css.icon.paymentMenu }}
                                                                icon={icon as IconProp} />
                                                        }
                                                        size={'medium'}
                                                        title={title}
                                                        onClick={() => user.purchase.paymentMethod.handleType(type as PaymentMethodType)} />
                                                ))}
                                            </Stack>
                                    }
                                    {user.purchase.paymentMethod.list === 'CARD' &&
                                            <Card />
                                    }
                                    {user.purchase.paymentMethod.list &&
                                            <Stack sx={{ ...css.stack.paymentOptions }} spacing={1}>
                                                <Button
                                                    fullWidth
                                                    disabled={responseData.isLoaded}
                                                    onClick={() => onSubmit()}
                                                    variant='contained'>
                                                    Comprar {responseData.isLoaded &&
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={14}
                                                        sx={{ ml: 1 }}
                                                    />}
                                                </Button>
                                                <Button
                                                    onClick={() => user.purchase.paymentMethod.handleType(undefined)}
                                                    fullWidth
                                                    variant='outlined'>
                                                    Alterar forma de pagamento
                                                </Button>
                                            </Stack>
                                    }
                                </> : <Skeleton variant='rectangular' height={!mediaQuery.sm ? 170 : 'calc(100vh - 312px)'} />
                            }
                            {!mediaQuery.md && <Box sx={{ ...css.box.dividerMobile }}></Box>}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Template.MuiDialog
                maxWidth='sm'
                fullScreen={!mediaQuery.md}
                title='Editar endereço de entrega'
                ref={dialogEditAddress}>
                <HeaderPage
                    titleAlign={'center'}
                    noPadding
                    icon={['far', 'chevron-left']}
                    onClick={() => dialogEditAddress.current?.hide()}
                    title={'Editar endereço de entrega'} />
                <Box p={2}>
                    <Template.Forms.Address
                        toCheckout
                        externalAction={() => {
                            user.address.load();
                            dialogEditAddress.current?.hide();
                        }}
                        addressID={searchParams.get('addressID') ?? user.address.list?.data[0]?._id} />
                </Box>
            </Template.MuiDialog>
            <Feedback.MuiSnackbar
                open={openSnack}
                severity={responseData.type}
                onClose={() => setOpenSnack(false)}
                message={responseData.message}
            />
        </Box>
    );
};

export { Checkout };

import { Template } from '@components';
import { Box, Button, Container, Stack, Typography, useTheme } from '@mui/material';
import { rem } from '@utils';
import { useNavigate } from 'react-router-dom';

const LoggedOut: React.FC = () => {
    const { typography } = useTheme();
    const navigate = useNavigate();

    return (
        <Box>
            <Container maxWidth='lg'>
                <Typography
                    variant="body2"
                    component='h1'
                    align='left'
                    fontSize={rem(14)}
                    color='darkGray'
                    whiteSpace='nowrap'
                    fontWeight={typography.fontWeightBold}
                    gutterBottom>
                    Acesse ou crie sua conta
                </Typography>
                <Stack direction='row' spacing={2} mt={rem(10)}>
                    <Button
                        type='button'
                        variant='outlined'
                        size='medium'
                        onClick={() => navigate('/auth/login')}>
                        Entrar
                    </Button>
                    <Button
                        type='button'
                        variant='contained'
                        size='medium'
                        onClick={() => navigate('/auth/criar-conta')}>
                        Criar Conta
                    </Button>
                </Stack>
                <Box mt={rem(28)}>
                    <Typography
                        variant='body2'
                        component='h1'
                        align='left'
                        fontSize={rem(14)}
                        color='darkGray'
                        whiteSpace='nowrap'
                        fontWeight={typography.fontWeightSemiBold}
                        gutterBottom
                        onClick={() => navigate('/resultados/parceiro')}>
                        Parceiros
                    </Typography>
                    <Template.Partners />
                </Box>
            </Container>
        </Box>
    );
};

export { LoggedOut };

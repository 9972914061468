/* eslint-disable no-unused-vars */
import { ThemeProvider } from '@mui/material';
import { theme } from './themes';
import './libs/fontawesome';
import './assets/css/index.scss';

import { browserRouters } from './routes';
import { RouterProvider } from 'react-router-dom';
import { LayoutProvider } from './contexts/layout/provider';
import { UserProvider } from './contexts/user/provider';
import { AdsProvider } from './contexts/ads/provider';
import { GlobalProvider } from './contexts/global/provider';
import { FiltersProvider } from './contexts/filters/provider';
import { CustomThemeProvider } from './contexts/custom-theme/provider';

export default function App() {
    const { router } = browserRouters();
    return (
        <CustomThemeProvider>
            <GlobalProvider>
                <LayoutProvider>
                    <UserProvider>
                        <AdsProvider>
                            <FiltersProvider>
                                <RouterProvider router={router} />
                            </FiltersProvider>
                        </AdsProvider>
                    </UserProvider>
                </LayoutProvider>
            </GlobalProvider>
        </CustomThemeProvider>
    );
}

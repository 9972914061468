import {
    AddressByZIPCodeProps,
    CardInfosProps,
    CardLoadProps,
    CartDataProps,
    ExtractDataProps,
    ListFavoritesDataProps,
    ListUserAddressProps,
    ListUserProps,
    MovementExpireProps,
    MovementHistoryProps,
    OrderProps,
} from '@interfaces/Services';
import { ItemDevolutionProps } from '@interfaces/index';

const RESPONSE = {
    isLoaded: false,
    status: 0,
    message: '',
};

export const DEFAULTS = {
    USER: {
        LIST: {
            ...RESPONSE,
            data: {} as ListUserProps,
        },
        ADDRESS_LIST: {
            ...RESPONSE,
            data: [] as ListUserAddressProps[],
        },
        LIST_ADDRESS_BY_ZIPCODE: {
            ...RESPONSE,
            data: {} as AddressByZIPCodeProps,
        },
        FAVORITES: {
            LIST: {
                ...RESPONSE,
                data: {
                    totalFavorites: 0,
                    favorites: [] as ListFavoritesDataProps[],
                },
            },
        },
        EXTRACT: {
            LIST: {
                ...RESPONSE,
                data: {} as ExtractDataProps,
            },
        },
        CART: {
            LIST: {
                ...RESPONSE,
                data: {} as CartDataProps,
            },
        },
        PAYMENT_FORM: {
            LIST: {
                ...RESPONSE,
                data: [] as CardLoadProps[],
            },
            CURRENT_DATA: {} as CardInfosProps,
        },
        WALLET: {
            WALLET_VALUE: {
                ...RESPONSE,
                error: true,
                data: {
                    money: 0,
                    availableBalance: 0,
                    pointsToRelease: 0,
                },
            },
            MOVEMENT_HISTORY: {
                ...RESPONSE,
                error: true,
                data: [] as MovementHistoryProps[],
            },
            MOVEMENT_EXPIRE: {
                ...RESPONSE,
                error: true,
                data: [] as MovementExpireProps[],
            },
        },
        PURCHASE: {
            ORDERS: {
                ...RESPONSE,
                error: true,
                data: [] as OrderProps[],
            },
            DEVOLUTION: {
                activeStep: 1,
                items: [] as ItemDevolutionProps[],
                orderId: '',
                reason: '',
                tagUrl: '',
            },
        },
    },
};

/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useFilters } from '@hooks/filters';
import { TempFilterProps } from '@interfaces/Filters';
import { MenuItem, MenuList, Paper, Typography, useTheme } from '@mui/material';
import { FILTERS_KEYS } from '@static/filters';
import { filterPageOptions } from '@static/menus';
import { FiltersType } from '@types';
import { rem } from '@utils';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

interface OrderByFilterListProps {
    _id: string;
    name: string;
    value: string;
    filterType: string;
};

interface OrderByFilterProps {
    onClick: () => void;
}

export const OrderBy = ({ onClick }: OrderByFilterProps) => {
    const { typography } = useTheme();
    const { handleFilters, filters } = useFilters();

    const [search, setSearch] = useSearchParams();
    const [tempOrderBy, setTempOrderBy] = React.useState<TempFilterProps[]>([]);
    const [value, setValue] = React.useState({} as OrderByFilterListProps);

    const handlePrice = (orderBy: string[]) => {
        if (orderBy.length === 0) {
            search.delete(FILTERS_KEYS.ORDER_BY);
        } else {
            search.set(FILTERS_KEYS.ORDER_BY, orderBy.join(','));
            // search.delete(FILTERS_KEYS.ALEATORY);
        }
        setSearch(search);
    };

    const handleOnChange = ({ ...props }: OrderByFilterListProps) => {
        return setValue((prev) => prev = { ...props });
    };

    const formatList = ({ ...props }: OrderByFilterListProps) => {
        const item = { ...props };
        const orderByFilterList = [
            {
                _id: item._id,
                name: item.value,
                filterType: FILTERS_KEYS.ORDER_BY,
            },
        ] as TempFilterProps[];
        const query = [`${item.value}|${item._id}`];
        handlePrice(query);
        handleFilters(FILTERS_KEYS.ORDER_BY as FiltersType, orderByFilterList);
    };

    React.useEffect(() => {
        setTempOrderBy(filters.orderBy);
    }, [filters.orderBy]);

    React.useEffect(() => {
        if (value._id) {
            formatList(value);
        }
    }, [value]);


    return (
        <Paper>
            <MenuList onClick={onClick}>
                {filterPageOptions.map((item, i) => (
                    <MenuItem
                        key={item._id}
                        onClick={() => handleOnChange(item)}>
                        <Typography
                            variant="inherit"
                            fontSize={rem(13)}
                            fontWeight={!(!!tempOrderBy.find((filter) => filter._id === item._id)) ?
                                typography.
                                    fontWeightSemiBold :
                                typography.fontWeightBold}>
                            {item.name}
                        </Typography>
                    </MenuItem>
                ))}
            </MenuList>
        </Paper>
    );
};

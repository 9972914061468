import { AvatarGroup } from '@components';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { rem } from '@utils';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import {
    usePopupState,
    bindHover,
    bindPopover,
} from 'material-ui-popup-state/hooks';
import React from 'react';

interface UserProps {
    username: string;
    avatar: string;
    balance: number;
    children: React.ReactNode;
}

const User: React.FC<UserProps> = (
    { username, avatar, balance, children }: UserProps,
) => {
    const { typography, palette } = useTheme();

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'userMenuPopover',
    });

    return (
        <React.Fragment>

            <Button
                type='button'
                variant='text'
                aria-owns={'menu'}
                aria-haspopup="true"
                sx={{ position: 'relative' }}
                {...bindHover(popupState)}>
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: 'transparent',
                    opacity: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 1,
                }}/>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent={'space-between'}
                    minWidth={rem(200)}
                    sx={{ cursor: 'pointer' }}>
                    <Box>
                        <Stack
                            direction='row'
                            alignItems='center'
                            spacing={1}>
                            <AvatarGroup
                                borderWidth='none'
                                align={'bottom'}
                                size={'tiny'}
                                src={avatar}
                            />
                            <Box>
                                <Typography
                                    component='h1'
                                    fontSize={rem(14)}
                                    textTransform='initial'
                                    align='left'
                                    color='darkGray'
                                    fontWeight={typography.fontWeightBold}>
                                    {username}
                                </Typography>
                                <Stack direction='row' spacing={0.5}>
                                    <Typography
                                        component='h5'
                                        fontSize={rem(12)}
                                        textTransform='initial'
                                        align='left'
                                        color='darkGray'
                                        fontWeight={
                                            typography.fontWeightBold
                                        }>
                                        Saldo:
                                    </Typography>
                                    <Typography
                                        component='h5'
                                        fontSize={rem(12)}
                                        textTransform='initial'
                                        align='left'
                                        color='darkGray'
                                        fontWeight={typography.fontWeightMedium}>
                                        {balance} Pontos
                                    </Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                    <I
                        icon={['fas', 'chevron-down']}
                        color={palette.darkGray}
                        fontSize={rem(12)}
                    />
                </Stack>
            </Button>

            <HoverPopover
                {...bindPopover(popupState)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {children}
            </HoverPopover>
        </React.Fragment>
    );
};

export { User };

import { AddressBox } from '@components';
import { useUser } from '@hooks/user';
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Container,
    Typography,
    useTheme,
} from '@mui/material';
import { formatAddress, rem } from '@utils';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { ListUserAddressProps } from '@interfaces/Services';
import { useSearchParams } from 'react-router-dom';

export const List: React.FC = () => {
    const { typography } = useTheme();
    const navigate = useNavigate();
    const user = useUser();

    const [searchParams, setSearchParams] = useSearchParams();
    const [addressID, setAddressID] = React.useState('');
    const [responseData, setResponseData] = React.useState({
        isLoading: false,
        type: 'success' as AlertColor,
        message: '',
    });

    const loadUserAddressList = async () => {
        await user.address.load();
        setAddressID(user.address.list.data[0]?._id);
    };

    const handleAddress = (address: ListUserAddressProps) => {
        if (address._id) {
            setAddressID(address._id);
            setSearchParams((params) => {
                params.set('addressID', address._id);
                return params;
            });
        }
    };

    const confirmAddress = async () => {
        const addressSelected =
        user.address.list.data.find((address) => address._id === addressID) ||
            user.address.list.data[0];

        if (addressSelected?.main === false) {
            setResponseData((prev) => prev = {
                ...prev,
                isLoading: true,
            });
            const response = await user.address.handle({
                addressId: addressID || user.address.list.data[0]?._id,
                address: {
                    ...addressSelected,
                    main: true,
                },
            });

            if (!response?.error) {
                await user.cart.load();
                setResponseData({
                    isLoading: false,
                    type: response?.response.type,
                    message: response?.response.message,
                });
                return navigate(`/checkout/pagamento${addressID ?
                    '?addressID=' + addressID :
                    ''}`);
            }
            setResponseData({
                isLoading: false,
                type: response?.response.type,
                message: response?.response.message,
            });
        } else {
            return navigate(`/checkout/pagamento${addressID ?
                '?addressID=' + addressID :
                '?addressID=' + addressSelected._id}`);
        }
    };

    React.useEffect(() => {
        if (!user.address.list.isLoaded) {
            loadUserAddressList();
        }
        const urlAddressIDParam = searchParams.get('addressID');
        if (urlAddressIDParam) {
            setAddressID(urlAddressIDParam);
        }
    }, []);

    console.log(addressID);

    return (
        <Box pt={rem(30)}>
            <Container maxWidth='xs'>
                <Box mb={rem(20)}>
                    <Typography
                        variant="body2"
                        component='h1'
                        fontSize={rem(18)}
                        color='darkGray'
                        fontWeight={typography.fontWeightBold}
                        gutterBottom>
                        Endereço de entrega
                    </Typography>
                    <Typography
                        variant="body2"
                        component='h1'
                        fontSize={rem(14)}
                        color='darkGray'
                        fontWeight={typography.fontWeightMedium}>
                        Confirme seu endereço de entrega.
                    </Typography>
                </Box>
                <Box mt={rem(15)} mb={rem(15)}>
                    {user.address.list.isLoaded ? (
                        user.address.list.data.length > 0 &&
                        user.address.list.data.map((address, i, list) => (
                            list.length !== i && (
                                <Box mt={rem(10)}>
                                    <AddressBox
                                        title={address.zipCode}
                                        legend={formatAddress({
                                            ...address,
                                        })}
                                        mode='edit'
                                        noIcon
                                        radio={{
                                            checked: (!!addressID &&
                                                addressID === address._id),
                                            id: address._id,
                                            name: 'selected_address',
                                            onChange: () => handleAddress(address),
                                        }}
                                        addressTag={`${address.nickname}
                                        ${address.main ? ' - Principal' : ''}`}
                                    />
                                </Box>
                            )
                        ))) : (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 2,
                        }}>
                            <CircularProgress
                                color="inherit"
                                size={28}
                                sx={{ ml: 1 }}
                            />
                        </Box>
                    )}
                    <Box mt={rem(10)}>
                        <AddressBox
                            title='Adicionar endereço'
                            legend='Clique para adicionar um novo endereço'
                            mode={'add'}
                            borderType='dashed'
                            hideLocationIcon
                            onClick={() => navigate('/checkout/endereco/adicionar')} />
                    </Box>
                </Box>

                <Box my={rem(20)}>
                    <Button
                        fullWidth
                        type='button'
                        variant='contained'
                        size='large'
                        disabled={
                            responseData.isLoading ||
                            !user.address.list.isLoaded ||
                            addressID === '' ||
                            addressID === undefined}
                        onClick={() => confirmAddress()}>
                        Continuar
                        {responseData.isLoading &&
                            <CircularProgress
                                color="inherit"
                                size={14}
                                sx={{ ml: 1 }}
                            />}
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { AlertColor, Box, Button, CircularProgress, Container, Grid, IconButton, Stack, Typography } from '@mui/material';
import { AvatarGroup, BoxContent, ControllQuantity, Feedback, HeaderPage, ListValues, MessageBox, Price, ProductInline, Template, Image, VariationItem } from '@components';
import { sx } from './sx';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { skeleton } from './skeleton';
import { useUser } from '@hooks/user';
import { files, styles } from '@utils';
import { AddItemDataProps } from '@interfaces/Services';
import { ResponseAsyncProps } from '@interfaces/ClientServicesApi';
import { SelectedAdItemProps, ToggleStaticProps } from '@interfaces/index';
import { Item } from './styles';
import { useAds } from '@hooks/ads';
import { useGlobal } from '@hooks/global';
import { useLayout } from '@hooks/layout';

interface ChangeItemProps {
    action: 'add' | 'remove' | 'delete' | 'modal';
    item: AddItemDataProps;
};

const Cart: React.FC = () => {
    const navigate = useNavigate();
    const { cart, info } = useUser();
    const { ads } = useAds();
    const global = useGlobal();
    const { layout } = useLayout();

    const { css } = sx({
        viewType: global.app.view.type,
    });
    const skel = skeleton();
    const mediaQuery = styles.mediaQueryCSS();

    const dialogChangesItem = React.useRef<ToggleStaticProps>(null);

    const [loadingChanges, setLoadingChanges] = React.useState(false);
    const [selectedAdItem, setSelectedAdItem] = React.useState(
        {} as SelectedAdItemProps,
    );
    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        id: '',
        isLoadedModal: true,
        isLoadedQuantity: true,
        isLoadedDelete: true,
        type: 'success' as AlertColor,
        message: '',
    });

    const handleClose = () => setOpen(false);

    const handleOpenChangeModal = async (item: SelectedAdItemProps) => {
        setSelectedAdItem(item);
        if (ads.byId.item.isLoaded && ads.byId.item.data._id === item.idAd) {
            dialogChangesItem.current?.show();
        } else {
            setResponseData((prev) => prev = {
                ...prev,
                id: item.variationItemId,
                isLoadedModal: false,
            });
            await ads.byId.load({
                idAd: item.idAd,
            });
            dialogChangesItem.current?.show();
            setResponseData((prev) => prev = {
                ...prev,
                id: '',
                isLoadedModal: true,
            });
        }
    };

    const imageVariation = () => {
        const findItem = ads.byId.item.isLoaded && ads.byId.item.data?.variations?.find((variation) => variation._id === selectedAdItem.idVariation);
        if (findItem) {
            return files.load(findItem.images[0], 'server');
        } else {
            return files.load('', 'local');
        }
    };

    const changeItem = async ({ action, item }: ChangeItemProps) => {
        setLoadingChanges(true);
        setResponseData((prev) => prev = {
            ...prev,
            id: item.variationItemId,
            isLoadedModal: !(action === 'modal'),
            isLoadedDelete: !(action === 'delete'),
            isLoadedQuantity: !(!(action !== 'remove') || !(action !== 'add')),
        });
        let response = {} as ResponseAsyncProps | undefined;
        switch (action) {
        case 'add':
            response = await cart.handleItem.add(item);
            break;
        case 'modal':
            response = await cart.handleItem.add(item);
            break;
        case 'remove':
            response = await cart.handleItem.add(item);
            break;
        case 'delete':
            response = await cart.handleItem.delete(item);
            break;
        }
        if (!response?.error) {
            setResponseData({
                type: 'success',
                id: '',
                message: response?.response.message,
                isLoadedDelete: true,
                isLoadedModal: true,
                isLoadedQuantity: true,
            });
            setOpen(true);
            dialogChangesItem.current?.hide();
            setTimeout(() => {
                handleClose();
            }, 3000);
            await cart.load();
            setLoadingChanges(false);
        } else {
            setResponseData({
                id: '',
                type: response?.response?.type,
                message: response?.response?.message,
                isLoadedDelete: true,
                isLoadedModal: true,
                isLoadedQuantity: true,
            });
            setOpen(true);
            setLoadingChanges(false);
            setTimeout(() => {
                handleClose();
            }, 1500);
        }
    };

    const loadCart = async () => {
        if (!cart.list.isLoaded) {
            await cart.load();
        }
    };

    React.useEffect(() => {
        loadCart();
    }, []);

    return (
        <Box sx={{ ...css.box.wrapper }}>
            <Box>
                <HeaderPage
                    titleAlign={'default'}
                    icon={['far', 'cart-shopping']}
                    title={'Meu carrinho'}
                    action={loadingChanges && (
                        <Box px={2}>
                            <CircularProgress color='inherit' size={18} />
                        </ Box>
                    )} />
            </Box>
            <Container sx={{ ...css.container }}>
                <Grid container spacing={2}>
                    <Grid p={0} item lg={8} md={12} sm={12} xs={12}>
                        <Stack
                            spacing={2}
                            sx={{ ...css.stack.items }}>
                            {!cart.list.isLoaded && cart.list.status === 403 && (
                                <Box py={'0.9375rem'}>
                                    <MessageBox
                                        type={'info'}
                                        message='Faça o login para ver seu carrinho'
                                    />
                                    <Stack
                                        direction='row'
                                        spacing={2}
                                        justifyContent='center'>
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            size='large'
                                            onClick={() => navigate('/auth/login')}>
                                            Fazer login
                                        </Button>
                                    </Stack>
                                </Box>
                            )}
                            {cart.list.isLoaded && !layout.loading.value ?
                                (cart.list.data?.totalProducts > 0 ?
                                    cart.list.data.stores.map((store) => (
                                        <Box sx={{ ...css.box.wrapperStore }}>
                                            <Box sx={{ ...css.box.storeHeader }}>
                                                <AvatarGroup
                                                    align={'right'}
                                                    size={'tiny'}
                                                    borderWidth='none'
                                                    title={store.store.title}
                                                    src={files.load(store.store.images.avatar, 'server')} />
                                            </Box>
                                            <Stack divider={ <Box sx={{ ...css.box.divider }}></Box> }>
                                                {store.items.map((item, i) => (
                                                    <BoxContent
                                                        key={`${item.ad._id}-${i}`}
                                                        actions={[
                                                            {
                                                                actionType: 'change',
                                                                actionFunction: () => handleOpenChangeModal({
                                                                    idAd: item.ad._id,
                                                                    idVariation: item.variation._id,
                                                                    variationItemId: item.variationItem._id,
                                                                    quantity: item.quantity,
                                                                    replaceItem: i + 1,
                                                                    stockQuantity: item.variationItem.stockQuantity,
                                                                    colorNameVariation: item.variation.colorName,
                                                                }),
                                                                loading: responseData.id === item.variationItem._id && !responseData.isLoadedModal,
                                                            },
                                                            {
                                                                actionType: 'delete',
                                                                actionFunction: async () => {
                                                                    await changeItem({
                                                                        action: 'delete',
                                                                        item: {
                                                                            idAd: item.ad._id,
                                                                            idVariation: item.variation._id,
                                                                            variationItemId: item.variationItem._id,
                                                                            quantity: item.quantity,
                                                                        },
                                                                    });
                                                                },
                                                                loading: responseData.id === item.variationItem._id && !responseData.isLoadedDelete,
                                                            },
                                                        ]}>
                                                        <Grid p={0} container>
                                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                <Link
                                                                    style={{ textDecoration: 'none' }}
                                                                    to={`/produto/${item.ad._id}?variation=${item.variation._id}&option=${item.variationItem._id}`}>

                                                                    <ProductInline
                                                                        srcImage={files.load(item?.variation?.images[0], 'server')}
                                                                        title={item.ad.title}
                                                                        // srcLogoPartner='https://cdn.discordapp.com/attachments/1044241266134745202/1171126350803902474/magalu.png?ex=6564c5e8&is=655250e8&hm=47b9d1ca21056daa55961a31c4b179d8a11f66879bcfc27f7e0d89acadff6a8e&'
                                                                        valueType={global.app.view.type}
                                                                        discount={item.variationItem.oldValue}
                                                                        value={global.app.view.type === 'REDEMPTION' ? item.variationItem.pointValue : item.variationItem.actualValue}
                                                                        cashback={global.app.view.type === 'SHOPPING' ? item.cashback : undefined} />
                                                                </Link>
                                                            </Grid>
                                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                                <Box sx={{ ...css.box.quantity }}>
                                                                    {!responseData.isLoadedQuantity && responseData.id === item.variationItem._id ?
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            width: '132px',
                                                                            justifyContent: 'center',
                                                                            py: 2,
                                                                        }}>
                                                                            <CircularProgress color='inherit' size={18} />
                                                                        </Box> :
                                                                        <ControllQuantity
                                                                            label
                                                                            value={item.quantity}
                                                                            limit={item.variationItem.stockQuantity}
                                                                            remove={() =>
                                                                                setTimeout(async () => {
                                                                                    await changeItem({
                                                                                        action: 'remove',
                                                                                        item: {
                                                                                            idAd: item.ad._id,
                                                                                            idVariation: item.variation._id,
                                                                                            variationItemId: item.variationItem._id,
                                                                                            quantity: item.quantity - 1,
                                                                                            replaceItem: i + 1,
                                                                                        },
                                                                                    });
                                                                                }, 0)
                                                                            }
                                                                            add={() => {
                                                                                changeItem({
                                                                                    action: 'add',
                                                                                    item: {
                                                                                        idAd: item.ad._id,
                                                                                        idVariation: item.variation._id,
                                                                                        variationItemId: item.variationItem._id,
                                                                                        quantity: item.quantity + 1,
                                                                                        replaceItem: i + 1,
                                                                                    },
                                                                                });
                                                                            }} />
                                                                    }
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </BoxContent>
                                                ))}
                                            </Stack>
                                        </Box>
                                    )) :
                                    <Box py={'0.9375rem'}>
                                        <MessageBox
                                            message={cart.list.message}
                                            type={'info'} />
                                    </Box>) :
                                <>
                                    {skel.storeInfo()}
                                    { [0, 1].map(() => (skel.boxContentProduct))}
                                </>
                            }
                        </Stack>
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Box sx={{ ...css.box.resumeValues }}>
                            {!layout.loading.value && cart.list.isLoaded && cart.list.data?.totalProducts > 0 ?
                                <>
                                    {global.app.view.type === 'REDEMPTION' ? <>
                                        <ListValues
                                            keyTitle={`Total dos produtos (${cart.list.data.totalProducts})`}
                                            value={<Price type={'REDEMPTION'} value={cart.list.data.redemption.totalPoints} />} />
                                        <ListValues
                                            keyTitle={'Pontos disponíveis'}
                                            value={<Price type={'REDEMPTION'} value={info.data.availableBalance} />} />
                                        {cart.list.data.redemption.totalPointsPurchased > 0 &&
                                            <ListValues
                                                keyTitle={'Pontos restantes'}
                                                value={<Price type={'REDEMPTION'} value={cart.list.data.redemption.totalPointsPurchased} />} />
                                        }
                                        {cart.list.data.redemption.valuePaidPointsPurchased > 0 &&
                                            <ListValues
                                                keyTitle={'Valor dos pontos restantes'}
                                                value={<Price type={'SHOPPING'} value={cart.list.data.redemption.valuePaidPointsPurchased} />} />
                                        }
                                        <ListValues
                                            keyTitle={'Frete'}
                                            value={<Price type={'SHOPPING'} value={cart.list.data.totalShipping} />} />
                                        <ListValues
                                            total
                                            keyTitle={'Total'}
                                            value={
                                                <Stack spacing={1} sx={{ ...css.box.total }}>
                                                    {cart.list.data.redemption.totalPointsUsed > 0 &&
                                                        <Price type={'REDEMPTION'} value={cart.list.data.redemption.totalPointsUsed} />
                                                    }
                                                    {cart.list.data.total > 0 &&
                                                        <Price type={'SHOPPING'} value={cart.list.data.total} />
                                                    }
                                                </Stack>
                                            } />
                                    </> :
                                        <>
                                            <ListValues
                                                keyTitle={`Total dos produtos (${cart.list.data.totalProducts})`}
                                                value={<Price type={'SHOPPING'} value={cart.list.data.totalPartial} />} />
                                            <ListValues
                                                keyTitle={'Total de chashback'}
                                                value={<Price type={'REDEMPTION'} value={cart.list.data.totalCashback} />} />
                                            <ListValues
                                                keyTitle={'Frete'}
                                                value={<Price type={'SHOPPING'} value={cart.list.data.totalShipping} />} />
                                            <ListValues
                                                total
                                                keyTitle={'Total'}
                                                value={
                                                    <Box sx={{ ...css.box.total }}>
                                                        <Price type={'SHOPPING'} value={cart.list.data.total} />
                                                    </Box>
                                                } />
                                        </> }
                                    <Box sx={{ ...css.box.continue }}>
                                        <Link to={'/checkout/endereco'}>
                                            <Button
                                                fullWidth
                                                variant='contained'>
                                                Continuar
                                            </Button>
                                        </Link>
                                    </Box> </> :
                                skel.resumeCart
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Template.MuiDialog
                maxWidth='sm'
                showDialogTitle={!mediaQuery.md}
                title='Escolha os detalhes deste produto'
                fullScreen={!mediaQuery.md}
                ref={dialogChangesItem}>
                <Box sx={{ ...css.modal.box.wrapper }}>
                    {ads.byId.item.isLoaded ?
                        <>
                            <Box sx={{ ...css.modal.box.col1 }}>
                                <Box sx={{ ...css.modal.box.itemPicture }}>
                                    <Image
                                        src={imageVariation()}
                                        alt={ads.byId.item.data.title}
                                        objectFit='contain'
                                        maxHeight={ css.modal.image } />
                                    <Box sx={{ ...css.modal.box.itemTexts }}>
                                        <Typography
                                            variant="body1"
                                            fontWeight="bold"
                                            fontSize={16}>
                                            {ads.byId.item.data.title}
                                        </Typography>
                                        <Price
                                            type={global.app.view.type}
                                            size={'medium'}
                                            discount={ads.byId.item.data.variations.find((variation) => variation._id === selectedAdItem.idVariation)?.items.find((item) => item._id === selectedAdItem.variationItemId)?.oldValue}
                                            value={global.app.view.type === 'REDEMPTION' ? Number(ads.byId.item.data.variations.find((variation) => variation._id === selectedAdItem.idVariation)?.items.find((item) => item._id === selectedAdItem.variationItemId)?.pointValue) : Number(ads.byId.item.data.variations.find((variation) => variation._id === selectedAdItem.idVariation)?.items.find((item) => item._id === selectedAdItem.variationItemId)?.actualValue)} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ ...css.modal.box.col2 }}>
                                {mediaQuery.md && (
                                    <>
                                        <IconButton
                                            onClick={() => dialogChangesItem.current?.hide()}
                                            aria-label="close"
                                            sx={{ ...css.modal.iconButton.close }}>
                                            <I icon={['far', 'times']} />
                                        </IconButton>
                                        <Typography variant='h4' sx={{ ...css.modal.title }} >
                                            Escolha os detalhes deste produto
                                        </Typography>
                                    </>
                                )}
                                <Stack mb={2} spacing={1}>
                                    <Stack spacing={1} direction={'row'}>
                                        <Typography
                                            variant="body2"
                                            sx={{ ...css.modal.titleSection }}>
                                                Variação:
                                        </Typography>
                                        <Typography
                                            variant="body2">
                                            {selectedAdItem.colorNameVariation}
                                        </Typography>
                                    </Stack>
                                    <Stack spacing={1} direction={'row'}>
                                        {ads.byId.item.data.variations.map((variation) => (
                                            <VariationItem
                                                onClick={() => setSelectedAdItem((prev) => prev = {
                                                    ...prev,
                                                    idAd: ads.byId.item.data._id,
                                                    idVariation: variation._id,
                                                    quantity: 1,
                                                    variationItemId: variation?.items[0]?._id,
                                                    stockQuantity: variation.items[0].stockQuantity,
                                                    colorNameVariation: variation.colorName,
                                                })}
                                                active={variation._id === selectedAdItem.idVariation}
                                                srcImage={files.load(variation.images[0], 'server')} />
                                        ))}
                                    </Stack>
                                </Stack>
                                <Stack mb={2} spacing={1}>
                                    <Typography
                                        variant="body2"
                                        sx={{ ...css.modal.titleSection }}>
                                            Opções:
                                    </Typography>
                                    <Stack direction={'row'} spacing={1}>
                                        {ads.byId.item.data.variations.find((variation) => variation._id === selectedAdItem.idVariation)?.
                                            items.map((item, i) => (
                                                <Item
                                                    key={`item-var-${i}`}
                                                    onClick={() => setSelectedAdItem(
                                                        (prev) => prev = {
                                                            ...prev,
                                                            quantity: 1,
                                                            variationItemId: item._id,
                                                            stockQuantity: item.stockQuantity,
                                                        },
                                                    )}
                                                    className={selectedAdItem.variationItemId === item._id ? 'active' : ''}>
                                                    <label>
                                                        {item.name}
                                                    </label>
                                                </Item>
                                            ))}
                                    </Stack>
                                </Stack>
                                {selectedAdItem.stockQuantity > 0 ?
                                    <Stack mb={2} spacing={1}>
                                        <Typography
                                            variant="body2"
                                            sx={{ ...css.modal.titleSection }}>
                                            Quantidade:
                                        </Typography>
                                        <ControllQuantity
                                            value={selectedAdItem.quantity}
                                            limit={selectedAdItem.stockQuantity}
                                            remove={() => setSelectedAdItem(
                                                (prev) => prev = {
                                                    ...prev, quantity: prev.quantity - 1,
                                                },
                                            )}
                                            add={() => setSelectedAdItem(
                                                (prev) => prev = {
                                                    ...prev, quantity: prev.quantity + 1,
                                                },
                                            )} />
                                    </Stack> :
                                    <Stack mb={2}>
                                        <Typography
                                            sx={{ ...css.modal.noStockText }}
                                            variant='caption'>
                                            <I
                                                style={{ marginRight: '5px' }}
                                                icon={['far', 'circle-info']} />
                                            Item sem estoque no momento
                                        </Typography>
                                    </Stack>
                                }
                                <Button
                                    onClick={() => changeItem({
                                        action: 'modal',
                                        item: selectedAdItem,
                                    })}
                                    disabled={!responseData.isLoadedModal || selectedAdItem.stockQuantity === 0}
                                    fullWidth
                                    variant="contained"
                                    size="large">
                                    Atualizar {!responseData.isLoadedModal &&
                                        <CircularProgress
                                            color="inherit"
                                            size={14}
                                            sx={{ ml: 1 }}
                                        />}
                                </Button>
                            </Box>
                        </> : 'Carregando...'
                    }
                </Box>
            </Template.MuiDialog>
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box>
    );
};

export { Cart };

import { FiltersType } from '@types';
import { ResultsFiltersList } from '../filters';
import { filterPageOptions } from './menus';

export const orderByFilterTranslate = {
    [filterPageOptions[0]._id]: 'Preço crescente',
    [filterPageOptions[1]._id]: 'Preço decrescente',
};

export const translateFilters = {
    orderBy: {
        title: 'Ordenar',
    },
    valueRange: {
        title: 'Preço',
    },
    categoryList: {
        title: 'Categoria',
    },
    size: {
        title: 'Tamanho',
    },
    storeUrl: {
        title: 'Parceiro',
    },
};

export const filtersList = [
    {
        _id: 'e1b6fb374cad',
        name: 'Preço',
        filter: 'value' as FiltersType,
        filterContent: <ResultsFiltersList.Price />,
    },
    {
        _id: 'be5e10b336e4',
        name: 'Categorias',
        filter: 'categoryList' as FiltersType,
        filterContent: <ResultsFiltersList.Categories />,
    },
    {
        _id: 'f65185e93724',
        name: 'Tamanhos',
        filter: 'size' as FiltersType,
        filterContent: <ResultsFiltersList.Sizes />,
    },
];

export const resultsFilters = [
    {
        _id: '7ff62e201525',
        title: 'Categoria/Fornecedor',
        children: [
            {
                _id: '7b48a47992dd',
                title: 'FAST SHOP',
                count: 239,
                children: [
                    {
                        _id: 'f4fbc214ca74',
                        title: 'Smartphones',
                        count: 238,
                    },
                    {
                        _id: '0c14fbad96fc',
                        title: 'Informática E Digitais',
                        count: 1,
                    },
                ],
            },
            {
                _id: 'c5b6e8462db6',
                title: 'Magazine Luiza',
                count: 164,
                children: [
                    {
                        _id: 'e674fe684146',
                        title: 'Celulares e Smartphones',
                        count: 164,
                    },
                ],
            },
            {
                _id: 'e6e7ac3582ed',
                title: 'Extra',
                count: 111,
                children: [
                    {
                        _id: '853319c0a47b',
                        title: 'Telefones e Celulares',
                        count: 107,
                    },
                    {
                        _id: '918171f1ce67',
                        title: 'Acessórios e Inovações',
                        count: 4,
                    },
                ],
            },
            {
                _id: 'd6c729d9986b',
                title: 'Ponto Frio',
                count: 104,
                children: [
                    {
                        _id: 'f00f24720720',
                        title: 'Telefones e Celulares',
                        count: 100,
                    },
                    {
                        _id: '50eb02d04ec0',
                        title: 'Acessórios e Inovações',
                        count: 4,
                    },
                ],
            },
            {
                _id: '82f3e6043987',
                title: 'Casas Bahia',
                count: 103,
                children: [
                    {
                        _id: 'b1524c4eb8fd',
                        title: 'Telefones e Celulares',
                        count: 99,
                    },
                    {
                        _id: 'df5aa283eeed',
                        title: 'Acessórios e Inovações',
                        count: 4,
                    },
                ],
            },
        ],
    },
    {
        _id: '31df58bf867b',
        title: 'Preço',
        children: [
            {
                _id: 'b4a30a00cbb8',
                title: 'R$ 0 a R$ 550',
                count: 138,
            },
            {
                _id: 'd9ad45241556',
                title: 'R$ 550 a R$ 4760',
                count: 169,
            },
            {
                _id: 'e12801b70b6f',
                title: 'R$ 4790 a R$ 8170',
                count: 222,
            },
            {
                _id: 'f0079bfe79b4',
                title: 'R$ 8180 a R$ 15990',
                count: 200,
            },
            {
                _id: 'c136bedabbc5',
                title: 'R$ 16550 a R$ 247840',
                count: 1,
            },
            {
                _id: '341c3a3b53b4',
                title: 'R$ 16550 a R$ 247840',
                count: 0,
            },
        ],
    },
    {
        _id: '',
        title: 'Cor',
        children: [
            {
                _id: '',
                title: 'Preto',
                count: 97,
            },
            {
                _id: '',
                title: 'Branco',
                count: 40,
            },
            {
                _id: '',
                title: 'Azul',
                count: 36,
            },
            {
                _id: '',
                title: 'Rosa',
                count: 26,
            },
            {
                _id: '',
                title: 'Roxo',
                count: 24,
            },
            {
                _id: '',
                title: 'Prata',
                count: 20,
            },
            {
                _id: '',
                title: 'Verde',
                count: 18,
            },
            {
                _id: '',
                title: 'Vermelho',
                count: 17,
            },
            {
                _id: '',
                title: 'Amarelo',
                count: 15,
            },
            {
                _id: '',
                title: 'Transparente',
                count: 6,
            },
        ],
    },
    {
        _id: '',
        title: 'Tamanho',
        children: [
            {
                _id: 'b80dc5dc29b1',
                title: 'Único',
                count: 7,
            },
            {
                _id: 'eb302c107c3b',
                title: 'Loja',
                children: [
                    {
                        _id: '8ad413c478a4',
                        title: 'Casas bahia',
                        count: 103,
                    },
                    {
                        _id: '97a08e327ba3',
                        title: 'Magazine luiza',
                        count: 164,
                    },
                    {
                        _id: 'ebf55f785edf',
                        title: 'Ponto frio',
                        count: 104,
                    },
                    {
                        _id: 'b1e97b69be0b',
                        title: 'Netshoes',
                        count: 6,
                    },
                    {
                        _id: '3d759250f5c9',
                        title: 'Extra',
                        count: 111,
                    },
                    {
                        _id: 'dcc65872624f',
                        title: 'Fast shop',
                        count: 239,
                    },
                    {
                        _id: 'c982c71b1b9f',
                        title: 'Zattini',
                        count: 1,
                    },
                ],
            },
        ],
    },
];

export const FILTERS_KEYS = {
    SIZE: 'size',
    CATEGORY_LIST: 'categoryList',
    VALUE: 'valueRange',
    ORDER_BY: 'orderBy',
    ALEATORY: 'aleatory',
    LIMIT: 'limit',
    STORE_URL: 'storeUrl',
};

import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { typography } = useTheme();
    const css = {
        box: {
            wrapper: {
                default: {
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                },
                tiny: {
                    padding: `${rem(10)} ${rem(15)}`,
                    alignItems: 'center',
                },
                small: {
                    padding: `${rem(12)} ${rem(15)}`,
                },
                medium: {
                    border: `${rem(1)} solid #ECF0F1`,
                    padding: `${rem(20)} ${rem(15)}`,
                    alignItems: 'center',
                },
            },
        },
        texts: {
            title: {
                tiny: {
                    fontSize: rem(14),
                    fontWeight: typography.fontWeightMedium,
                },
                small: {
                    fontSize: rem(16),
                    fontWeight: typography.fontWeightMedium,
                },
                medium: {
                    fontSize: rem(14),
                    fontWeight: typography.fontWeightBold,
                },
            },
            legend: {
                tiny: {
                    fontSize: rem(12),
                    fontWeight: typography.fontWeightMedium,
                },
                small: {
                    fontSize: rem(14),
                    fontWeight: typography.fontWeightMedium,
                },
                medium: {
                    fontSize: rem(12),
                    fontWeight: typography.fontWeightMedium,
                },
            },
        },
        icon: {
            tiny: {
                fontSize: rem(14),
            },
            small: {
                fontSize: rem(16),
            },
            medium: {
                fontSize: rem(18),
            },
        },
    };

    return { css };
};

export const handleFontSize = {
    left: {
        keyTab: '0.875rem',
        value: '0.875rem',
    },
    right: {
        keyTab: '0.875rem',
        value: '.875rem',
    },
    between: {
        keyTab: '0.875rem',
        value: '0.875rem',
    },
};

export const routes = {
    auth: {
        createAccount: {
            success: '/auth/criar-conta/sucesso',
        },
    },
    common: {
        results: {
            search: '/resultados/busca',
        },
    },
};

import React from 'react';
import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { OrderStatusTimelineItemProps, WalletExtractTimelineProps } from '@interfaces';

const OrderStatusTimeline = ({ children }: WalletExtractTimelineProps) => {
    const { css } = sx();
    return (
        <Box sx={{ ...css.box.wrapper.timeline }}>
            {children}
        </Box>
    );
};

const orderStatus = {
    AWAITING_PAYMENT: {
        title: 'Aguardando pagamento',
        icon: ['far', 'clock'] as IconProp,
    },
    PAID: {
        title: 'Pago',
        icon: ['far', 'dollar-sign'] as IconProp,
    },
    TAG_GENERATED: {
        title: 'Aguardando envio',
        icon: ['far', 'hourglass'] as IconProp,
    },
    POSTED: {
        title: 'Enviado',
        icon: ['far', 'truck-fast'] as IconProp,
    },
    FINISHED: {
        title: 'Entregue',
        icon: ['far', 'check'] as IconProp,
    },
    TAG_GENERATED_DEVOLUTION: {
        title: 'Aguardando seu envio',
        icon: ['far', 'hourglass'] as IconProp,
    },
    TAG_GENERATED_REFUSAL_DEVOLUTION: {
        title: 'Aguardando envio de volta',
        icon: ['far', 'hourglass'] as IconProp,
    },
    POSTED_DEVOLUTION: {
        title: 'Devolução enviada',
        icon: ['far', 'truck-fast'] as IconProp,
    },
    FINISHED_DEVOLUTION: {
        title: 'Devolução entregue',
        icon: ['far', 'check'] as IconProp,
    },
    POSTED_REFUSAL_DEVOLUTION: {
        title: 'Devolução enviada de volta',
        icon: ['far', 'truck-fast'] as IconProp,
    },
    FINISHED_REFUSAL_DEVOLUTION: {
        title: 'Recusa finalizada',
        icon: ['far', 'check'] as IconProp,
    },
};

const OrderStatusTimelineItem = ({
    status, date, time, active, actual,
}: OrderStatusTimelineItemProps) => {
    const { css } = sx(active, actual);
    return (
        <Box sx={{ ...css.box.wrapper.content }}
            className='wallet-extract-item'>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ ...css.box.items }}>
                    <Box sx={{ ...css.box.icon }}>
                        <I icon={orderStatus[status].icon} style={{ ...css.icon }} />
                    </Box>
                    <Box sx={{ ...css.box.texts }}>
                        <Box>
                            <Typography
                                component='p'
                                sx={{ ...css.texts.title }}>
                                {orderStatus[status].title}
                            </Typography>
                        </Box>
                        {date && time &&
                            <Box>
                                <Typography
                                    component='p'
                                    sx={{ ...css.texts.date }}>
                                    {date}
                                </Typography>
                                <Typography
                                    component='p'
                                    sx={{ ...css.texts.time }}>
                                    {time}
                                </Typography>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
            <Box className='divider' sx={{ ...css.box.dividerV }} />
        </Box>
    );
};

export { OrderStatusTimeline, OrderStatusTimelineItem };

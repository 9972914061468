export const sx = () => {
    const css = {
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 9,
            backgroundColor: 'rgba(255,255,255,0.4)',
            pt: 2,
            pb: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
    return { css };
};

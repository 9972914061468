import { createBrowserRouter } from 'react-router-dom';

import {
    stackRoutes,
    stackAuthRoutesMobile,
    stackRoutesSimpleHeaderMobile,
    stackRoutesCentralizedHeaderMobile,
    stackRoutesAuthenticatedLayoutSideMenu,
    stackAuthenticatedRoutes,
} from './stack.routes';
import { Patterns } from '@components';

export const browserRouters = () => {
    const router = createBrowserRouter([
        {
            element: <Patterns.RouterLayout />,
            children: stackRoutes,
        },
        {
            element: <Patterns.RouterAuthLayoutMobile />,
            children: stackAuthRoutesMobile,
        },
        {
            element: <Patterns.RouterAuthLayoutMobile />,
            children: stackRoutesSimpleHeaderMobile,
        },
        {
            element: <Patterns.RouterCentralizedLayoutMobile />,
            children: stackRoutesCentralizedHeaderMobile,
        },
        {
            element: <Patterns.RouterLayoutSideMenu />,
            children: stackRoutesAuthenticatedLayoutSideMenu,
        },
        {
            element: <Patterns.RouterLayout />,
            children: stackAuthenticatedRoutes,
        },
    ]);

    return { router };
};

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Item } from './styles';
import { rem } from '@utils';

interface ButtonSelectedFilterProps {
    title?: string | any;
    value?: any;
    onClick?: () => void;
}

const ButtonSelectedFilter: React.FC<ButtonSelectedFilterProps> = (
    { title, value, onClick }: ButtonSelectedFilterProps,
) => {
    const { typography, palette } = useTheme();
    return (
        <Box display='table' onClick={onClick}>
            <Item>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    spacing={1}
                    mb={value ? rem(8) : 0}>
                    {title && (
                        <Typography
                            variant='h2'
                            component='h2'
                            fontSize={12}
                            whiteSpace='nowrap'
                            fontWeight={typography.fontWeightSemiBold}
                            color={palette.dark}>
                            {title}
                        </Typography>
                    )}
                    {onClick && (
                        <Box position='relative' top={rem(1)}>
                            <I
                                icon={['fas', 'circle-xmark']}
                                color={palette.mediumGray}
                                fontSize={rem(14)}
                            />
                        </Box>
                    )}
                </Stack>
                {value && (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: rem(28),
                        mt: rem(4),
                    }}>
                        <Typography
                            variant='h2'
                            component='div'
                            fontSize={12}
                            width={'100%'}
                            whiteSpace='nowrap'
                            fontWeight={typography.fontWeightBold}>
                            {value}
                        </Typography>
                    </Box>
                )}
            </Item>
        </Box>
    );
};

export { ButtonSelectedFilter };

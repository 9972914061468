/* eslint-disable quotes */
import { useTheme } from '@mui/material';
import { rem } from '@utils';

const sx = () => {
    const { palette } = useTheme();

    const css = {
        drawer: {
            closeButton: {
                // position: 'absolute',
                // top: rem(5),
                // right: 0,
            },
            head: (backgroundColor?: string) => {
                return {
                    height: rem(48),
                    backgroundColor: backgroundColor ?? palette.lightBg,
                    pl: rem(15),
                    pr: rem(15),
                    pt: rem(5),
                    pb: rem(5),
                };
            },
        },
    };

    return { css };
};

export { sx };

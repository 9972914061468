import React from 'react';
import { AdsContext } from '../../contexts/ads/context';
import { ads as adsHook } from './ads';
import { useCategories } from './categories';
import { sizes as adSizes } from './sizes';
import { stores as partnersStores } from './stores';

export const adsProvider = () => {
    const ads = adsHook();
    const categories = useCategories();
    const sizes = adSizes();
    const stores = partnersStores();

    return {
        ...ads,
        categories,
        sizes,
        stores,
    };
};

export const useAds = () => {
    const context = React.useContext(AdsContext);
    return context;
};

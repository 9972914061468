import React from 'react';
import {
    Box,
    Fade,
    IconButton,
    Paper,
    Popper,
    Typography,
    useTheme,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { HeadlineProps } from '@interfaces';

import { fontSizes, sx } from './sx';
import { rem } from '@utils';

const Headline: React.FC<HeadlineProps> = ({
    size, title, legend, popper, chevronRight, paddingBottom,
}: HeadlineProps) => {
    const { sxBox, sxTitle, sxLegend } = sx();

    const { palette } = useTheme();

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => prev = !prev);
    };

    const closePopper = () => {
        setAnchorEl(null);
        setOpen((prev) => prev = false);
    };

    return (
        <>
            <Box sx={{ ...sxBox(paddingBottom as boolean) }}>
                <Box>
                    <Typography
                        variant='h1'
                        fontSize={fontSizes[size].title}
                        sx={{ ...sxTitle, wordBreak: 'break-word' }}>
                        {title}
                        {popper && (
                            <IconButton onClick={handleClick}>
                                <I icon={['far', 'circle-question']} fontSize={rem(12)} />
                            </IconButton>
                        )}
                        <Popper sx={{
                            position: 'relative',
                        }} open={open} anchorEl={anchorEl} placement='top' transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Paper>
                                        <Typography
                                            variant='subtitle2'
                                            sx={{ p: 1, pr: 3 }}
                                        >
                                            {popper}
                                        </Typography>
                                        <IconButton
                                            onClick={closePopper}
                                            sx={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                            }}>
                                            <I icon={['fas', 'xmark']}
                                                fontSize={12}
                                                color={palette.dark}
                                            />
                                        </IconButton>
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                    </Typography>

                    {legend && (
                        <Typography
                            variant='body1'
                            fontSize={fontSizes[size].subtitle}
                            sx={{ ...sxLegend, wordBreak: 'break-word' }}>
                            {legend}
                        </Typography>
                    )}
                </Box>
                {chevronRight &&
                    <Box sx={{
                        padding: '0.75rem 0.9375rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}>
                        <I
                            style={{ fontSize: '1.125rem' }}
                            icon={['far', 'chevron-right']} />
                    </Box>
                }
            </Box>
        </>
    );
};

export { Headline };

/* eslint-disable max-len */
import { UserAddressForm } from '@interfaces/Services';

export const formatAddress = ({
    zipCode,
    state,
    city,
    district,
    road,
    number,
    complement,
}: UserAddressForm) => {
    return `${road}, n${number} - Bairro ${district} ${complement ? `- ${complement},` : ','} ${city} - ${state}`;
};

import { Box } from '@mui/material';
import Slider, { Settings as SlickProps } from 'react-slick';
import { Image } from '@components';
import { sx } from './sx';
import { BannerProps } from '@interfaces/Services';
import { files } from '@utils';
import { Link } from 'react-router-dom';

interface HomeSliderProps {
    settings: SlickProps;
    imageMaxHeight: number;
    data: BannerProps[];
}

const Home: React.FC<HomeSliderProps> = (
    { settings, imageMaxHeight, data }: HomeSliderProps,
) => {
    const { css } = sx();

    return (
        <Slider {...settings} className='slick-slider'>
            {data.map(({ code, url, image, title }) => (
                <Link to={url as string} key={code}>
                    <Box key={code} sx={css.wrapper}>
                        <Image
                            src={files.load(image, 'server')}
                            alt={title as string}
                            maxHeight={imageMaxHeight} />
                    </Box>
                </Link>
            ))}
        </Slider>
    );
};

export { Home };

import { useTheme } from '@mui/material';
import { rem } from '@utils';

export const sx = () => {
    const { palette } = useTheme();


    const css = {
        stack: {
            shipping: {
                p: 1,
                borderTop: `1px dashed ${palette.cityLights}`,
                backgroundColor: palette.snowWhite,
            },
        },
        text: {
            shipping: {
                title: {
                    fontSize: rem(12),
                },
                subtitle: {
                    'fontSize': rem(10),
                    '& > span': {
                        color: palette.mediumVioletRed,
                    },
                },
            },
        },
    };

    return { css };
};

import { useMediaQuery, useTheme } from '@mui/material';
import { BreakpointsSizesTypes } from '@types';
import { rem } from '@utils';

export const sx = () => {
    const { palette, breakpoints } = useTheme();

    const mediaQuery = (size: BreakpointsSizesTypes) => useMediaQuery(
        `(min-width:${breakpoints.
            values[size as BreakpointsSizesTypes]}px)`,
    );

    const css = {
        container: {
            wrapper: {
                p: mediaQuery('lg') ? rem(25) : '0 !important',
            },
            main: {
                wrapper: {
                    minWidth: rem(250),
                    borderRight: `1px solid ${palette.cityLights}`,
                },
            },
            outlet: {
                wrapper: {
                    width: '100%',
                    minHeight: mediaQuery('lg') ?
                        'calc(100vh - 300px)' :
                        'calc(100vh - 280px)',
                    px: mediaQuery('lg') ? rem(25) : '0 !important',
                },
            },
        },
    };
    return { css };
};

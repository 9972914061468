export const filtersExtractPeriodList = [
    {
        id: 'a768c3d973e5',
        title: '1 mês',
        value: 30,
    },
    {
        id: 'ec125d23400b',
        title: '3 meses',
        value: 90,
    },
    {
        id: '2649f5974caa',
        title: '6 meses',
        value: 180,
    },
    {
        id: 'd5203533e63c',
        title: '1 ano',
        value: 360,
    },
    {
        id: '5c27d1d874a4',
        title: '+ de 1 ano',
        value: 720,
    },
];

/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useTheme } from '@mui/material';
import { rem, styles } from '@utils';

export const sx = () => {
    const { palette, typography } = useTheme();
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        stack: {
            p: mediaQuery.lg ? 0 : 2,
        },
        stackButtonLimit: {
            borderTop: `${rem(1)} solid ${palette.cityLights}`,
            backgroundColor: palette.common.white,
        },
        box: {
            wrapper: {
                backgroundColor: palette.lightBg, // !mediaQuery.lg ? palette.lightBg : palette.common.white,]
                border: '1px solid ' + palette.cityLights,
                borderRadius: rem(4),
            },
            divider: {
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
            },
            wrapperStore: {
                backgroundColor: palette.common.white,
            },
            storeHeader: {
                backgroundColor: palette.common.white,
                borderBottom: `${rem(1)} solid ${palette.cityLights}`,
                px: 2,
                py: 1,
            },
            tabs: {
                backgroundColor: palette.common.white,
                borderBottom: '1px solid #ECF0F1',
            },
        },
        tabs: {
            'width': '100%',
            'height': '100%',
            'padding': '0',
            '& > .MuiTabs-scroller': {
                '& > .MuiTabs-indicator': {
                    display: 'none',
                },
                '& > .MuiTabs-flexContainer': {
                    '& > .MuiTab-root': {
                        'textTransform': 'none !important',
                        'fontSize': rem(14),
                        'padding': `${rem(8)} ${rem(16)}`,
                        'fontWeight': typography.fontWeightMedium,
                        'color': palette.grey,
                        '&.Mui-selected': {
                            color: palette.primaryColor,
                            borderBottom: `${rem(2)} solid ${palette.primaryColor}`,
                            fontWeight: typography.fontWeightSemiBold,
                        },
                    },
                },
            },
        },
    };

    return { css };
};

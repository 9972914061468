/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { Display, Headline, Image, MessageBox, Product, ProductSkeleton } from '@components';
import { Avatar, Box, Container, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { slick } from '@static/slick-settings';
import { files, numbers, rem, styles } from '@utils';
import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { useAds } from '@hooks/ads';
import { useLayout } from '@hooks/layout';
import { useGlobal } from '@hooks/global';
import { useUser } from '@hooks/user';
import RSC from 'react-scrollbars-custom';
import { useFilters } from '@hooks/filters';
import { mountURLParamsForResults } from '../../utils/mountURLParamsForResults';
import { MountURLParamsForResultsProps } from '@interfaces/Utils';
import { FILTERS_KEYS } from '@static/filters';
import { CONSTANTS } from '@static/constants';
import { useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { FiltersParamsKeysType, FiltersTypeProps } from '@types';

interface ShowcasesComponentProps {
    children: React.ReactNode;
}

const Showcases = ({ children }: ShowcasesComponentProps) => {
    const mediaQuery = styles.mediaQueryCSS();
    return (
        !mediaQuery.md ?
            <Slider {...slick.settings.home.hero} className='slick-slider'>{children}</Slider> :
            <Grid container spacing={2}>{children}</Grid>
    );
};

const Home: React.FC = () => {
    const urlParams = useParams();
    const slider = React.useRef<Slider>(null);
    const { css } = sx();
    const mediaQuery = styles.mediaQueryCSS();
    const navigate = useNavigate();

    const ad = useAds();
    const filters = useFilters();
    const { cart } = useUser();
    const { layout } = useLayout();
    const global = useGlobal();

    const params = {
        key: urlParams.key as string,
    } as FiltersParamsKeysType;

    const loadServicesHome = async () => {
        filters.load.quicklinks({ storeType: global.app.view.type });
        filters.load.showcases({ storeType: global.app.view.type });
    };

    const loadAds = async () => {
        if (!ad.ads.popular.list.isLoaded) {
            await ad.ads.popular.load();
        }
        if (!ad.ads.news.list.isLoaded) {
            await ad.ads.news.load();
        }
        if (!cart.list.isLoaded) {
            await cart.load();
        }
    };

    const mountURLParams = async ({ ...props }: MountURLParamsForResultsProps) => {
        console.log('props', props);
        const response = mountURLParamsForResults({ ...props });
        const params = {
            key: `/resultados/busca/${!!response.params.key ? response.params.key : ''}`,
            limit: FILTERS_KEYS.LIMIT + '=' + CONSTANTS.SEARCH.LIMIT,
            aleatory: FILTERS_KEYS.ALEATORY + '=' + numbers.randomNumber({ maxRange: 100 }),
        };

        return navigate({
            pathname: params.key,
            search: `${params.limit}&${params.aleatory}&${response.query}`,
        });
    };

    useEffect(() => {
        loadAds();
    }, []);

    useEffect(() => {
        loadServicesHome();
    }, [global.app.view.type]);

    const size = mediaQuery.md ? 84 : 64;
    const propOverflowX = !mediaQuery.md ? { overflowX: 'auto' } : {};
    const mr = mediaQuery.md ? 50 : 30;

    return (
        <Box sx={{ backgroundColor: '#FAFAFB' }}>
            <Container maxWidth='lg'>
                {mediaQuery.md && (
                    <Box
                        sx={{ ...css.box.slider }}
                        pt={rem(15)}>
                        {layout.banners.carrousel.list.isLoaded ?
                            <Display.Sliders.Home
                                settings={slick.settings.home.hero}
                                data={layout.banners.carrousel.list.data}
                                imageMaxHeight={490}
                            /> :
                            <Skeleton variant='rectangular' height={490} />
                        }
                    </Box>
                )}
                {filters.list.quicklinks.isLoaded ? (filters.list.quicklinks.data.length > 0 &&
                    <Box display='flex' justifyContent='center' sx={{
                        ...css.quicklinks.box,
                        ...{
                            '.ScrollbarsCustom > .ScrollbarsCustom-Wrapper > .ScrollbarsCustom-Scroller > .ScrollbarsCustom-Content': {
                                ...propOverflowX,
                            },
                        },
                    }}>
                        <RSC id="RSC-Example" className='quicklinks-wrapper' style={{ ...css.quicklinks.wrapper }}>
                            {filters.list.quicklinks.data.map((quicklink, i) => {
                                if (!quicklink.active) return;
                                return (
                                    <Box
                                        onClick={() => mountURLParams({ type: 'quicklink', ...quicklink })}
                                        display='flex'
                                        key={quicklink._id}
                                        flexDirection='column'
                                        alignItems='center'
                                        mr={filters.list.quicklinks.data.length > 1 ? rem(mr) : 0}
                                    >
                                        <Avatar
                                            alt={quicklink.title.toLocaleUpperCase()}
                                            src={files.load(quicklink.banner, 'server')}
                                            sx={{ bgcolor: 'purple', width: size, height: size, mb: rem(4) }}
                                        />
                                        <Typography
                                            textAlign='center'
                                            fontSize={rem(13)}
                                            fontWeight={600}
                                            color='#2F3640'
                                            variant='subtitle2'>
                                            {quicklink.title}
                                        </Typography>
                                    </Box>
                                );
                            })}
                        </RSC>
                    </Box>) :
                    <Stack
                        my={4}
                        spacing={6}
                        justifyContent={'center'}
                        direction={'row'}>
                        {Array.from(Array(3).keys()).map((_, i) => (
                            <Skeleton
                                key={'loading-sk-link-' + i}
                                variant='circular'
                                height={ mediaQuery.md ? 84 : 64}
                                width={ mediaQuery.md ? 84 : 64} />

                        ))}
                    </Stack>
                }

                {filters.list.showcases.isLoaded ? (filters.list.showcases.data.length > 0 &&
                    <Box>
                        <Box mt={rem(30)} mb={rem(20)}>
                            <Headline
                                title='Destaques'
                                size={mediaQuery.md ? 'big' : 'medium'}
                            />
                        </Box>
                        <Showcases>
                            {filters.list.showcases.isLoaded ? filters.list.showcases.data.map((showcase) => (
                                <Grid key={showcase._id} item md={6} sm={6} xs={12}>
                                    <Box onClick={() => mountURLParams({
                                        type: 'showcase',
                                        _id: showcase._id,
                                        title: showcase.title,
                                        banner: showcase.banner,
                                        filterType: showcase.filterType,
                                        filters: showcase.filters,
                                        ads: showcase.ads,
                                        active: showcase.active,
                                        showcaseType: showcase.showcaseType,
                                        order: showcase.order,
                                    })}>
                                        <Image
                                            src={files.load(showcase.banner, 'server')}
                                            alt={showcase.title}
                                            maxHeight={mediaQuery.md ? 280 : 187}
                                        />
                                    </Box>
                                </Grid>
                            )) : ''}
                        </Showcases>
                    </Box>) :
                    <Grid container spacing={2}>
                        {Array.from(Array(mediaQuery.md ? 4 : 1).keys()).map((_, i) => (
                            <Grid
                                key={'loading-sk-showcase-' + i}
                                item md={6} sm={12} xs={12}>
                                <Skeleton
                                    variant='rectangular'
                                    height={ mediaQuery.md ? 280 : 187} />
                            </Grid>
                        ))}
                    </Grid>
                }

                <Box mt={rem(30)} mb={rem(20)}>
                    <Headline
                        title='Mais populares'
                        size={mediaQuery.md ? 'big' : 'medium'}
                    />
                </Box>

                <Grid container spacing={2}>
                    {(layout.loading.value || !ad.ads.popular.list.isLoaded) &&
                        Array.from(Array(8).keys()).map((_, i) => (
                            <Grid key={'popular-sk-ad-' + i} item md={3} sm={4} xs={6}>
                                <ProductSkeleton />
                            </Grid>
                        ))
                    }
                    {!layout.loading.value && ad.ads.popular.list.isLoaded && (ad.ads.popular.list.data.length > 0 ?
                        ad.ads.popular.list.data.map((item) => (
                            <Grid key={`popular-${item._id}`} item md={3} sm={4} xs={6}>
                                <Product
                                    _id={item._id}
                                    idVariation={item.variations._id}
                                    variationItemId={item.variations.items[0]._id}
                                    type={global.app.view.type}
                                    to={`/produto/${item._id}?variation=${item.variations._id}&option=${item.variations.items[0]._id}`}
                                    title={item.title}
                                    value={item.variations.items[0].actualValue}
                                    pointValue={item.variations.items[0].pointValue}
                                    discount={item.oldValue}
                                    favorite={item.variations.isFavorite}
                                    image={files.load(item.variations.images[0], 'server')}
                                    cashback={item.variations.items[0].cashbackValue}
                                />
                            </Grid>
                        )) :
                        <Grid item md={12} sm={12} xs={12}>
                            <MessageBox message={'Não foi possível encontrar anúncios'} type={'info'} />
                        </Grid>)
                    }
                </Grid>

                <Stack direction='row' alignItems='center' justifyContent='space-between' mt={rem(30)} mb={rem(20)}>
                    <Headline
                        title='Novidades'
                        size={mediaQuery.md ? 'big' : 'medium'}
                    />
                    {mediaQuery.sm && (
                        <Stack direction='row' spacing={1}>
                            <IconButton
                                sx={css.slider.button}
                                onClick={() => slider.current?.slickPrev()}>
                                <I
                                    icon={['far', 'chevron-left']}
                                    fontSize={rem(16)}
                                    color={'black'}
                                />
                            </IconButton>
                            <IconButton
                                sx={css.slider.button}
                                onClick={() => slider.current?.slickNext()}>
                                <I
                                    icon={['far', 'chevron-right']}
                                    fontSize={rem(16)}
                                    color={'black'}
                                />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
                {(layout.loading.value || !ad.ads.news.list.isLoaded) &&
                    <Slider {...slick.settings.home.products} className='slick-slider' ref={slider}>
                        {[0, 1, 2, 3].map((i) => (
                            <div key={'news-sk-' + i}>
                                <Box mr={rem(15)}>
                                    <ProductSkeleton />
                                </Box>
                            </div>
                        ))}
                    </Slider>
                }

                <Box
                    sx={{ ...css.box.slider }}
                    pb={4} height={'100%'}>
                    {!layout.loading.value && ad.ads.news.list.isLoaded && (ad.ads.news.list.data.length > 0 ?
                        <Slider {...slick.settings.home.products} className='slick-slider slider-news' ref={slider}>
                            {ad.ads.news.list.data.map((item) => (
                                <Box height={'100%'} position={'relative'} key={`news-${item._id}`}>
                                    <Box height={'100%'} mr={rem(15)}>
                                        <Product
                                            _id={item._id}
                                            idVariation={item.variations._id}
                                            variationItemId={item.variations.items[0]._id}
                                            type={global.app.view.type}
                                            to={`/produto/${item._id}?variation=${item.variations._id}&option=${item.variations.items[0]._id}`}
                                            title={item.title}
                                            value={item.variations.items[0].actualValue}
                                            pointValue={item.variations.items[0].pointValue}
                                            discount={item.oldValue}
                                            favorite={item.variations.isFavorite}
                                            // partnerLogo={item.partnerLogo}
                                            image={files.load(item.variations.images[0], 'server')}
                                            cashback={item.variations.items[0].cashbackValue}
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Slider> :
                        <Box p={rem(30)}>
                            <MessageBox message={'Não temos anúncios no momento'} type={'info'} />
                        </Box>
                    )}
                </Box>
            </Container>
        </Box>
    );
};

export { Home };

import { AlertColor, Snackbar } from '@mui/material';
import { Alert } from '../alert';

interface MuiSnackbarProps {
    open: boolean;
    onClose: (event?: React.SyntheticEvent | Event, reason?: string) => void
    severity: AlertColor;
    message: string;
}

export const MuiSnackbar: React.FC<MuiSnackbarProps> = (
    { open, onClose, severity, message }: MuiSnackbarProps,
) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

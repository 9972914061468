import { Template } from '@components';
import { useLayout } from '@hooks/layout';
import { Box, Container } from '@mui/material';
import { rem } from '@utils';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Partners: React.FC = () => {
    const { layout } = useLayout();
    const navigate = useNavigate();

    React.useEffect(() => {
        layout.mobile.header.headerSettings({
            title: 'Parceiros',
            onBackAction: () => navigate(-1),
        });
    }, []);

    return (
        <Box pt={rem(20)} pb={rem(20)}>
            <Container maxWidth='lg'>
                <Template.Partners />
            </Container>
        </Box>
    );
};

export { Partners };

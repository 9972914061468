import { rem, styles } from '@utils';

export const sx = (noPadding?: boolean) => {
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        sxBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: 0,
            borderBottom: `${rem(1)} solid #f1f1f1`,
        },
        sxContainer: {
            px: mediaQuery.lg && !noPadding ? rem(24) :
                noPadding ? '0 !important' : '0 !important',
        },
        sxIconButton: {
            default: {
                padding: `${rem(16)} ${rem(10)}`,
                paddingLeft: rem(16),
                marginRight: rem(5),
            },
            center: {
                padding: `${rem(20)} ${rem(18)}`,
                cursor: 'pointer',
                zIndex: '500',
            },
        },
        sxBoxContent: {
            display: 'flex',
            alignItems: 'center',
            marginRight: rem(15),
        },
        sxBoxAction: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: rem(50),
            cursor: 'pointer',
        },
        sxBoxTitle: {
            default: {
                display: 'block',
            },
            center: {
                position: 'absolute',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                left: 0,
                right: 0,
            },
        },
    };

    return { css };
};

import { Box, Button, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { rem } from '@utils';

interface ButtonTextArrowProps {
    text: string;
    onClick: () => void;
    disabled?: boolean;
}

const ButtonTextArrow: React.FC<ButtonTextArrowProps> = ({
    text, onClick, disabled,
}: ButtonTextArrowProps) => {
    const { typography, palette } = useTheme();

    return (
        <Button
            type='button'
            variant='text'
            size='large'
            sx={{ minWidth: rem(114) }}
            disabled={disabled}
            onClick={onClick}>
            <Typography
                variant="body2"
                component='h1'
                align='left'
                fontSize={rem(14)}
                color='darkGray'
                whiteSpace='nowrap'
                fontWeight={typography.fontWeightSemiBold}>
                {text}
            </Typography>
            <Box ml={rem(5)}>
                <I
                    icon={['fas', 'chevron-right']}
                    fontSize={rem(11)}
                    color={palette.dark} />
            </Box>
        </Button>
    );
};

export { ButtonTextArrow };

import { Box, Stack, Typography } from '@mui/material';
import { Image } from '@components';
import LogoCmk from '../../../../assets/img/logo-cmk.png';
import { Link } from 'react-router-dom';
import { sx } from '../sx';

export const Mobile: React.FC = () => {
    const { css } = sx();
    return (
        <Stack spacing={1} sx={{ ...css.stack.wrapperMobile }}>
            <Image
                src={LogoCmk}
                objectFit='contain'
                maxHeight={30}
                alt={'Commaeket'} />
            <Box display={'flex'} justifyContent={'center'}>
                <Typography variant='caption' sx={{ ...css.texts.copyright }}>
                    Copyright © 2023 Commarket LTDA
                </Typography>
            </Box>
            <Box display={'flex'} justifyContent={'center'}>
                <Link
                    style={{ ...css.link }}
                    to={''}>
                    <Typography variant='caption'
                        sx={{ ...css.texts.link }}>
                                Privacidade
                    </Typography>
                </Link>
                <Typography sx={{ ...css.texts.divisor }}>
                        ·
                </Typography>
                <Link
                    style={{ ...css.link }}
                    to={''}>
                    <Typography variant='caption'
                        sx={{ ...css.texts.link }}>
                                Termos
                    </Typography>
                </Link>
            </Box>

        </Stack>
    );
};

/* eslint-disable quotes */
import { rem, styles } from '@utils';

const sx = () => {
    const mediaQuery = styles.mediaQueryCSS();

    const css = {
        wrapper: {
            pt: rem(mediaQuery.md ? 60 : 10),
            height: mediaQuery.md ?
                `calc(100% - ${rem(90)})` :
                '100%',
        },
    };

    return { css };
};

export { sx };

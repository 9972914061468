/* eslint-disable no-unused-vars */
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Container,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { rem, styles } from '@utils';
import { sx } from './sx';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from '@hooks/user';
import { Feedback } from '@components';
import { DEFAULTS } from './DEFAULTS';
import { useGlobal } from '@hooks/global';
import { useLayout } from '@hooks/layout';
import { useAds } from '@hooks/ads';

interface ValidationCodeForm {
    validationCode: string;
}

const TypeConfirmationCode: React.FC = () => {
    const { typography } = useTheme();
    const { css } = sx();
    const ad = useAds();
    const user = useUser();
    const global = useGlobal();
    const { layout } = useLayout();

    const mediaQuery = styles.mediaQueryCSS();
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const paramsType = {
        method: params.method as 'email' | 'sms',
    };

    const textAlign = mediaQuery.md ? 'center' : 'left';
    const [isLoading, setIsLoading] = React.useState(DEFAULTS.loading);
    const [open, setOpen] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
    });

    const handleClose = () => setOpen(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        clearErrors,
    } = useForm<ValidationCodeForm>({
        mode: 'onSubmit',
    });

    const resendValidationCode = async () => {
        setIsLoading((prev) => prev = {
            ...prev,
            code: true,
        });
        const response = await user.
            account.
            auth.
            validationCode.
            send({
                sendEmail: paramsType.method === 'email',
                sendSms: paramsType.method === 'sms',
                email: location.state?.email ?? undefined,
            });
        setIsLoading(DEFAULTS.loading);
        setOpen(true);
        setResponseData({
            type: response?.response.type,
            message: response?.response.message,
        });
    };

    const onSubmit: SubmitHandler<ValidationCodeForm> = async (data) => {
        setIsLoading((prev) => prev = {
            ...prev,
            form: true,
        });
        const { validationCode } = data;
        const response = await user.account.auth.validationCode.validate({
            validationCode,
        });

        const loadAPIS = async () => {
            await user.favorites.load();
            await user.cart.load();
        };

        if (response?.error) {
            setIsLoading(DEFAULTS.loading);
            setOpen(true);
            setResponseData({
                type: response?.response.type,
                message: response?.response.message,
            });
            return;
        }

        if (!response?.error) {
            const token = response?.response.payload.accessToken ?? '';
            global.app.view.set('SHOPPING');
            global.app.cookies.set('session', token);

            const responseUser = await user.load({
                jwt: token,
            });
            if (!responseUser?.error) {
                await loadAPIS();
                setTimeout(() => {
                    navigate('/auth/criar-conta/sucesso');
                }, 500);
            }
        }
    };

    return (
        <Box sx={css.wrapper}>
            <Container maxWidth='xs'>
                <Box>
                    <Typography
                        variant="body2"
                        component='h1'
                        align={textAlign}
                        fontSize={rem(22)}
                        color='darkGray'
                        fontWeight={typography.fontWeightBold}
                        gutterBottom>
                        Um código de 6 dígitos foi enviado
                        {paramsType.method === 'sms' ? 'via SMS' : 'para o E-mail'}
                    </Typography>
                    <Typography
                        variant="body2"
                        component='h1'
                        align={textAlign}
                        fontSize={rem(14)}
                        color='darkGray'
                        fontWeight={typography.fontWeightMedium}>
                        Digite o código no campo abaixo para confirmar
                        sua conta
                    </Typography>
                    <Box component='form' onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={3} mb={rem(10)} mt={rem(30)}>
                            <TextField
                                fullWidth
                                focused={true}
                                label={'Código de confirmação'}
                                id="validation-code"
                                helperText=''
                                variant="outlined"
                                error={!!errors.validationCode}
                                {...register('validationCode',
                                    { required: true },
                                )} />
                        </Stack>
                        <Box mb={rem(10)} mt={rem(10)}>
                            <Stack direction='row' justifyContent='center'>
                                <Button
                                    variant='text'
                                    type='button'
                                    onClick={resendValidationCode}
                                    disabled={isLoading.code}>
                                    Reenviar código {isLoading.code &&
                                        <CircularProgress
                                            color="inherit"
                                            size={14}
                                            sx={{ ml: 1 }}
                                        />}
                                </Button>
                            </Stack>
                        </Box>
                        <Stack
                            direction='row'
                            spacing={2}
                            justifyContent='space-between'
                            mt={rem(15)}>
                            <Button
                                fullWidth
                                type='submit'
                                variant='contained'
                                disabled={isLoading.form}
                                size='large'>
                                Enviar
                                {isLoading.form &&
                                    <CircularProgress
                                        color="inherit"
                                        size={14}
                                        sx={{ ml: 1 }}
                                    />}
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Container>
            <Feedback.MuiSnackbar
                open={open}
                severity={responseData.type}
                onClose={handleClose}
                message={responseData.message}
            />
        </Box>
    );
};

export { TypeConfirmationCode };
